import { useCallback } from 'react';
import { LSManager } from '@vanongo/utils';

export const useResizeHook = ({ setUserColumns, LS_KEY }) => {
  return useCallback(
    ({ width, offset, field }) => {
      setUserColumns((prevUserColumns) => {
        const index = prevUserColumns.findIndex((el) => el.field === field);
        // DEEP COPY
        const nextItem = Object.entries(prevUserColumns[index]).reduce(
          (total, [key, value]) => {
            total[key] = value;
            return total;
          },
          {},
        );
        nextItem.width = width + offset;
        const nextColumns = [...prevUserColumns];
        nextColumns.splice(index, 1, nextItem);

        LSManager.save(LS_KEY, nextColumns);
        return nextColumns;
      });
    },
    [setUserColumns, LS_KEY],
  );
};
