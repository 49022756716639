import { all, takeLatest, put } from 'redux-saga/effects';
import { driversDebtorActions } from '../../slices/driversDebtor/driversDebtor';
import { api } from '../../../services/api';
import { getObjFromQuery } from '@vanongo/utils';

const getDriversDebtorSaga = function* ({
  payload: { pageSize = 10, page, queryString } = {
    pageSize: 10,
    page: 1,
  },
}) {
  try {
    const filtersValue = getObjFromQuery(queryString);

    const response = yield api.get(
      `${process.env.REACT_APP_ADMIN_URL}drivers/debts`,
      {
        query: { page, limit: pageSize, ...filtersValue },
      },
    );
    yield put(
      driversDebtorActions.getDriversDebtorSuccess({
        data: response.results,
        page: response.currentPage,
        pageSize,
        totalCount: response.totalCount,
      }),
    );
  } catch (e) {
    console.log(e);
  }
};

export const driversDebtorSaga = function* () {
  yield all([
    takeLatest(
      driversDebtorActions.getDriversDebtor.type,
      getDriversDebtorSaga,
    ),
  ]);
};
