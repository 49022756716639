import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

export const BreadcrumbsItem = (item) => {
  if (!item.link) {
    return (
      <Typography key={item.title} variant="body1" color="textPrimary">
        {item.title}
      </Typography>
    );
  }

  return (
    <Link
      key={item.title}
      variant="body1"
      color="inherit"
      to={item.link}
      component={RouterLink}
    >
      {item.title}
    </Link>
  );
};
