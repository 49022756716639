import { useEffect } from 'react';

export const useGetTableWidth = ({ ref, setTableWidth }) => {
  useEffect(() => {
    let timeOutId;

    const setTable = () => {
      timeOutId = setTimeout(() => {
        if (ref.current.clientWidth === 0) {
          setTable();
        } else {
          setTableWidth(ref.current.clientWidth);
        }
      }, 100);
    };

    if (ref.current) {
      setTable();
    }

    const handleResize = () => {
      if (ref.current) {
        setTableWidth(ref.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timeOutId);
    };
  }, [ref, setTableWidth]);
};
