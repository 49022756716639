import { useSelector } from 'react-redux';
import { areasFilterValueSelector } from '../../redux/slices/global/areasFilter';
import { useAreaAttributes } from '../areas/useAreaAttributes';

export const useAttributesOptions = () => {
  const areaId = useSelector(areasFilterValueSelector);
  const { attributes, isLoading } = useAreaAttributes(areaId);

  return {
    options: attributes,
    isLoading,
  };
};
