import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

export const MapCenter = ({ center }) => {
  const map = useMap();

  useEffect(() => {
    const zoom = map.getZoom();
    if (zoom) {
      map.setView(center, zoom);
    }
  }, [center, map]);

  return null;
};
