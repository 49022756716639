import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  currenciesOptionsActions,
  currenciesOptionsSelector,
} from '../../redux/getters/currenciesOptions';

export const useCurrenciesOptions = () => {
  const dispatch = useDispatch();
  const { data: options, isLoading } = useSelector(currenciesOptionsSelector);

  useEffect(() => {
    dispatch(currenciesOptionsActions.get());
  }, [dispatch]);

  return {
    options,
    isLoading,
  };
};
