import React, { useState, useRef } from 'react';
import {
  Badge,
  Box,
  IconButton,
  Popover,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Select } from '@vanongo/components/formComponents/Select/Select';
import { LSManager } from '@vanongo/utils';
import {
  localization,
  languagesOptions,
  getLanguage,
} from '../../../services/localization';
import { useTranslation } from 'react-i18next';
import { LANGUAGE } from '../../../constants/STORAGE_KEYS';
import { CustomIcon } from '@vanongo/components/CustomIcon/CustomIcon';

const useStyles = makeStyles((theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5,
  },
  popover: {
    overflow: 'visible',
    width: 320,
    padding: theme.spacing(2),
  },
}));

const Settings = () => {
  const { t } = useTranslation('global');
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [language, setLanguage] = useState(getLanguage());

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLanguageChange = (value) => {
    setLanguage(value);
    LSManager.save(LANGUAGE, value);
    localization.changeLanguage(value);
  };

  return (
    <>
      <Tooltip title={t('Settings')}>
        <Badge
          color="secondary"
          variant="dot"
          classes={{ badge: classes.badge }}
        >
          <IconButton color="inherit" onClick={handleOpen} ref={ref}>
            <CustomIcon glyph="Settings" width={36} height={36} />
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <>
          <Typography variant="h4" color="textPrimary">
            {t('Settings')}
          </Typography>
          <Box mt={2}>
            <Select
              options={languagesOptions}
              onChange={handleLanguageChange}
              value={language}
              title={t('Language')}
            />
          </Box>
        </>
      </Popover>
    </>
  );
};

export default Settings;
