export const downloadBlob = (blob, name, url) => {
  const link = document.createElement('a');
  if (url) {
    link.setAttribute('href', url);
  } else {
    link.setAttribute('href', URL.createObjectURL(blob));
  }
  link.setAttribute('download', name);
  link.style.visibility = 'hidden';
  link.click();
};
