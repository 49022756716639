import { reduxHelper } from '@vanongo/utils';
import { api } from '../../services/api';

export const {
  actions: areaByIdActions,
  reducer: areaById,
  selector: areaByIdSelector,
  saga: areaByIdSaga,
} = reduxHelper({
  actionName: 'getAreaById',
  name: 'areaById',
  defaultValue: {},
  promise: (areaId) =>
    areaId
      ? api.get(`${process.env.REACT_APP_ADMIN_URL}areas/${areaId}`)
      : Promise.resolve({}),
});
