import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  areaByIdActions,
  areaByIdSelector,
} from '../../redux/getters/areaById';

export const useGetAreaById = (areaId) => {
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector(areaByIdSelector);

  useEffect(() => {
    dispatch(areaByIdActions.getAreaById(areaId));
  }, [dispatch, areaId]);

  return { data, isLoading };
};
