import { useMemo } from 'react';
import { localization } from '../../../webAdmin/src/services/localization';

export const useGetCreateButton = ({ disabled, onClick } = {}) => {
  return useMemo(
    () => ({
      color: 'secondary',
      type: 'submit',
      variant: 'contained',
      text: localization.t('Create'),
      disabled: disabled,
      onClick,
    }),
    [disabled, onClick],
  );
};
