import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { ButtonConfigRender } from '@vanongo/components/Buttons/ButtonConfigRender';
import { DatePicker } from '@vanongo/components/formComponents/Date/Date';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../../constants/namespaces';
import { Select } from '@vanongo/components/formComponents/Select/Select';
import { useSolversOptions } from '../../../hooks/options/useSolversOptions';
import { Checkbox } from "@vanongo/components"

export const ConfigureJob = ({ closePopup, handleNext, activeStep }) => {
  const { t } = useTranslation(NAMESPACES.ORDERS);
  const { t: tGlobal } = useTranslation(NAMESPACES.GLOBAL);

  const today = new Date();
  const tomorrow = today.setDate(today.getDate() + 1);
  const [value, onChange] = useState(activeStep.deliveryDate || tomorrow);
  const [solverValue, changeSolverValue] = useState(
    activeStep.solver && activeStep.solver.value,
  );
  const [createWaybills, changeCreateWaybills] = useState(
    !!activeStep.createWaybills,
  );
  const { options } = useSolversOptions();

  const handleNextStep = () => {
    const solver = options.find((el) => el.value === solverValue);
    handleNext({ deliveryDate: value, solver, createWaybills });
  };

  const leftButton = [{ text: tGlobal('Close'), onClick: closePopup }];
  const buttonConfig = [
    {
      text: tGlobal('Next'),
      color: 'secondary',
      variant: 'contained',
      disabled: !solverValue,
      onClick: handleNextStep,
    },
  ];

  return (
    <>
      <Box mb={2} p={2} height="55vh">
        <DatePicker
          title={t('Delivery date')}
          value={value}
          onChange={onChange}
          isClearable={false}
        />

        <Box mt={2}>
          <Select
            value={solverValue}
            onChange={changeSolverValue}
            effect={useSolversOptions}
            title={t('Solvers')}
          />
        </Box>

        <Box mt={2}>
          <Checkbox
            value={createWaybills}
            onChange={changeCreateWaybills}
            title={t('Create waybill automatically')}
          />
        </Box>
      </Box>

      <ButtonConfigRender buttonConfig={buttonConfig} leftSide={leftButton} />
    </>
  );
};
