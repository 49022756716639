import React from 'react';
import { timeFormat, dateddmmyyFormat } from '@vanongo/utils';

export const DateTimeCellRender = ({ date }) => {
  return (
    <>
      {dateddmmyyFormat(date)} <br />
      {timeFormat(date)}
    </>
  );
};
