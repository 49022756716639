import { getObjFromQuery } from './getObjFromQuery';

export const createFilters = (queryString, metadata) => {
  const query = getObjFromQuery(queryString);

  return Object.entries(query).reduce((total, [key, value]) => {
    const findItem = metadata.find((el) => el.name === key);
    if (findItem) {
      total[key] = value;
    }

    return total;
  }, {});
};
