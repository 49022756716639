import { useAttributesOptions } from '../../../hooks/options/useAttributesOptions';
import { useSmsStatusListOptions } from '../../../hooks/options/useSmsStatusListOptions';

export const orderStatuses = [
  { value: 'AT_WAREHOUSE', label: 'At warehouse' },
  { value: 'NEW', label: 'New' },
  { value: 'ASSIGNED', label: 'Assigned' },
  { value: 'CONFIRMED', label: 'Confirmed' },
  { value: 'IN_PROGRESS', label: 'In progress' },
  { value: 'FINISHED', label: 'Finished' },
  { value: 'CANCELLED', label: 'Cancelled' },
  { value: 'ON_HOLD', label: 'On hold' },
  { value: 'TO_RETURN', label: 'To return' },
  { value: 'PICKED_UP', label: 'Picked up' },
  { value: 'WAITING_FOR_SELF_PICKUP', label: 'Waiting for self pickup' },
  { value: 'RETURNED_TO_PARTNER', label: 'Returned to partner' },
  { value: 'DISPATCHED', label: 'Dispatched' },
  { value: 'TO_RESCHEDULE', label: 'To reschedule' },
  { value: 'AT_WAREHOUSE_TO_RETURN', label: 'At warehouse to return' },
  { value: 'AT_RECIPIENT_TO_RETURN', label: 'At recipient to return' },
  { value: 'READY_FOR_DELIVERY', label: 'Ready for delivery' },
];

export const userTypesOptions = [
  { value: 'CUSTOMER', label: 'Customer' },
  { value: 'PARTNER', label: 'Partner' },
];

const validatedAddressOptions = [
  { value: '1', label: 'Validated' },
  { value: '2', label: 'Unvalidated' },
  { value: '3', label: 'Outside of area' },
  { value: '4', label: 'Inside of area' },
  { value: '5', label: 'Is return' },
  { value: '6', label: 'Is not return' },
];

export const filterMetadataOnTheFly = [
  {
    name: 'status',
    type: 'multiselect',
    title: 'Status',
    width: 200,
    options: orderStatuses,
  },
  {
    name: 'externalCargoId',
    type: 'string',
    title: 'External cargo id',
  },
  {
    name: 'search',
    type: 'string',
    title: 'Search',
  },
  {
    title: 'Organization',
    name: 'organizationId',
    type: 'select',
    width: 200,
  },
];

export const filterMetadata = [
  {
    name: 'phone',
    type: 'string',
    title: 'Phone',
  },
  {
    name: 'flags',
    type: 'multiselect',
    options: validatedAddressOptions,
    title: 'Issues',
  },
  {
    name: 'shortId',
    type: 'string',
    title: 'ShortId',
  },
  {
    name: 'minWeight',
    type: 'number',
    title: 'Min weight',
  },
  {
    name: 'maxWeight',
    type: 'number',
    title: 'Max weight',
  },
  {
    name: 'attributes',
    type: 'multiselect',
    title: 'Solver attributes',
    effect: useAttributesOptions,
  },
  {
    name: 'externalCargoIds',
    type: 'string',
    title: 'Bulk external cargo ids',
  },
  {
    name: 'smsStatus',
    type: 'select',
    title: 'Status of SMS',
    effect: useSmsStatusListOptions,
  },
  {
    name: 'date',
    type: 'selectDateRange',
    options: [
      { label: 'Created', value: 'created' },
      { label: 'Received', value: 'actualPickupTime' },
      { label: 'Fact', value: 'deadlineActual' },
      { label: 'Expected', value: 'expectedDeliveryPeriod', hasExact: true },
      { label: 'Delivery', value: 'requestedDeliveryPeriod', hasExact: true },
    ],
    sm: 12,
    md: 12,
  },
  { name: 'waybillId', type: 'string', isHidden: true },
];
