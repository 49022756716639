import { all, takeEvery, put } from 'redux-saga/effects';

import { areasLegalDocumentsActions } from '../../slices/areas/areasLegalDocuments';
import { commonRequest } from '../apiSaga';

const getAreasLegalDocumentsSaga = function* ({ payload }) {
  try {
    const response = yield commonRequest('getLatestDocuments', {
      parameters: { areaId: payload },
    });

    yield put(
      areasLegalDocumentsActions.getAreasLegalDocumentsSuccess({
        data: response,
      }),
    );
  } catch (e) {}
};

const uploadAreaDocumentSaga = function* ({
  payload: { file, areaId, documentType },
}) {
  const data = new FormData();
  data.append('area', areaId);
  data.append('file', file);
  data.append('documentType', documentType);

  try {
    const response = yield commonRequest('createDocument', {
      body: data,
    });

    yield put(
      areasLegalDocumentsActions.uploadAreaDocumentSuccess({
        data: response,
        type: documentType,
      }),
    );
  } catch (e) {
    console.log(e);
  }
};
//
// const getDocumentFileSaga = function* ({ payload: { url } }) {
//   try {
//
//   } catch (e) {
//     console.error(e);
//   }
// };

export const areasLegalDocumentsSaga = function* () {
  yield all([
    takeEvery(
      areasLegalDocumentsActions.getAreasLegalDocuments.type,
      getAreasLegalDocumentsSaga,
    ),
    takeEvery(
      areasLegalDocumentsActions.uploadAreaDocument.type,
      uploadAreaDocumentSaga,
    ),
    // takeEvery(
    //   areasLegalDocumentsActions.getDocumentFile.type,
    //   getDocumentFileSaga,
    // ),
  ]);
};
