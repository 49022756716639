import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: areasCreateActions } = createSlice({
  name: 'areasCreate',
  initialState: {
    isUpdating: false,
  },
  reducers: {
    createAreas: (state) => {
      state.isUpdating = true;
    },
    createAreasSuccess: (state) => {
      state.isUpdating = false;
    },
  },
});

export const areasCreateSelector = (state) => state.areas.areasCreate;
