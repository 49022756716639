export const filterListAssignDriversMetadata = [
  {
    name: 'name',
    type: 'string',
    title: 'Name',
  },
  {
    name: 'phone',
    type: 'string',
    title: 'Phone',
  },
  {
    name: 'partnerId',
    title: 'Partner',
    type: 'select',
    width: 200,
  },
];

const filterListMetadata = [
  {
    name: 'name',
    type: 'string',
    title: 'Name',
  },
  {
    name: 'phone',
    type: 'string',
    title: 'Phone',
  },

  {
    name: 'group',
    title: 'Group',
    type: 'select',
    width: 200,
  },
  {
    name: 'attributes',
    title: 'Attributes',
    type: 'multiselect',
    width: 200,
  },
  {
    name: 'organization',
    title: 'Organization',
    type: 'select',
    width: 200,
  },
];

export default filterListMetadata;
