import React, { useMemo, useRef } from 'react';
import { Button } from '../../Buttons/Button';
import { Box, FormControl, makeStyles, Typography } from '@material-ui/core';
import { get } from '@vanongo/utils';

const useStyles = makeStyles((theme) => ({
  input: {
    position: 'fixed',
    left: -100,
    width: 1,
    height: 1,
  },
  ellipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '50%',
  },
}));

export const UploadFile = ({
  multiple,
  accept,
  color = 'secondary',
  size = 'large',
  variant = 'contained',
  title,
  onChange,
  disabled,
  value,
  error,
}) => {
  const classes = useStyles();
  const ref = useRef(null);

  const handleClick = () => {
    const clickEvent = document.createEvent('MouseEvents');
    clickEvent.initMouseEvent('click');

    ref.current.dispatchEvent(clickEvent);
  };

  const handleChange = (e) => {
    onChange(e.target.files);
  };

  const chosenFiles = useMemo(() => {
    if (value.length > 1) {
      return `Uploaded ${value.length} files`;
    }

    return get(value, '0.name', '');
  }, [value]);

  return (
    <>
      <Box className="df aic jcsb">
        <Button
          color={color}
          size={size}
          variant={variant}
          disabled={disabled}
          onClick={handleClick}
        >
          {title}
        </Button>
        <span className={classes.ellipsis}>{chosenFiles}</span>
      </Box>
      {error && error.message && (
        <Typography
          style={{
            margin: '3px 0 0 14px',
            fontSize: 12,
          }}
          color="error"
        >
          {error.message}
        </Typography>
      )}
      <input
        id="id"
        ref={ref}
        className={classes.input}
        disabled={disabled}
        type="file"
        multiple={multiple}
        accept={accept}
        onChange={handleChange}
      />
    </>
  );
};
