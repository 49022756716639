import React from 'react';
import { TableCell, TableSortLabel, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import Draggable from 'react-draggable';
import {
  DragDropContext,
  Droppable,
  Draggable as ReorderDraggable,
} from 'react-beautiful-dnd';

export const TableHeader = ({
  headerHeight,
  columns,
  classes,
  enableRowSelection,
  sorting,
  onChangeSorting,
  enableResizing,
  enableReordering,
  onResize,
  onReorder,
  width,
}) => {
  return (
    <DragDropContext onDragEnd={onReorder}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided) => (
          <div
            className={classes.headerContainer}
            style={{ width }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {enableRowSelection && (
              <div
                style={{
                  minWidth: 30,
                  maxWidth: 30,
                  borderBottom: '1px solid #e0e0e0',
                }}
              />
            )}

            {columns.map(
              ({ width, title, field, disableSorting, isHidden }, index) => {
                if (isHidden) {
                  return null;
                }

                return (
                  <ReorderDraggable
                    key={field}
                    draggableId={field}
                    isDragDisabled={!enableReordering}
                    index={index}
                  >
                    {(provided) => (
                      <div ref={provided.innerRef}>
                        <TableCell
                          component="div"
                          className={clsx(
                            classes.flexContainer,
                            classes.headerCell,
                          )}
                          variant="head"
                          style={{
                            height: headerHeight,
                            minWidth: width,
                            maxWidth: width,
                          }}
                          align="left"
                        >
                          <Tooltip
                            title={title}
                            disableFocusListener={false}
                            disableTouchListener={false}
                          >
                            <div className={classes.headerElementWrapper}>
                              <div
                                className={classes.twoRowsEllipsis}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {sorting && !disableSorting ? (
                                  <TableSortLabel
                                    active={sorting.value === field}
                                    direction={
                                      sorting.value === field
                                        ? sorting.direction
                                        : 'asc'
                                    }
                                    onClick={() =>
                                      onChangeSorting(
                                        field,
                                        sorting.value === field
                                          ? sorting.direction
                                          : 'desc',
                                      )
                                    }
                                    style={{
                                      display: 'flex',
                                      width: '100%',
                                    }}
                                  >
                                    <div className={classes.headerEllipsis}>
                                      {title}
                                    </div>
                                  </TableSortLabel>
                                ) : (
                                  title
                                )}
                              </div>
                              {enableResizing && (
                                <Draggable
                                  // TODO find one solution or write it on your own to do both reordering and resizing
                                  // TODO remove key (find our other way) maybe controllable variant
                                  key={`${field}_${width}`}
                                  axis="x"
                                  onStop={(e, data) =>
                                    onResize({ width, offset: data.x, field })
                                  }
                                  bounds={{ left: 30 - width }}
                                >
                                  <div
                                    data-key={'resize-handle'}
                                    className={classes.resizeHandle}
                                  />
                                </Draggable>
                              )}
                            </div>
                          </Tooltip>
                        </TableCell>
                      </div>
                    )}
                  </ReorderDraggable>
                );
              },
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
