import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: customersInfoActions } = createSlice({
  name: 'customersDetails',
  initialState: {
    data: {},
    isLoading: false,
    isUpdating: false,
    isError: false,
  },
  reducers: {
    getCustomersInfo: (state) => {
      state.isLoading = true;
    },
    getCustomersInfoSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
    getCustomersPutError: (state) => {
      state.isError = true;
    },
    updateCustomersInfo: (state) => {
      state.isUpdating = true;
    },
    updateCustomersInfoSuccess: (state, { payload }) => {
      state.isUpdating = false;
      state.data = payload;
    },
    updateCustomersInfoFailed: (state) => {
      state.isUpdating = false;
    },
    removeCustomer: (state) => {
      state.isLoading = true;
    },
  },
});

export const customersInfoSelector = (state) => state.customers.customersInfo;
