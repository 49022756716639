import { reduxHelper } from '@vanongo/utils';
import { api } from '../../services/api';

export const {
  actions: organisationListBriefActions,
  reducer: organisationListBrief,
  selector: organisationListBriefSelector,
  saga: organisationListBriefSaga,
} = reduxHelper({
  actionName: 'getList',
  name: 'organisationListBrief',
  defaultValue: [],
  promise: (body) =>
    api.get(`${process.env.REACT_APP_ADMIN_URL}partners/list-brief`, { body }),
  mapFunc: (arr) =>
    arr.map((el) => ({
      label: el.name,
      value: el.id,
    })),
  fetchOnce: false,
});
