import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: areasInfoActions } = createSlice({
  name: 'areasDetails',
  initialState: {
    data: {},
    isLoading: false,
    isUpdating: false,
    isError: false,
  },
  reducers: {
    getAreasInfo: (state) => {
      state.isLoading = true;
    },
    getAreasInfoSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
    getAreasInfoPutError: (state) => {
      state.isError = true;
    },
    updateAreasInfo: (state) => {
      state.isUpdating = true;
    },
    updateAreasOperationArea: (state) => {
      state.isUpdating = true;
    },
    updateAreasCenterArea: (state) => {
      state.isUpdating = true;
    },
    updateAreasInfoSuccess: (state, { payload }) => {
      state.isUpdating = false;
      state.data = payload;
    },
    updateAreasInfoFailed: (state) => {
      state.isLoading = false;
      state.isUpdating = false;
    },
    removeArea: (state) => {
      state.isLoading = true;
    },
  },
});

export const areasInfoSelector = (state) => state.areas.areasInfo;
