import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: vehiclesListActions } = createSlice({
  name: 'vehiclesList',
  initialState: {
    vehicles: [],
    page: 0,
    pageSize: 10,
    totalCount: 0,
    isLoading: false,
  },
  reducers: {
    getVehiclesList: (state) => {
      state.isLoading = true;
    },
    getVehiclesListSuccess: (
      state,
      { payload: { data, page, totalCount, pageSize } },
    ) => {
      state.isLoading = false;
      state.vehicles = data;
      state.page = page - 1;
      state.pageSize = pageSize;
      state.totalCount = totalCount;
    },
  },
});

export const vehiclesListSelector = (state) => state.vehicles.vehiclesList;
