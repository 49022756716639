import { useSelector } from 'react-redux';
import { areasFilterSelector } from '../../redux/slices/global/areasFilter';

export const useAreaOptions = () => {
  const { options, isLoading } = useSelector(areasFilterSelector);

  return {
    options,
    isLoading,
  };
};
