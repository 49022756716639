import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: ordersInfoActions } = createSlice({
  name: 'ordersInfo',
  initialState: {
    data: {},
    isLoading: false,
    isUpdating: false,
    isError: false,
    isTtnCreating: false,
  },
  reducers: {
    getOrdersInfo: (state) => {
      state.isLoading = true;
    },
    getOrdersInfoSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
    getOrdersInfoError: (state) => {
      state.isError = true;
      state.isLoading = false;
    },
    getOrdersPutError: (state) => {
      state.isError = true;
    },
    updateOrderItem: () => {},
    updateOrderItemSuccess: (state, { payload: { attributes } }) => {
      state.data = {
        ...state.data,
        attributes,
      };
    },
    updateAddressDeliveryInfo: (state) => {
      state.isUpdating = true;
    },
    updateAddressDeliveryInfoSuccess: (state, { payload }) => {
      state.isUpdating = false;
      state.data = {};
    },
    updateOrderItemDeliveryPersonInfo: (state) => {
      state.isUpdating = true;
    },
    updateOrderItemDeliveryPersonInfoSuccess: (state, { payload }) => {
      state.isUpdating = false;
      state.data.deliveryContactPersonDTO = {
        ...state.data.deliveryContactPersonDTO,
        ...payload,
      };
    },
    updateOrderItemPickUpAddress: (state) => {
      state.isUpdating = true;
    },
    updateOrderItemPickUpAddressSuccess: (state, { payload }) => {
      state.isUpdating = false;
      state.data = {};
    },
    updateOrderItemPickUpPersonInfo: (state) => {
      state.isUpdating = true;
    },
    updateOrderItemPickUpPersonInfoSuccess: (state, { payload }) => {
      state.isUpdating = false;
      state.data.pickUpContactPersonDTO = {
        ...state.data.pickUpContactPersonDTO,
        ...payload,
      };
    },
    updateOrdersInfoFailed: (state) => {
      state.isUpdating = false;
    },
    cloneOrderItem: (state) => {},
    changeStatus: (state) => {},
    changeStatusSuccess: (state, { payload: { status } }) => {
      state.data = {
        ...state.data,
        status,
      };
    },
    createTtn: (state) => {
      state.isTtnCreating = true;
    },
    createTtnSuccess: (state) => {
      state.isTtnCreating = false;
    },
    updatePickupDeliveryPeriods: (state) => {
      state.isUpdating = true;
    },
    updatePickupDeliverySuccess: (state) => {
      state.isUpdating = false;
    },
    updatePickupDeliveryFailed: (state) => {
      state.isUpdating = false;
    },
    updateNote: (state) => {
      state.isUpdating = true;
    },
    updateNoteSuccess: (state) => {
      state.isUpdating = false;
    },
    updateNoteFailed: (state) => {
      state.isUpdating = false;
    },
  },
});

export const ordersInfoSelector = (state) => state.orders.ordersInfo;
