import React, { useCallback } from 'react';
import { Typography, Switch, Box } from '@material-ui/core';

export const FormSwitch = ({ title, value, onChange }) => {
  const handleChange = useCallback(
    (e) => {
      onChange(e.target.checked);
    },
    [onChange],
  );

  return (
    <Box>
      <Typography variant="h5" color="textPrimary">
        {title}
      </Typography>
      <Switch
        checked={value}
        color="secondary"
        edge="start"
        onChange={handleChange}
      />
    </Box>
  );
};
