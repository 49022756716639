import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: smsConfigurationActions } = createSlice({
  name: 'smsConfiguration',
  initialState: {
    list: [],

    page: 0,
    totalCount: 0,
    pageSize: 10,

    isLoading: false,
    isUpdating: false,
  },
  reducers: {
    getSmsConfigurationList: (state) => {
      state.isLoading = true;
    },
    getSmsConfigurationListSuccess: (state, { payload: { data } }) => {
      state.isLoading = false;
      state.list = data;
    },
    createSmsConfiguration: (state) => {
      state.isUpdating = true;
    },
    createSmsConfigurationSuccess: (state) => {
      state.isUpdating = false;
    },
  },
});

export const smsConfigurationSelector = (state) => state.smsConfiguration;
