import { reduxHelper } from '@vanongo/utils';
import { api } from '../../services/api';

export const {
  actions: pricingModelGetItemActions,
  reducer: pricingModelGetItem,
  selector: pricingModelGetItemSelector,
  saga: pricingModelGetItemSaga,
} = reduxHelper({
  actionName: 'get',
  name: 'pricingModelGetItem',
  promise: (id) =>
    id
      ? api.get(`${process.env.REACT_APP_ADMIN_URL}pricing-models/${id}`)
      : Promise.resolve({}),
});
