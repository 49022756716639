import { all, put, takeLatest } from 'redux-saga/effects';
import { serviceDetailsActions } from '../../slices/b2cServices/b2cServiceDetails';
import { apiRequest } from '../../../services/apiSdk';
import { get } from '@vanongo/utils';

const parseResponse = (data) => {
  return {
    ...data,
    areaId: get(data.pricingPolygons, '0.areaId'),
    pricingPolygons: data.pricingPolygons.map((el) => el.id),
    servicesType: '' + +data.isMandatory,
  };
};

const getServiceDetailsSaga = function* ({ payload }) {
  try {
    const response = yield apiRequest(
      `${process.env.REACT_APP_ADMIN_URL}b2c-services/${payload}`,
    );

    yield put(
      serviceDetailsActions.getServicesDetailsSuccess({
        data: parseResponse(response),
      }),
    );
  } catch (e) {
    console.error(e);
  }
};

export const b2cServiceDetailsSaga = function* () {
  yield all([
    takeLatest(
      serviceDetailsActions.getServicesDetails.type,
      getServiceDetailsSaga,
    ),
  ]);
};
