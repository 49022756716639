import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { ConfirmPopup } from '../ConfirmPopup/ConfirmPopup';
import { Input } from '@vanongo/components/formComponents/Input/Input';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../constants/namespaces';
import { Select } from '@vanongo/components/formComponents/Select/Select';

const CUSTOM_REASON = 'Other';
const customReasonOption = { value: CUSTOM_REASON, label: 'Custom' };
const emptyReasons = ['FIRST_TIME'];

const optionsMap = {
  TO_RETURN: [
    { value: 'CARGO_IS_DECLINED', label: 'TO_RETURN_CARGO_IS_DECLINED' },
    customReasonOption,
  ],
  AT_WAREHOUSE_TO_RETURN: [
    {
      value: 'CARGO_IS_DECLINED',
      label: 'AT_WAREHOUSE_TO_RETURN_CARGO_IS_DECLINED',
    },
    customReasonOption,
  ],
  TO_RESCHEDULE: [
    {
      value: 'RECIPIENT_UNAVAILABLE',
      label: 'TO_RESCHEDULE_RECIPIENT_UNAVAILABLE',
    },
    {
      value: 'IMPOSSIBLE_TO_ARRIVE',
      label: 'TO_RESCHEDULE_IMPOSSIBLE_TO_ARRIVE',
    },
    {
      value: 'RESCHEDULED',
      label: 'TO_RESCHEDULE_RESCHEDULED',
    },
    customReasonOption,
  ],
  AT_WAREHOUSE: [
    { value: 'FIRST_TIME', label: 'AT_WAREHOUSE_FIRST_TIME' },
    { value: 'RESCHEDULED', label: 'AT_WAREHOUSE_RESCHEDULED' },
    customReasonOption,
  ],
};

const getComment = ({ reason, comment }) => {
  if (!reason || reason === CUSTOM_REASON) {
    return comment;
  }

  if (emptyReasons.includes(reason)) {
    return '';
  }

  return reason;
};

export const ChangeOrderItemStatus = ({
  newStatus,
  status,
  id,
  closePopup,
  action,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(NAMESPACES.ORDERS);
  const { t: t_global } = useTranslation(NAMESPACES.GLOBAL);
  const [comment, setComment] = useState('');
  const [reason, changeReason] = useState(null);

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = () => {
    const commentToSend = getComment({ reason, comment, status: newStatus });

    dispatch(
      action({
        id,
        comment: commentToSend,
        status: newStatus,
      }),
    );
  };

  const Comment = (
    <Box mt={3}>
      <Input
        multiline
        title={t_global('Comment')}
        rows={4}
        value={comment}
        onChange={handleChange}
      />
    </Box>
  );

  const options = optionsMap[newStatus];
  const render = !!options ? (
    <Box mt={3}>
      <Select
        value={reason}
        options={options}
        onChange={changeReason}
        title={t('Reason')}
        t={t}
      />
      {reason === CUSTOM_REASON && Comment}
    </Box>
  ) : (
    Comment
  );

  return (
    <ConfirmPopup
      closePopup={closePopup}
      render={render}
      disabled={(reason === CUSTOM_REASON && !comment) || (!reason && !comment)}
      onConfirm={handleSubmit}
      title={t('Change status')}
      text={t('CHANGE_STATUS_REASON', {
        prevStatus: status,
        nextStatus: newStatus,
      })}
    />
  );
};
