import React, { useCallback } from 'react';
import { debounce, makeStyles } from '@material-ui/core';
import { FilterOnTheFlyItem } from './FilterOnTheFlyItem';

const useStyles = makeStyles((theme) => ({
  item: {
    marginRight: 10,
  },
}));

const setFilters = ({ values, value, name, onChange }) => {
  onChange({ ...values, [name]: value });
};

const debounceSetFilters = debounce(setFilters, 500);

export const FilterOnTheFly = ({ metadata = [], values, onChange }) => {
  const classes = useStyles();

  const handleChange = useCallback(
    (value, name) => {
      debounceSetFilters({
        values,
        value,
        name,
        onChange,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange],
  );

  return metadata.map((item) => (
    <FilterOnTheFlyItem
      key={item.name}
      item={item}
      classes={classes}
      values={values}
      onChange={handleChange}
    />
  ));
};
