import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: areasPaymentMethodsActions } = createSlice({
  name: 'areasPaymentMethods',
  initialState: {
    data: [],
    isLoading: false,
    isUpdating: false,

    payMethodsOptions: [],
    payMethodsOptionsIsFetching: false,
  },
  reducers: {
    getAreasPaymentMethods: (state) => {
      state.isLoading = true;
    },
    getAreasPaymentMethodsSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
    getPaymentMethodsOptions: (state) => {
      state.payMethodsOptionsIsFetching = true;
    },
    getPaymentMethodsOptionsSuccess: (state, { payload }) => {
      state.payMethodsOptionsIsFetching = false;
      state.payMethodsOptions = payload;
    },
    addNewPaymentMethod: (state) => {},
    addNewPaymentMethodSuccess: (state, { payload }) => {
      state.data = [].concat(state.data, payload);
    },
    removePaymentMethod: (state) => {},
    removePaymentMethodSuccess: (state, { payload }) => {
      const index = state.data.findIndex((el) => el.id === payload);
      if (index < 0) {
        return;
      }

      state.data = state.data.filter((el, i) => i !== index);
    },
  },
});

export const areasPaymentMethodsSelector = (state) =>
  state.areas.areasPaymentMethods;
