import { reduxHelper } from '@vanongo/utils';
import { api } from '../../services/api';

export const {
  actions: partnersListBriefActions,
  reducer: partnersListBrief,
  selector: partnersListBriefSelector,
  saga: partnersListBriefSaga,
} = reduxHelper({
  actionName: 'getList',
  name: 'partnersListBrief',
  defaultValue: [],
  promise: (body) =>
    api.get(`${process.env.REACT_APP_ADMIN_URL}partners/list-brief`, { body }),
  mapFunc: (arr) =>
    arr.map((el) => ({
      label: el.name,
      value: el.id,
    })),
  fetchOnce: false,
});
