import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import Backend from 'i18next-chained-backend';
import {
  DEFAULT_LANGUAGE,
  DEFAULT_LOCALISE_FOLDER,
  DEFAULT_TRANSLATION_PATH,
} from '../constants/app';
import { LSManager } from '@vanongo/utils';
import { LANGUAGE } from '../constants/STORAGE_KEYS';

const isDevelopmentMode = process.env.NODE_ENV === 'development';

export let localization = null;

export const languagesOptions = ['en', 'ru'];
export const getLanguage = () => {
  const savedLang = LSManager.get(LANGUAGE);
  if (savedLang) {
    return savedLang;
  }
  const browserLanguage = navigator.language;
  const lang = languagesOptions.find((language) =>
    browserLanguage.includes(language),
  );
  return lang || DEFAULT_LANGUAGE;
};

export const initLocalization = async () => {
  localization = i18next.createInstance();

  if (isDevelopmentMode) {
    await localization
      .use(XHR)
      .use(initReactI18next)
      .init({
        lng: getLanguage(),
        fallbackLng: DEFAULT_LANGUAGE,
        returnEmptyString: false,
        interpolation: {
          escapeValue: false,
        },
        ns: DEFAULT_LOCALISE_FOLDER,
        defaultNS: DEFAULT_LOCALISE_FOLDER,
        debug: false,
        backend: {
          backends: [XHR],
          backendOptions: [{ loadPath: DEFAULT_TRANSLATION_PATH }],
        },
      });
  } else {
    await localization
      .use(Backend)
      .use(initReactI18next)
      .init({
        lng: getLanguage(),
        fallbackLng: DEFAULT_LANGUAGE,
        returnEmptyString: false,
        interpolation: {
          escapeValue: false,
        },
        ns: DEFAULT_LOCALISE_FOLDER,
        defaultNS: DEFAULT_LOCALISE_FOLDER,
        backend: {
          backends: [XHR],
          backendOptions: [{ loadPath: DEFAULT_TRANSLATION_PATH }],
        },
        // FOR DEVELOPMENT
        debug: false,
      });
  }
};
