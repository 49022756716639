import { all, put, takeLatest } from 'redux-saga/effects';
import { groupsListActions } from '../../slices/groups/groups';
import { api } from '../../../services/api';
import history from '../../../services/history';
import { getValidationErrors } from '@vanongo/utils';
import { popupActions } from '../../slices/popup';

const getGroupsListSaga = function* ({
  payload: { pageSize = 10, page = 1 } = {
    pageSize: 10,
    page: 1,
  },
}) {
  try {
    const response = yield api.get(
      `${process.env.REACT_APP_ADMIN_URL}driver-groups/`,
      {
        query: {
          page,
          limit: pageSize,
        },
      },
    );

    yield put(
      groupsListActions.getGroupsListSuccess({
        data: response.results,
        page: response.currentPage,
        pageSize,
        totalCount: response.totalCount,
      }),
    );
  } catch (e) {
    console.log(e);
  }
};

const updateGroupSaga = function* ({ payload: { item, id } }) {
  try {
    const response = yield api.patch(
      `${process.env.REACT_APP_ADMIN_URL}driver-groups/${id}`,
      {
        body: prepareDataItem(item),
      },
    );

    yield put(groupsListActions.updateGroupSuccess(response));
    yield put(popupActions.closePopup());
  } catch (e) {
    console.log(e);
  }
};

const deleteGroupsSaga = function* ({ payload: selection }) {
  try {
    yield Promise.all(
      selection.map(({ id }) =>
        api.delete(`${process.env.REACT_APP_ADMIN_URL}driver-groups/${id}`),
      ),
    );

    yield put(groupsListActions.deleteSuccess());
  } catch (e) {}
};

const prepareDataItem = (data) => ({
  name: data.name,
  attributes: data.attributes.map((el) => el.valueId),
});

const createSaga = function* ({ payload: data }) {
  try {
    yield api.post(`${process.env.REACT_APP_ADMIN_URL}driver-groups/`, {
      body: prepareDataItem(data),
    });

    yield put(groupsListActions.createSuccess());
    history.push(`/gr/groups`);
  } catch (e) {
    console.log(e);
    yield put(groupsListActions.createFailed(getValidationErrors(e)));
  }
};

export const groupsListSaga = function* () {
  yield all([
    takeLatest(groupsListActions.getGroupsList.type, getGroupsListSaga),
    takeLatest(groupsListActions.updateGroup.type, updateGroupSaga),
    takeLatest(groupsListActions.delete.type, deleteGroupsSaga),
    takeLatest(groupsListActions.deleteSuccess.type, getGroupsListSaga),
    takeLatest(groupsListActions.create.type, createSaga),
  ]);
};
