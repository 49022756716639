import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: attributesValuesActions } = createSlice({
  name: 'attributesValues',
  initialState: {
    isLoading: {},
    options: {},
  },
  reducers: {
    getAttributeValues: (state, { payload: id }) => {
      state.isLoading[id] = true;
    },
    getAttributeValuesSuccess: (state, { payload: { options, id } }) => {
      state.isLoading[id] = false;
      state.options[id] = options;
    },
  },
});

export const attributesValuesSelector = (state) =>
  state.groups.attributesValues;
