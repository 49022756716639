import { Provider } from 'react-redux';
import React from 'react';
import { Router } from 'react-router-dom';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';
import store from './redux/store';

import PopupPortal from './popup/popup.portal';
import { theme } from '@vanongo/components';
import { Auth } from './Auth';
import { Root } from './Root';
import { routes } from './routes';
import history from './services/history';
import GlobalStyles from '@vanongo/components/GlobalStyles';
import { SnackbarSingleton } from '@vanongo/components/toast/SnackbarSingleton';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          <SnackbarProvider
            dense={false}
            hideIconVariant={true}
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <>
              <SnackbarSingleton>
                <Router history={history}>
                  <PopupPortal />
                  <GlobalStyles />
                  <Auth>
                    <Root route={routes} />
                  </Auth>
                </Router>
              </SnackbarSingleton>
            </>
          </SnackbarProvider>
        </StylesProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
