import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import PopupLayout from '../PopupLayout/PopupLayout';
import { Select } from '@vanongo/components/formComponents/Select/Select';
import { usePaymentMethodsOptions } from '../../hooks/options/usePaymentMethodsOptions';
import { areasPaymentMethodsActions } from '../../redux/slices/areas/areasPaymentMethods';

const userTypeOptions = ['PARTNER', 'CUSTOMER'];
const cardProcessorOptions = ['STRIPE'];

const CARD = 'CARD';

export const AddPaymentMethodToArea = ({ closePopup, areaId }) => {
  const [userType, onChangeUserType] = useState(null);
  const [payMethod, onChangePayMethod] = useState(null);
  const [cardProcessor, onChangeCardProcessor] = useState(null);
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(
      areasPaymentMethodsActions.addNewPaymentMethod({
        paymentMethod: {
          userType,
          payMethod,
          cardProcessor,
        },
        areaId,
      }),
    );
    closePopup();
  };

  const confirmButtons = [
    { text: 'Cancel', onClick: closePopup },
    {
      color: 'secondary',
      type: 'submit',
      variant: 'contained',
      text: 'Save',
      onClick: onSubmit,
      disabled:
        !userType || !payMethod || (payMethod.label === CARD && !cardProcessor),
    },
  ];

  const handleChangePayMethod = (value, option) => onChangePayMethod(option);
  const handleChangeUserType = (value, option) => onChangeUserType(option);
  const handleChangeCardProcessor = (value, option) =>
    onChangeCardProcessor(option);

  return (
    <PopupLayout
      width={600}
      title={'Add payment methods'}
      buttonConfig={confirmButtons}
    >
      <Box mb={2}>
        <Select
          value={userType && userType.value}
          onChange={handleChangeUserType}
          options={userTypeOptions}
          title="User type"
        />
      </Box>
      <Box mb={2}>
        <Select
          value={payMethod && payMethod.value}
          onChange={handleChangePayMethod}
          effect={usePaymentMethodsOptions}
          title="Payment methods"
        />
      </Box>
      {payMethod && payMethod.label === CARD && (
        <Box>
          <Select
            value={cardProcessor && cardProcessor.value}
            onChange={handleChangeCardProcessor}
            options={cardProcessorOptions}
            title="Card processor"
          />
        </Box>
      )}
    </PopupLayout>
  );
};
