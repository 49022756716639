import { useRef, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import {get, getCoordinatesOfPolygon} from '@vanongo/utils';

export const GeoMapPolygons = ({
  initialData,
  isEditMode = false,
  zoom = 12,
}) => {
  const map = useMap();
  const polygonLayerRef = useRef();

  useEffect(() => {
    const data = JSON.parse(JSON.stringify(initialData));
    if (!data) {
      return;
    }

    const coordinates = getCoordinatesOfPolygon(data);
    if (!coordinates || !coordinates.length) {
      return;
    }
    const polygon = L.polygon(coordinates);
    const center = polygon.getBounds().getCenter();
    map.setView(center, zoom);
    polygonLayerRef.current = polygon;
    map.addLayer(polygon);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    map.pm.addControls({
      drawMarker: false,
      drawCircle: false,
      drawCircleMarker: false,
      drawPolyline: false,
      drawRectangle: false,
      cutPolygon: false,

      removalMode: isEditMode,
      drawPolygon: isEditMode,
      editMode: isEditMode,
      dragMode: isEditMode,
    });

    map.on('pm:create', (e) => {
      // only show one selection element
      if (polygonLayerRef.current) {
        map.removeLayer(polygonLayerRef.current);
      }

      if (e.layer && e.layer.pm && e.shape && e.shape === 'Polygon') {
        polygonLayerRef.current = e.layer;
      }
    });
  }, [map, isEditMode]);

  return null;
};
