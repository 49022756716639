import { all, put, takeEvery } from 'redux-saga/effects';
import { vehiclesCreateActions } from '../../slices/vehicles/vehiclesCreate';
import { commonRequest } from '../apiSaga';
import history from '../../../services/history';

const createVehiclesSaga = function* ({ payload: data }) {
  try {
    const response = yield commonRequest('createVehicle', {
      body: data,
    });
    yield put(vehiclesCreateActions.createVehiclesSuccess());
    history.push(`/vehicles/${response.id}`);
  } catch (e) {
    console.error(e);
    yield put(vehiclesCreateActions.createVehiclesSuccess());
  }
};

export const vehiclesCreateSaga = function* () {
  yield all([
    takeEvery(vehiclesCreateActions.createVehicles.type, createVehiclesSaga),
  ]);
};
