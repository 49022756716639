import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Page } from '../Page';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

export const DefaultLayoutPage = ({ title, children }) => {
  const classes = useStyles();
  return (
    <Page className={classes.root} title={title}>
      {children}
    </Page>
  );
};
