import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: vehiclesCreateActions } = createSlice({
  name: 'vehiclesCreate',
  initialState: {
    isUpdating: false,
  },
  reducers: {
    createVehicles: (state) => {
      state.isUpdating = true;
    },
    createVehiclesSuccess: (state) => {
      state.isUpdating = false;
    },
  },
});

export const vehiclesCreateSelector = (state) => state.vehicles.vehiclesCreate;
