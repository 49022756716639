import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { openedPopupsSelect, popupActions } from '../redux/slices/popup';

export const UsePopupConnect = () => {
  const dispatch = useDispatch();
  const closePopupCallback = useCallback(
    () => dispatch(popupActions.closePopup()),
    [dispatch],
  );

  const openedPopups = useSelector(openedPopupsSelect);
  return {
    openedPopups,
    closePopup: closePopupCallback,
  };
};
