import {
  DRIVERS,
  VEHICLES,
  AREAS,
  CUSTOMERS,
  CARGO,
  ORDERS,
  SOLVER,
  DRIVERS_DEBTOR,
  WAYBILLS,
  VOUCHERS,
  SMS_CONFIGURATION,
  MAP,
} from './constants/routes';

export const menuItems = [
  {
    title: 'Dashboard',
    customIcon: 'MenuDashboard',
    subMenu: [
      {
        title: 'Statistics',
        customIcon: 'MenuStatistic',
        href: '/statistics/delivery-dynamics',
      },
      {
        title: 'Driver debtor',
        customIcon: 'MenuDriverDebtor',
        href: DRIVERS_DEBTOR,
      },
      {
        title: 'Map',
        customIcon: 'DashboardMap',
        href: MAP,
      },
    ],
  },
  {
    title: 'Orders Management',
    customIcon: 'MenuOrdersManagement',
    subMenu: [
      {
        title: 'Cargo',
        customIcon: 'MenuCargo',
        href: CARGO,
      },
      {
        title: 'Orders',
        customIcon: 'MenuOrders',
        href: ORDERS,
      },
      {
        title: 'Waybills',
        customIcon: 'MenuWaybills',
        href: WAYBILLS,
      },
    ],
  },
  {
    title: 'Solver',
    customIcon: 'MenuSolver',
    subMenu: [
      {
        title: 'Solver jobs',
        customIcon: 'MenuSolverJobs',
        href: SOLVER,
      },
    ],
  },
  {
    title: 'Organizations',
    customIcon: 'MenuBtb',
    href: '/partners/partners',
  },
  {
    title: 'Consumers',
    customIcon: 'MenuConsumers',
    subMenu: [
      {
        title: 'Customers',
        customIcon: 'MenuCustomers',
        href: CUSTOMERS,
      },
      {
        title: 'Voucher',
        customIcon: 'MenuVoucher',
        href: VOUCHERS,
      },
    ],
  },
  {
    title: 'Logistic Partners',
    customIcon: 'MenuLogisticPartners',
    subMenu: [
      {
        title: 'Drivers',
        customIcon: 'MenuDrivers',
        href: DRIVERS,
      },
      {
        title: 'Vehicles',
        customIcon: 'MenuVeh',
        href: VEHICLES,
      },
      {
        title: 'Groups',
        customIcon: 'MenuGroups',
        href: '/gr/groups',
      },
    ],
  },
  {
    title: 'Configurations',
    customIcon: 'MenuConfigurations',
    subMenu: [
      {
        title: 'Areas',
        customIcon: 'MenuAreas',
        href: AREAS,
      },
      {
        title: 'Services',
        customIcon: 'MenuServices',
        href: '/services/b2c',
      },
      {
        title: 'Pricing',
        customIcon: 'MenuPricing',
        href: '/services/pricing-model',
      },
      {
        title: 'SMS',
        customIcon: 'SMS',
        href: SMS_CONFIGURATION,
      },
    ],
  },
];
