import { all, takeLatest, put } from 'redux-saga/effects';
import { b2bServicesActions } from '../../slices/b2bServices/list';
import { api } from '../../../services/api';
import { getObjFromQuery, getValidationErrors } from '@vanongo/utils';
import { popupActions } from '../../slices/popup';

const prepareFormData = ({
  organization,
  currency,
  startDate,
  endDate,
  pricingPolygons,
  pricingModel,
  service,
  id,
  paramsValues,
  name,
  ...truePricingModel
}) => {
  return {
    organization,
    currency,
    startDate,
    endDate,
    pricingPolygons,
    service,
    name,
    paramsValues: truePricingModel,
  };
};

const getListSaga = function* ({
  payload: { pageSize = 10, page = 1, queryString } = {
    pageSize: 10,
    page: 1,
  },
}) {
  try {
    const filtersValue = getObjFromQuery(queryString);

    const response = yield api.get(
      `${process.env.REACT_APP_ADMIN_URL}b2b-services`,
      {
        query: {
          page,
          limit: pageSize,
          ...filtersValue,
        },
      },
    );

    yield put(
      b2bServicesActions.getListSuccess({
        data: response.results,
        page: response.currentPage,
        pageSize,
        totalCount: response.totalCount,
      }),
    );
  } catch (e) {}
};

const createSaga = function* ({ payload: { data } }) {
  try {
    yield api.post(`${process.env.REACT_APP_ADMIN_URL}b2b-services`, {
      body: prepareFormData(data),
    });

    yield put(popupActions.closePopup());
    yield put(b2bServicesActions.createSuccess());
  } catch (e) {
    yield put(b2bServicesActions.createFailed(getValidationErrors(e)));
  }
};

const updateSaga = function* ({ payload: { data, serviceId } }) {
  try {
    const response = yield api.patch(
      `${process.env.REACT_APP_ADMIN_URL}b2b-services/${serviceId}`,
      {
        body: prepareFormData(data),
      },
    );

    yield put(popupActions.closePopup());
    yield put(b2bServicesActions.updateSuccess(response));
  } catch (e) {
    yield put(b2bServicesActions.updateFailed(getValidationErrors(e)));
  }
};

const deleteSaga = function* ({ payload: selection }) {
  try {
    yield Promise.all(
      selection.map(({ id }) =>
        api.delete(`${process.env.REACT_APP_ADMIN_URL}b2b-services/${id}`),
      ),
    );

    yield put(b2bServicesActions.deleteSuccess());
  } catch (e) {}
};
export const b2bServicesSaga = function* () {
  yield all([
    takeLatest(b2bServicesActions.getList.type, getListSaga),
    takeLatest(b2bServicesActions.create.type, createSaga),
    takeLatest(b2bServicesActions.createSuccess.type, getListSaga),
    takeLatest(b2bServicesActions.deleteSuccess.type, getListSaga),
    takeLatest(b2bServicesActions.update.type, updateSaga),
    takeLatest(b2bServicesActions.delete.type, deleteSaga),
  ]);
};
