import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: ordersCreateActions } = createSlice({
  name: 'ordersCreate',
  initialState: {
    isUpdating: false,
  },
  reducers: {
    createOrders: (state) => {
      state.isUpdating = true;
    },
    createOrdersSuccess: (state) => {
      state.isUpdating = false;
    },
  },
});

export const ordersCreateSelector = (state) => state.orders.ordersCreate;
