import React from 'react';
import { makeStyles, SvgIcon, Box, Typography } from '@material-ui/core';

import PopupLayout from '../PopupLayout/PopupLayout';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../constants/namespaces';
import { AlertTriangle as AlertIcon } from 'react-feather';

const useStyles = makeStyles(() => ({
  icon: {
    width: 60,
    height: 60,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  textContainer: {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
}));

export const ConfirmPopup = ({
  title,
  text,
  onConfirm,
  closePopup,
  render: Render,
  disabled,
}) => {
  const { t } = useTranslation(NAMESPACES.GLOBAL);
  const classes = useStyles();

  const onSubmit = () => {
    closePopup();
    onConfirm();
  };

  const confirmButtons = [
    { text: t('Cancel'), onClick: closePopup },
    {
      color: 'secondary',
      type: 'submit',
      disabled,
      variant: 'contained',
      text: t('Confirm'),
      onClick: onSubmit,
    },
  ];

  return (
    <PopupLayout
      width={500}
      title={title || t('Confirm action')}
      buttonConfig={confirmButtons}
    >
      <Box className={classes.iconContainer}>
        <SvgIcon color="error" className={classes.icon} fontSize="large">
          <AlertIcon />
        </SvgIcon>
      </Box>
      <Box mt={2} className={classes.textContainer}>
        <Typography
          align="center"
          gutterBottom
          variant="body2"
          color="textPrimary"
        >
          {text || t('Are you sure?')}
        </Typography>

        {Render ? Render : null}
      </Box>
    </PopupLayout>
  );
};
