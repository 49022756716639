import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar, Box, Toolbar, makeStyles, Button } from '@material-ui/core';
import Logo, { WHITE_LOGO } from '@vanongo/components/Logo';
import { THEMES } from '@vanongo/components';
import Account from './Account';
import Settings from './Settings';
import { linkToDrivers } from '../../../constants/links';
import { CityFilter } from './CityFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: '#1791FF',
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  toolbar: {
    minHeight: 64,
  },
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Button>
          <Logo type={WHITE_LOGO} height="32px" onClick={linkToDrivers} />
        </Button>
        <Box ml={2} flexGrow={1} />
        <CityFilter />
        <Settings />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
