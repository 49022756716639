import {
  AREAS_CREATE,
  PARTNERS_CREATE,
  CUSTOMERS_CREATE,
  DRIVERS,
  DRIVERS_CREATE,
  VEHICLES_CREATE,
} from './routes';
import history from '../services/history';

export const linkToDrivers = () => history.push(DRIVERS);
export const linkToCreateDriver = () => history.push(DRIVERS_CREATE);
export const linkToCreatePartner = () => history.push(PARTNERS_CREATE);
export const linkToCreateCustomer = () => history.push(CUSTOMERS_CREATE);
export const linkToCreateArea = () => history.push(AREAS_CREATE);
export const linkToCreateB2cService = () =>
  history.push('/services/b2c/create');
export const linkToCreateVehicle = () => history.push(VEHICLES_CREATE);
