import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  pricingPolygonsListBriefActions,
  pricingPolygonsListBriefSelector,
} from '../../redux/getters/pricingPolygonsListBrief';

export const usePricingPolygonsListBrief = (organization) => {
  const dispatch = useDispatch();
  const { data: options, isLoading } = useSelector(
    pricingPolygonsListBriefSelector,
  );

  useEffect(() => {
    dispatch(pricingPolygonsListBriefActions.get(organization));
  }, [dispatch, organization]);

  return {
    options,
    isLoading,
  };
};
