import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: driversListActions } = createSlice({
  name: 'driversList',
  initialState: {
    drivers: [],
    page: 0,
    totalCount: 0,
    pageSize: 50,
    isLoading: false,
  },
  reducers: {
    getDriverList: (state) => {
      state.isLoading = true;
    },
    getDriverListSuccess: (
      state,
      { payload: { data, page, totalCount, pageSize } },
    ) => {
      state.isLoading = false;
      state.drivers = data;
      state.page = page - 1;
      state.totalCount = totalCount;
      state.pageSize = pageSize;
    },
  },
});

export const driversListSelector = (state) => state.drivers.driversList;
