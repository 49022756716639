import {all, put, takeEvery} from "redux-saga/effects";
import {driversUploadFilesActions} from "../../slices/drivers/driversFiles";
import {api} from "../../../services/api";
import {commonRequest} from "../apiSaga";
import {popupActions} from "../../slices/popup";
import { saveAs } from 'file-saver';

const getUploadFilesListSaga = function* ({
  payload: { queryString: id }
}) {
  try {
    const response = yield api.get(`${process.env.REACT_APP_ADMIN_URL}drivers/${id}/files`);

    yield put(driversUploadFilesActions.getUploadFilesListSuccess({
      data: response.results,
      page: response.currentPage - 1,
      totalCount: response.totalCount,
      pageSize: 10,
    }));

  } catch (e) {
    yield put(driversUploadFilesActions.getUploadFilesListFailed());
    console.error(e);
  }
};

const updateUploadFilesSaga = function* ({payload: {
  title,
  driverId,
  fileId
}}) {
  try {
    yield api.patch(`${process.env.REACT_APP_ADMIN_URL}drivers/${driverId}/files/${fileId}`, {
      body: {
        title: title
      },
    });

    yield put(driversUploadFilesActions.updateUploadFilesSuccess());

    yield put(popupActions.closePopup());

    yield put(driversUploadFilesActions.getUploadFilesList({queryString: driverId}))
  } catch (e) {
    yield put(driversUploadFilesActions.updateUploadFilesFailed());
    console.error(e);
  }
}

const uploadFilesSaga = function* ({
  payload: {
    file,
    driverId,
    title
  }
}) {
  try {
    const data = new FormData();
    data.append('file', file);
    data.append('title', title);

    const response =  yield commonRequest('uploadFiles', {
      parameters: { driverId },
      body: data
    });
    yield put(driversUploadFilesActions.uploadFilesSuccess({
      data: response,
    }));
    yield put(popupActions.closePopup());
  } catch (e) {
    yield put(driversUploadFilesActions.uploadFilesFailed());
    console.error(e);
  }
}

const deleteUploadFilesSaga = function* ({payload: {
  driverId,
  fileId
}}) {
  try {
    yield api.delete(`${process.env.REACT_APP_ADMIN_URL}drivers/${driverId}/files/${fileId}`);

    yield put(driversUploadFilesActions.deleteUploadFilesSuccess());
    yield put(popupActions.closePopup());

    yield put(driversUploadFilesActions.getUploadFilesList({queryString: driverId}))
  } catch (e) {
    yield put(driversUploadFilesActions.deleteUploadFilesFailed());
    console.error(e);
  }
}

const downloadUploadFilesSaga = function* ({
  payload: {
    url
  }}
) {
  try {
    saveAs(url);
    yield put(driversUploadFilesActions.downloadUploadFilesSuccess());
  } catch (e) {
    yield put(driversUploadFilesActions.downloadUploadFilesFailed());
    console.error(e);
  }
}

export const driversUploadFilesSaga = function* () {
  yield all([
    takeEvery(driversUploadFilesActions.getUploadFilesList.type, getUploadFilesListSaga),
    takeEvery(driversUploadFilesActions.updateUploadFiles.type, updateUploadFilesSaga),
    takeEvery(driversUploadFilesActions.uploadFiles.type, uploadFilesSaga),
    takeEvery(driversUploadFilesActions.deleteUploadFiles.type, deleteUploadFilesSaga),
    takeEvery(driversUploadFilesActions.downloadUploadFiles.type, downloadUploadFilesSaga),
  ]);
};
