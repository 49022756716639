import React from 'react';
import { createContext } from 'react';

export const SelectionContext = createContext({
  selection: [],
  onSelectionChange: () => {},
});

export const CustomSelectionProvider = ({
  onSelectionChange,
  selection,
  children,
}) => {
  return (
    <SelectionContext.Provider
      value={{
        selection,
        onSelectionChange,
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};
