import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  areasPaymentMethodsActions,
  areasPaymentMethodsSelector,
} from '../../redux/slices/areas/areasPaymentMethods';

export const usePaymentMethodsOptions = () => {
  const dispatch = useDispatch();
  const { payMethodsOptions: options } = useSelector(
    areasPaymentMethodsSelector,
  );

  useEffect(() => {
    dispatch(areasPaymentMethodsActions.getPaymentMethodsOptions());
  }, [dispatch]);

  return {
    options,
    isLoading: false,
  };
};
