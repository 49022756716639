import { all, put, select, takeEvery } from 'redux-saga/effects';
import {
  cargoInfoActions,
  cargoInfoSelector,
} from '../../slices/cargo/cargoInfo';
import { commonRequest } from '../apiSaga';
import { toast } from '@vanongo/components/toast/SnackbarSingleton';
import { localization } from '../../../services/localization';
import { NAMESPACES } from '../../../constants/namespaces';
import history from '../../../services/history';

const getCargoInfoSaga = function* ({ payload }) {
  const { data } = yield select(cargoInfoSelector);
  if (payload === data.id) {
    yield put(cargoInfoActions.getCargoInfoSuccess(data));
    return;
  }

  try {
    const response = yield commonRequest('getCargoDetails', {
      parameters: { cargoId: payload },
    });
    yield put(cargoInfoActions.getCargoInfoSuccess(response));
  } catch (e) {
    yield put(cargoInfoActions.getCargoInfoSuccess());
  }
};

const changeStatusSaga = function* ({ payload: { cargoId, comment, status } }) {
  try {
    yield commonRequest('changeCargoStatus', {
      parameters: { cargoId },
      body: {
        status,
        reason: comment,
      },
    });

    toast(
      localization.t('Cargo status changed successfully', {
        ns: NAMESPACES.CARGO,
      }),
      {
        variant: 'success',
      },
    );
    yield put(cargoInfoActions.changeStatusSuccess({ status }));
  } catch (e) {
    console.log(e);
  }
};

const updateCargoInfoSaga = function* ({ payload: { cargoId, data } }) {
  try {
    yield commonRequest('updateCargo', {
      parameters: { cargoId },
      body: data,
    });

    toast(
      localization.t('Cargo details changed successfully', {
        ns: NAMESPACES.CARGO,
      }),
      {
        variant: 'success',
      },
    );
    yield put(cargoInfoActions.updateCargoInfoSuccess(data));
    history.push(`/cargo/${cargoId}`);
  } catch (e) {
    console.log(e);
  }
};

export const cargoInfoSaga = function* () {
  yield all([
    takeEvery(cargoInfoActions.getCargoInfo.type, getCargoInfoSaga),
    takeEvery(cargoInfoActions.updateCargoInfo.type, updateCargoInfoSaga),
    takeEvery(cargoInfoActions.changeStatus.type, changeStatusSaga),
  ]);
};
