import React from 'react';
import { makeStyles, TablePagination } from '@material-ui/core';
import { ActionsComponent } from './ActionsComponent';

const emptyFunc = () => '';
const renderValue = (value) => (
  <div style={{ padding: '0px 5px' }}>{value + ' rows '}</div>
);

const useStyles = makeStyles(() => ({
  pagination: {
    height: 40,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
  },
}));
export const Pagination = ({
  rowsPerPageOptions,
  page,
  rowCount,
  onChangePage,
  onChangeRowsPerPage,
  pageSize,
}) => {
  const classes = useStyles();

  const handleChangeRowsPerPage = (e, data) => {
    onChangeRowsPerPage(data.props.value);
  };

  return (
    <TablePagination
      component="div"
      rowsPerPage={pageSize}
      page={page}
      rowsPerPageOptions={rowsPerPageOptions}
      count={rowCount}
      onPageChange={onChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      colSpan={3}
      ActionsComponent={ActionsComponent}
      labelRowsPerPage={''}
      className={classes.pagination}
      labelDisplayedRows={emptyFunc}
      SelectProps={{
        renderValue,
      }}
    />
  );
};
