import L from 'leaflet';
import MapPin from '../../assets/icons/mapIcons/mapPin.svg';

export const defaultMarker = new L.Icon({
  iconUrl: MapPin,
  iconAnchor: [12.5, 25],
  popupAnchor: [0, -10],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(25, 25),
});
