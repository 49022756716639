import { all, takeLatest, put, select } from 'redux-saga/effects';
import { vehiclesListActions } from '../../slices/vehicles/vehiclesList';
import { commonRequest } from '../apiSaga';
import { areasFilterValueSelector } from '../../slices/global/areasFilter';
import { getObjFromQuery } from '@vanongo/utils';

const createFilters = (query) => {
  if (!query) {
    return {};
  }
  if (!Object.keys(query).length) {
    return {};
  }

  return {
    licensePlate: query.licensePlate,
    organizationId: query.organization,
  };
};

const getVehiclesListSaga = function* ({
  payload: { pageSize = 10, page = 1, queryString } = { pageSize: 10, page: 1 },
}) {
  const query = getObjFromQuery(queryString);
  const area = yield select(areasFilterValueSelector);

  try {
    const response = yield commonRequest('getVehicles', {
      parameters: { page, limit: pageSize, area, ...createFilters(query) },
    });

    yield put(
      vehiclesListActions.getVehiclesListSuccess({
        data: response.results,
        page: response.currentPage,
        pageSize,
        totalCount: response.totalCount,
      }),
    );
  } catch (e) {}
};

export const vehiclesListSaga = function* () {
  yield all([
    takeLatest(vehiclesListActions.getVehiclesList.type, getVehiclesListSaga),
  ]);
};
