import React, { useCallback, useState, useMemo } from 'react';
import { Table as TableComponent } from './parts/table/Table';
import { Toolbar } from './parts/toolbar/Toolbar';
import { CustomSelectionProvider } from './context/SelectionContext';
import { LSManager } from '@vanongo/utils';
import { useResizeHook } from './hooks/useResizeHook';
import { useSetColumnWidth } from './hooks/useSetColumnWidth';
import { useTableSettings } from './hooks/useTableSettings';
import { useSelector } from 'react-redux';
import { userInfoSelector } from '../../webAdmin/src/redux/slices/authorization';
import { useReorderHook } from './hooks/useReorderHook';

const setUpColumns = (lsColumns, columns) => {
  if (!lsColumns) {
    return columns;
  }
  const restColumns = [...columns];
  const savedColumns = lsColumns.reduce((acc, lsColumn) => {
    const metaColumnIndex = restColumns.findIndex(
      (column) => lsColumn.field === column.field,
    );
    if (metaColumnIndex < 0) {
      return acc;
    }
    const metaColumn = restColumns.splice(metaColumnIndex, 1)[0];

    acc.push({ ...metaColumn, ...lsColumn });
    return acc;
  }, []);

  if (restColumns.length) {
    return savedColumns.concat(restColumns);
  }

  return savedColumns;
};

export const Table = ({
  rowCount,
  data,
  onRefresh,
  isLoading,
  onSelectionChange,
  selection = [],
  onSelectAll,
  rowKey = 'id',
  enableRowSelection,
  selectionButtonConfig = [],
  page,
  pageSize = 20,
  onChangePage,
  onChangeRowsPerPage,
  rowsPerPageOptions = [10, 20, 50],
  columns,
  rowSize = 'medium',
  sorting,
  onChangeSorting,
  onRowClick,
  enableResizing,
  enableReordering,
  tableName,
  aggregation,
}) => {
  const [width, setTableWidth] = useState(0);
  const userInfo = useSelector(userInfoSelector);
  const LS_KEY = `${tableName}_${userInfo.uid}`;
  const initialUserColumns = useMemo(
    () => setUpColumns(LSManager.get(LS_KEY), columns),
    [LS_KEY, columns],
  );
  const [userColumns, setUserColumns] = useState(initialUserColumns);
  const onResize = useResizeHook({
    setUserColumns,
    LS_KEY,
  });
  const onReorder = useReorderHook({
    setUserColumns,
    LS_KEY,
  });

  const columnsWithWidth = useSetColumnWidth({
    width,
    enableRowSelection,
    userColumns,
  });

  const { resetColumns, setVisibilityColumn } = useTableSettings({
    columns,
    setUserColumns,
    LS_KEY,
  });

  const handleCheckbox = useCallback(
    (rowData) => {
      if (Array.isArray(rowData)) {
        onSelectionChange([]);
        return;
      }

      if (!rowData[rowKey]) {
        return;
      }
      const index = selection.findIndex((e) => e[rowKey] === rowData[rowKey]);
      if (index === -1) {
        onSelectionChange(selection.concat([rowData]), rowData);
      } else {
        onSelectionChange(
          selection.slice(0, index).concat(selection.slice(index + 1)),
          rowData,
        );
      }
    },
    [onSelectionChange, rowKey, selection],
  );

  return (
    <CustomSelectionProvider
      selection={selection}
      onSelectionChange={handleCheckbox}
    >
      <Toolbar
        setTableWidth={setTableWidth}
        onRefresh={onRefresh}
        onSelectAll={onSelectAll}
        selectionButtonConfig={selectionButtonConfig}
        rowCount={rowCount}
        page={page}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        userColumns={userColumns}
        resetColumns={resetColumns}
        setVisibilityColumn={setVisibilityColumn}
        tableName={tableName}
      />
      <TableComponent
        rowKey={rowKey}
        rowSize={rowSize}
        data={data}
        sorting={sorting}
        onChangeSorting={onChangeSorting}
        enableRowSelection={enableRowSelection}
        onRowClick={onRowClick}
        isLoading={isLoading}
        enableResizing={enableResizing}
        enableReordering={enableReordering}
        columnsWithWidth={columnsWithWidth}
        rowCount={rowCount}
        aggregation={aggregation}
        onResize={onResize}
        onReorder={onReorder}
      />
    </CustomSelectionProvider>
  );
};
