import { NameCellRender } from '../../../renders/NameCellRender';
import { AreaRender } from '../../../renders/AreaRender';
import { DateTimeCellRender } from '../../../renders/DateTimeCellRender';
import React from 'react';
import { RegistrationStatusRender } from '../../../renders/RegistrationStatusRender';
import { ArrayRender } from '../../../renders/ArrayRender';

export const driversTableMetadata = [
  {
    title: 'Name',
    field: 'name',
    render: NameCellRender,
  },
  {
    title: 'Phone',
    field: 'phone',
    align: 'center',
    width: 140,
    cellStyle: { fontWeight: 500 },
  },
  {
    field: 'updated',
    title: 'Updated',
    render: ({ updated }) => DateTimeCellRender({ date: updated }),
    width: 80,
  },
  {
    field: 'lastDeliveryDate',
    title: 'Last delivery date',
    render: ({ lastDeliveryDate }) =>
      DateTimeCellRender({ date: lastDeliveryDate }),
    width: 170,
  },
  {
    title: 'Organization',
    field: 'organization',
    render: ({ organization }) => organization?.name,
  },
  {
    title: 'Speak english',
    field: 'speaksEnglish',
    align: 'center',
    width: 170,
    render: ({ speaksEnglish }) => speaksEnglish.toString(),
  },
  {
    title: 'Registered',
    field: 'registrationStatus',
    align: 'center',
    width: 170,
    render: RegistrationStatusRender,
  },
  {
    field: 'attributes',
    title: 'Solver attributes',
    render: ({ attributes }) => ArrayRender(attributes),
  },
  {
    field: 'areaId',
    title: 'Area',
    width: 200,
    render: ({ areaId }) => <AreaRender areaId={areaId} />,
  },
  {
    title: 'Comment',
    field: 'comment',
  },
  {
    title: 'Last stop',
    field: 'lastStop',
    width: 200,
  },
  {
    title: 'Predictive eta for last stop',
    field: 'lastStopEta',
    width: 200,
    render: ({ lastStopEta }) => DateTimeCellRender({ date: lastStopEta }),
  },
  {
    field: 'driverAttributes',
    title: 'Driver attributes',
    render: ({ driverAttributes }) =>
      driverAttributes &&
      driverAttributes
        .slice(0, 3)
        .map((item) => `${item.attribute.attributeType}=${item.value}`)
        .join(', ') + (driverAttributes.length > 3 ? '...' : ''),
  }
];
