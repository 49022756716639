import { useCallback } from 'react';
import { LSManager } from '@vanongo/utils';

export const useReorderHook = ({ setUserColumns, LS_KEY }) => {
  return useCallback(
    ({ destination, source, draggableId }) => {
      if (!destination) {
        return;
      }

      if (destination.index === source.index) {
        return;
      }

      setUserColumns((prevUserColumns) => {
        const nextColumns = Array.from(prevUserColumns);
        nextColumns.splice(source.index, 1);
        nextColumns.splice(destination.index, 0, prevUserColumns[source.index]);

        LSManager.save(LS_KEY, nextColumns);
        return nextColumns;
      });
    },
    [setUserColumns, LS_KEY],
  );
};
