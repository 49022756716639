import React, { useRef, useEffect } from 'react';
import { Polyline } from 'react-leaflet';

export const BorderLine = ({ borderLineCoordinates }) => {
  const borderLineRef = useRef(null);

  useEffect(() => {
    delete borderLineRef.current.pm;
  }, [borderLineRef]);

  if (!borderLineCoordinates) {
    return null;
  }

  return (
    <Polyline
      ref={borderLineRef}
      pathOptions={{ color: '#000' }}
      positions={borderLineCoordinates}
    />
  );
};
