import React, { useState, useCallback } from 'react';
import ReactSelect from 'react-select';
import clsx from 'clsx';
import {
  FormControl,
  InputLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { transformOptions } from './transformOptions';

const useStyles = makeStyles(() => ({
  label: {
    background: 'linear-gradient(180deg, #ffffff00 40%, #fff 40%)',
    padding: '0 2px',
  },
  selectContainer: {
    '& > div': {
      height: 48,
    },
  },
  errorContainer: {
    '& div': {
      borderColor: 'red',
    },
  },
  error: {
    fontSize: 12,
    margin: '3px 14px 0',
  },
}));

const controlStyle = (prev) => ({
  ...prev,
  height: '56px',
});

const menuPortalStyle = (prev) => ({
  ...prev,
  zIndex: 1500,
});

export const Select = ({
  onChange,
  options: receivedOptions = [],
  value,
  t,
  title,
  error,
  isClearable = true,
  disabled,
  effect = () => ({}),
  effectParams,
  optionsEffect = {isLoading: false, options: undefined},
}) => {
  const classes = useStyles();
  const [focused, setFocused] = useState(false);

  const receivedFromEffect = effect(effectParams);
  const options = optionsEffect?.options || transformOptions({
    options: receivedFromEffect.options || receivedOptions,
    t,
  });
  const valueIsValid = value === 0 || value;
  const receivedValue =
    valueIsValid && Array.isArray(value)
      ? value
      : options.find((el) => el.value === value) || null;

  const toggleFocused = () => {
    setFocused((prev) => !prev);
  };

  const handleChange = useCallback(
    (e) => {
      onChange(e && e.value, e);
    },
    [onChange],
  );

  return (
    <FormControl
      fullWidth
      variant={'outlined'}
      focused={!!valueIsValid || focused || !!receivedFromEffect.inputValue}
    >
      <InputLabel className={!disabled ? classes.label : ''}>
        {title}
      </InputLabel>

      <ReactSelect
        className={clsx(classes.selectContainer, {
          [classes.errorContainer]: error && !focused,
        })}
        value={receivedValue}
        onChange={handleChange}
        options={optionsEffect.options || options}
        menuPortalTarget={document.body}
        isLoading={optionsEffect.isLoading || receivedFromEffect.isLoading}
        onInputChange={receivedFromEffect.onInputChange}
        inputValue={receivedFromEffect.inputValue}
        isClearable={isClearable}
        onFocus={toggleFocused}
        onBlur={toggleFocused}
        escapeClearsValue={true}
        placeholder=""
        isDisabled={disabled}
        styles={{
          control: controlStyle,
          menuPortal: menuPortalStyle,
        }}
      />

      {error && error.message && (
        <Typography className={classes.error} color="error">
          {error.message}
        </Typography>
      )}
    </FormControl>
  );
};
