import { all, put, select, takeEvery } from 'redux-saga/effects';
import {
  solverInfoActions,
  solverInfoSelector,
} from '../../slices/solver/solverInfo';
import { commonRequest } from '../apiSaga';
import history from '../../../services/history';
import { WAYBILLS } from '../../../constants/routes';
import {api} from "../../../services/api";

const getSolverInfoSaga = function* ({ payload }) {
  try {
    const response = yield commonRequest('getSolverJob', {
      parameters: { solverJobId: payload },
    });
    yield put(solverInfoActions.getSolverInfoSuccess(response));
  } catch (e) {
    yield put(solverInfoActions.getSolverInfoFail());
  }
};

const checkCacheAndGetSolverInfoSaga = function* ({ payload }) {
  const { data } = yield select(solverInfoSelector);
  if (payload === data.id) {
    yield put(solverInfoActions.getSolverInfoSuccess(data));
    return;
  }

  yield getSolverInfoSaga({ payload });
};

const updateSolverInfoSaga = function* ({ payload: { data, vehicleId } }) {
  try {
    const response = yield commonRequest('updateVehicleDetails', {
      body: data,
      parameters: { vehicleId },
    });

    yield put(solverInfoActions.updateSolverInfoSuccess(response));
    history.push(`/solver/${vehicleId}`);
  } catch (e) {
    yield put(solverInfoActions.updateSolverInfoFailed());
  }
};

const createWaybillSaga = function* ({ payload }) {
  try {
    yield commonRequest('createWaybillsFromSolverJob', {
      parameters: { solverJobId: payload },
    });

    yield put(solverInfoActions.createWaybillSuccess());
    history.push(`${WAYBILLS}`);
  } catch (e) {
    console.error(e);
  }
};

const restartJobSaga = function* ({ payload }) {
  try {
    const response = yield commonRequest('restartSolverJob', {
      parameters: { solverJobId: payload },
    });

    yield put(solverInfoActions.restartJobSuccess(response));
  } catch (e) {
    console.error(e);
  }
};

const exportRoutesAsText = function* ({ payload }) {
  try {
    const response = yield api.get(`${process.env.REACT_APP_ADMIN_URL}solver/jobs/${payload}/export-routes`);

    yield put(solverInfoActions.exportRoutesAsTextSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(solverInfoActions.exportRoutesAsTextFailed());
  }
};

export const solverInfoSaga = function* () {
  yield all([
    takeEvery(
      solverInfoActions.getSolverInfo.type,
      checkCacheAndGetSolverInfoSaga,
    ),
    takeEvery(
      solverInfoActions.checkSolverInfoStatusChanging,
      getSolverInfoSaga,
    ),
    takeEvery(solverInfoActions.updateSolverInfo.type, updateSolverInfoSaga),
    takeEvery(solverInfoActions.createWaybill.type, createWaybillSaga),
    takeEvery(solverInfoActions.restartJob.type, restartJobSaga),
    takeEvery(solverInfoActions.exportRoutesAsText.type, exportRoutesAsText),
  ]);
};
