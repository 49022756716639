import { all, put, takeEvery } from 'redux-saga/effects';
import { areasCreateActions } from '../../slices/areas/areasCreate';
import { commonRequest } from '../apiSaga';
import history from '../../../services/history';
import { prepareAreaInfo } from './helper/prepareAreaInfo';

const createAreasSaga = function* ({ payload: data }) {
  data.operationArea = {};
  data.cityCentreArea = {};
  try {
    const response = yield commonRequest('createArea', {
      body: prepareAreaInfo(data),
    });

    yield put(areasCreateActions.createAreasSuccess());
    history.push(`/areas/${response.id}/details/edit-region`);
  } catch (e) {
    console.error(e);
    yield put(areasCreateActions.createAreasSuccess());
  }
};

export const areasCreateSaga = function* () {
  yield all([takeEvery(areasCreateActions.createAreas.type, createAreasSaga)]);
};
