import React from 'react';
import { Filter } from './Filter';
import { FilterOnTheFly } from './FilterOnTheFly';
import { Typography } from '@material-ui/core';
import { FilterValues } from './FilterValues';

export const FilterPanel = ({
  onChange,
  defaultValues,
  values,
  metadata = [],
  metadataOnTheFly = [],
  filterValuesMapper,
  title,
}) => {
  const handleChange = (filters) => {
    onChange({ ...values, ...filters });
  };

  return (
    <div className="df aic" style={{ maxHeight: 48 }}>
      {title && (
        <Typography
          variant="h3"
          color="textPrimary"
          style={{ marginRight: 10 }}
        >
          {title}
        </Typography>
      )}
      <FilterOnTheFly
        metadata={metadataOnTheFly}
        onChange={handleChange}
        values={values}
      />
      {!!metadata.length && (
        <Filter
          onSubmit={handleChange}
          metadata={metadata}
          defaultValues={defaultValues}
          values={values}
        />
      )}
      <FilterValues
        onChange={onChange}
        values={values}
        valuesMapper={filterValuesMapper}
        metadata={metadata}
        metadataOnTheFly={metadataOnTheFly}
      />
    </div>
  );
};
