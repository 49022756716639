// TODO: rewrite logic to use dynamic import
export const lazyImportIcon = (glyph) => {
  try {
    // eslint-disable-next-line global-require,import/no-dynamic-require
    return require(`../assets/icons/${glyph}.svg`);
  } catch (e) {
    return null;
  }
};

export default function () {
  function importAllIcons(r) {
    r.keys().forEach(r);
  }

  const icons = require.context('../assets/icons/', true, /.+\.svg$/);

  importAllIcons(icons);

  return icons
    .keys()
    .map((a) => a.replace('./', ''))
    .map((a) => a.replace('.svg', ''));
}
