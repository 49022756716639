import { combineReducers } from '@reduxjs/toolkit';
import { reducer as authorization } from './slices/authorization';
import { reducer as driversList } from './slices/drivers/driversList';
import { reducer as driversInfo } from './slices/drivers/driversInfo';
import { reducer as driversSchedule } from './slices/drivers/driversSchedule';
import { reducer as driversVehicles } from './slices/drivers/driversVehicles';
import { reducer as driversCreate } from './slices/drivers/driversCreate';
import { reducer as driversUploadFiles } from './slices/drivers/driversFiles';

import { reducer as partnersList } from './slices/partners/partnersList';
import { reducer as usersPartnersList } from './slices/partners/usersPartnersList';
import { reducer as partnersInfo } from './slices/partners/partnersInfo';
import { reducer as partnersCreate } from './slices/partners/partnersCreate';
import { reducer as b2bServices } from './slices/b2bServices/list';

import { reducer as customersList } from './slices/customers/customersList';
import { reducer as customersInfo } from './slices/customers/customersInfo';
import { reducer as customersCreate } from './slices/customers/customersCreate';

import { reducer as areasFilter } from './slices/global/areasFilter';
import { reducer as areasList } from './slices/areas/areasList';
import { reducer as areasCreate } from './slices/areas/areasCreate';
import { reducer as areasInfo } from './slices/areas/areasInfo';
import { reducer as areasPaymentMethods } from './slices/areas/areasPaymentMethods';
import { reducer as areasLegalDocuments } from './slices/areas/areasLegalDocuments';
import { reducer as areasAttributes } from './slices/areas/areasAttributes';
import { reducer as areasPricingPolygons } from './slices/areas/areasPricingPolygons';

import { reducer as vehiclesList } from './slices/vehicles/vehiclesList';
import { reducer as vehiclesCreate } from './slices/vehicles/vehiclesCreate';
import { reducer as vehiclesInfo } from './slices/vehicles/vehiclesInfo';

import { reducer as waybillsList } from './slices/waybills/waybillsList';
import { reducer as waybillsCreate } from './slices/waybills/waybillsCreate';
import { reducer as waybillsInfo } from './slices/waybills/waybillsInfo';

import { reducer as ordersList } from './slices/orders/ordersList';
import { reducer as ordersCreate } from './slices/orders/ordersCreate';
import { reducer as ordersInfo } from './slices/orders/ordersInfo';
import { reducer as orderStatusHistory } from './slices/orders/orderStatusHistory';
import { reducer as orderSmsHistory } from './slices/orders/orderSmsHistory';

import { reducer as solverList } from './slices/solver/solverList';
import { reducer as solverInfo } from './slices/solver/solverInfo';
import { reducer as solverCreate } from './slices/solver/solverCreate';
import { reducer as solverLog } from './slices/solver/solverLog';

import { reducer as cargoList } from './slices/cargo/cargoList';
import { reducer as cargoInfo } from './slices/cargo/cargoInfo';
import { reducer as cargoStatusHistory } from './slices/cargo/cargoStatusHistory';

import { reducer as b2cServicesList } from './slices/b2cServices/b2cServicesList';
import { reducer as b2cServiceDetails } from './slices/b2cServices/b2cServiceDetails';
import { reducer as b2cServicesOption } from './slices/b2cServices/b2cServicesOption';
import { reducer as servicesList } from './slices/services/servicesList';
import { reducer as pricingModelList } from './slices/services/pricingModelList';
import { reducer as earningsList } from './slices/services/earningsList';

import { reducer as globalPopupReducer } from './slices/popup';

import { reducer as vouchers } from './slices/vouchers/vouchersList';

import { reducer as driversDebtor } from './slices/driversDebtor/driversDebtor';

import { reducer as deliveryDynamics } from './slices/statistics/deliveryDynamics';

import { reducer as attributes } from './slices/groups/attributes';
import { reducer as groupsList } from './slices/groups/groups';
import { reducer as attributesValues } from './slices/groups/attributesValues';

import { reducer as smsConfiguration } from './slices/smsConfiguration/smsConfiguration';

import { reducer as mapStopList } from './slices/map/mapStopList';

import { optionsReducer } from './options';
import { gettersReducer } from './getters/index';

const rootReducer = combineReducers({
  authorization,
  drivers: combineReducers({
    driversList,
    driversInfo,
    driversSchedule,
    driversVehicles,
    driversCreate,
    driversUploadFiles,
  }),
  partners: combineReducers({
    partnersList,
    partnersInfo,
    partnersCreate,
    b2bServices,
    usersPartnersList,
  }),
  customers: combineReducers({
    customersList,
    customersInfo,
    customersCreate,
  }),
  areas: combineReducers({
    areasList,
    areasInfo,
    areasCreate,
    areasFilter,
    areasPaymentMethods,
    areasLegalDocuments,
    areasAttributes,
    areasPricingPolygons,
  }),
  vehicles: combineReducers({
    vehiclesList,
    vehiclesCreate,
    vehiclesInfo,
  }),
  waybills: combineReducers({
    waybillsList,
    waybillsCreate,
    waybillsInfo,
  }),
  solver: combineReducers({
    solverList,
    solverInfo,
    solverCreate,
    solverLog,
  }),
  orders: combineReducers({
    ordersList,
    ordersCreate,
    ordersInfo,
    orderStatusHistory,
    orderSmsHistory,
  }),
  cargo: combineReducers({
    cargoList,
    cargoInfo,
    cargoStatusHistory,
  }),
  services: combineReducers({
    b2cServicesList,
    b2cServiceDetails,
    b2cServicesOption,
    servicesList,
    pricingModelList,
    earningsList,
  }),
  vouchers,
  driversDebtor,
  statistics: combineReducers({
    deliveryDynamics,
  }),
  groups: combineReducers({
    groupsList,
    attributes,
    attributesValues,
  }),
  popup: combineReducers({
    global: globalPopupReducer,
  }),
  options: optionsReducer,
  smsConfiguration,
  mapStopList,
  getters: gettersReducer,
});

export default rootReducer;
