import { all, put, takeLatest } from 'redux-saga/effects';
// import { api } from '../../../services/api';
import { smsConfigurationActions } from '../../slices/smsConfiguration/smsConfiguration';
import { SMS_CONFIGURATION } from '../../../constants/routes';
import history from '../../../services/history';

const mock = [
  {
    id: 'uui1',
    name: 'test',
    smsProvider: 'smsProvider1',
    code: 'asd',
  },
  {
    id: 'uui2',
    name: 'test2',
    smsProvider: 'smsProvider2',
    code: 'qwe',
  },
  {
    id: 'uui3',
    name: 'test3',
    smsProvider: 'smsProvider3',
    code: 'zxc',
  },
];

const getSmsConfigurationListSaga = function* () {
  try {
    // const response = yield api.get(
    //   `${process.env.REACT_APP_ADMIN_URL}lists/sms-providers`
    // );

    yield put(
      smsConfigurationActions.getSmsConfigurationListSuccess({
        data: mock,
      }),
    );
  } catch (e) {
    console.log(e);
  }
};

const createSmsConfigurationSaga = function* ({ payload: data }) {
  try {
    console.log(data);
    // yield api.post(`${process.env.REACT_APP_ADMIN_URL}lists/sms-providers`, {
    //   body: data,
    // });

    yield put(smsConfigurationActions.createSmsConfiguration());
    history.push(SMS_CONFIGURATION);
  } catch (e) {
    console.log(e);
  }
};

export const smsConfigurationListSaga = function* () {
  yield all([
    takeLatest(
      smsConfigurationActions.getSmsConfigurationList.type,
      getSmsConfigurationListSaga,
    ),
    takeLatest(
      smsConfigurationActions.createSmsConfiguration.type,
      createSmsConfigurationSaga,
    ),
  ]);
};
