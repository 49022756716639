import { call, select } from 'redux-saga/effects';
// import { CANCEL } from 'redux-saga';

import { HTTP_HEADERS, METHOD } from '../../constants/api';
import { get } from '@vanongo/utils';
import { tokenSelector } from '../slices/authorization';
import { apiSdk } from '../../services/apiSdk';
import { toast } from '@vanongo/components/toast/SnackbarSingleton';
import { localization } from '../../services/localization';
import { commonErrorToast } from '@vanongo/components/toast/commonErrorToast';
import { signOutSaga } from './authorization/authorization.saga';

// export const request = (url, options) => {
//   const controller = new AbortController();
//   const req = fetch(url, {
//     ...options,
//     signal: controller.signal,
//   })
//     // Handle response status code, which not in range 200-299
//     .then(async (response) => {
//       const contentType = response.headers.get('content-type');
//       const responseIsJson =
//         contentType && contentType.includes('application/json');
//       const method = responseIsJson ? 'json' : 'text';
//       const data = await response[method]();
//
//       if (response.status < 200 || response.status > 300) {
//         const payload = {
//           status: response.status,
//           response: data,
//         };
//
//         return Promise.reject(payload);
//       }
//
//       return data;
//     });
//
//   req[CANCEL] = () => controller.abort();
//
//   return req;
// };

export function* commonError(e) {
  if ([401, 403].includes(get(e, 'response.status'))) {
    yield signOutSaga();
    return;
  }

  const errorMessage = get(e, 'response.data.errorMessage');
  if (errorMessage) {
    toast(errorMessage, commonErrorToast());
  } else {
    toast(localization.t('Something went wrong'), commonErrorToast());
  }
}

export function* commonRequest(
  operationId,
  {
    body,
    parameters,
    customHeaders = {},
    withoutToken,
    disableErrorHandling,
  } = {
    customHeaders: {},
  },
) {
  const token = yield select(tokenSelector);
  for (let key in parameters) {
    if (parameters[key] === '' || parameters[key] === null) {
      delete parameters[key];
    }
  }

  const headers = {
    [HTTP_HEADERS.CONTENT_TYPE]: 'application/json',
    ...customHeaders,
  };

  if (token && !withoutToken) {
    headers[HTTP_HEADERS.AUTHORIZATION] = token;
  }

  try {
    const api = yield apiSdk;
    const response = yield call(api[operationId], parameters, body, {
      headers,
    });
    return response.data;
  } catch (e) {
    if (!disableErrorHandling) {
      yield commonError(e, { url: operationId, body, parameters, e });
    }

    throw e;
  }
}

export function* customRequest(
  url,
  {
    body,
    customHeaders = {},
    method = METHOD.GET,
    withoutToken,
    disableErrorHandling,
    baseURL,
  } = {
    customHeaders: {},
    method: METHOD.GET,
  },
) {
  const token = yield select(tokenSelector);

  const headers = {
    [HTTP_HEADERS.CONTENT_TYPE]: 'application/json',
    ...customHeaders,
  };

  if (token && !withoutToken) {
    headers[HTTP_HEADERS.AUTHORIZATION] = token;
  }

  try {
    const api = yield apiSdk;
    const response = yield call(api[method], url, {
      headers,
      data: body,
      baseURL,
    });

    return response.data;
  } catch (e) {
    if (!disableErrorHandling) {
      yield commonError(e, { url, body, e });
    }

    throw e;
  }
}
