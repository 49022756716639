import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  serviceInfoActions,
  serviceInfoSelector,
} from '../../redux/getters/serviceInfo';

export const useServiceInfo = (serviceId) => {
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector(serviceInfoSelector);

  useEffect(() => {
    dispatch(serviceInfoActions.get(serviceId));
  }, [dispatch, serviceId]);

  return { data, isLoading };
};
