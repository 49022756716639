import { ConfirmWithCommentPopup } from './ConfirmWithCommentPopup/ConfirmWithCommentPopup';
import { ConfirmPopup } from './ConfirmPopup/ConfirmPopup';
import { ConnectVehicleToDriver } from './ConnectVehicleToDriver/ConnectVehicleToDriver';
import { AddPaymentMethodToArea } from './AddPaymentMethodToArea/AddPaymentMethodToArea';
import { CreateSolverJobPopup } from './CreateSolverJobPopup/CreateSolverJobPopup';
import { SolverJobLogPopup } from './SolverJobLogPopup/SolverJobLogPopup';
import { FormPopup } from './FormPopup/FormPopup';
import { ChangeOrderItemStatus } from './ChangeOrderItemStatus/ChangeOrderItemStatus';
import { AssignDrivers } from './AssignDrivers/AssignDrivers';
import { ConsignmentNote } from './ConsignmentNote/ConsignmentNote';
import { B2bServiceFormPopup } from './B2bServiceFormPopup';
import { InfoPopup } from './InfoPopup/InfoPopup';
import { UploadPopup } from "./UploadPopup/UploadPopup";
import { ImportOrdersPopup } from "./FormPopupConfirm/ImportOrdersPopup";

export const CONFIRM_POPUP = 'ConfirmPopup';
export const CONNECT_VEHICLE_TO_DRIVER_POPUP = 'ConnectVehicleToDriver';
export const CONFIRM_WITH_COMMENT_POPUP = 'ConfirmWithCommentPopup';
export const ADD_PAYMENT_METHOD_TO_AREA_POPUP = 'AddPaymentMethodToArea';
export const CREATE_SOLVER_JOB_POPUP = 'CreateSolverJobPopup';
export const SOLVER_JOB_LOG_POPUP = 'SolverJobLogPopup';
export const FORM_POPUP = 'FormPopup';
export const CHANGE_ORDER_ITEM_STATUS = 'ChangeOrderItemStatus';
export const ASSIGN_DRIVERS_POPUP = 'AssignDrivers';
export const CONSIGNMENT_NOTE_POPUP = 'ConsignmentNote';
export const B2B_SERVICE_FORM_POPUP = 'B2bServiceFormPopup';
export const INFO_POPUP = 'InfoPopup';
export const UPLOAD_POPUP = 'UploadPopup';
export const IMPORT_ORDERS_POPUP = 'ImportOrdersPopup';

export const popupMap = {
  ConfirmPopup,
  ConnectVehicleToDriver,
  ConfirmWithCommentPopup,
  AddPaymentMethodToArea,
  CreateSolverJobPopup,
  SolverJobLogPopup,
  FormPopup,
  ChangeOrderItemStatus,
  AssignDrivers,
  ConsignmentNote,
  B2bServiceFormPopup,
  InfoPopup,
  UploadPopup,
  ImportOrdersPopup,
};
