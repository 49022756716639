import { all, takeLatest, put } from 'redux-saga/effects';
import { deliveryDynamicsActions } from '../../slices/statistics/deliveryDynamics';
import { api } from '../../../services/api';
import { getObjFromQuery } from '@vanongo/utils';

const getDeliveryDynamicsSaga = function* ({
  payload: { queryString, type } = {},
}) {
  try {
    const filtersValue = getObjFromQuery(queryString);
    if (!filtersValue?.dateFrom || !filtersValue?.dateTo) {
      yield put(
        deliveryDynamicsActions.getDeliveryDynamicsSuccess({
          data: null,
        }),
      );
    } else {
      const response = yield api.get(
        `${process.env.REACT_APP_ADMIN_URL}charts/delivery-dynamics`,
        {
          query: { ...filtersValue, groupBy: type },
        },
      );

      yield put(
        deliveryDynamicsActions.getDeliveryDynamicsSuccess({
          data: response,
        }),
      );
    }
  } catch (e) {
    console.log(e);
  }
};

export const deliveryDynamicsSaga = function* () {
  yield all([
    takeLatest(
      deliveryDynamicsActions.getDeliveryDynamics.type,
      getDeliveryDynamicsSaga,
    ),
  ]);
};
