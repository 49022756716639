import { all, takeEvery, takeLatest, put, select } from 'redux-saga/effects';
import { cargoListActions } from '../../slices/cargo/cargoList';
import { commonRequest } from '../apiSaga';
import { areasFilterValueSelector } from '../../slices/global/areasFilter';
import { cargoFilterListMetadata } from '../../../views/cargo/CargoList/filterListMetadata';
import { createFilters } from '@vanongo/utils';
import { toast } from '@vanongo/components/toast/SnackbarSingleton';
import { localization } from '../../../services/localization';

const getCargoListSaga = function* ({
  payload: { pageSize = 10, page = 1, queryString } = { pageSize: 10, page: 1 },
}) {
  const area = yield select(areasFilterValueSelector);

  try {
    const response = yield commonRequest('getCargo', {
      parameters: {
        page,
        limit: pageSize,
        area,
        ...createFilters(queryString, cargoFilterListMetadata),
      },
    });

    yield put(
      cargoListActions.getCargoListSuccess({
        data: response.results,
        page: response.currentPage,
        pageSize,
        totalCount: response.totalCount,
      }),
    );
  } catch (e) {
    console.log(e);
  }
};

const changeCargoSaga = function* ({ payload: { cargoId, status, comment } }) {
  try {
    yield commonRequest('changeCargoStatus', {
      parameters: { cargoId },
      body: {
        status,
        reason: comment,
      },
    });

    toast(localization.t('Cargo status changed successfully'), {
      variant: 'success',
    });
    yield put(cargoListActions.changeStatusSuccess({ cargoId, status }));
  } catch (e) {}
};

export const cargoListSaga = function* () {
  yield all([
    takeLatest(cargoListActions.getCargoList.type, getCargoListSaga),
    takeEvery(cargoListActions.changeStatus.type, changeCargoSaga),
  ]);
};
