import { all, takeEvery, put } from 'redux-saga/effects';
import { solverCreatePopupActions } from '../../slices/solver/solverCreate';
import { popupActions } from '../../slices/popup';
import { commonRequest } from '../apiSaga';
import history from '../../../services/history';
import { SOLVER } from '../../../constants/routes';
import { parsePointCoordinates, secondsFromTime } from '@vanongo/utils';

const pairsMap = (data) => {
  return data.map((el) => {
    el.startLocation = parsePointCoordinates(el.startLocation);
    el.finishLocation = parsePointCoordinates(el.finishLocation);
    el.id = `${el.driverId}_${el.vehicleId}`;

    return el;
  });
};

const getDriverVehiclesPairsSaga = function* ({
  payload: { pageSize = 10, page = 1, params } = {
    pageSize: 50,
    page: 1,
  },
}) {
  try {
    const response = yield commonRequest('getPairVehicleDriver', {
      parameters: { limit: pageSize, page: page, ...params },
    });

    yield put(
      solverCreatePopupActions.getDriverVehiclesPairsSuccess({
        data: pairsMap(response.results),
        totalCount: response.totalCount,
        page: response.currentPage - 1,
        pageSize: pageSize,
      }),
    );
  } catch (e) {}
};

const createDataHelper = (data) => {
  return {
    solver: data.solver.container,
    orderItems: data.selectedOrders.map((el) => el.id),
    plannedWorkSessions: data.selectedDrivers.map((el) => {
      const preparedData = {
        driverId: el.driverId,
        vehicleId: el.vehicleId,
      };
      if (el.helpers) {
        preparedData.helpers = +el.helpers;
      }

      if (el.start) {
        preparedData.start = secondsFromTime(el.start);
      }

      if (el.end) {
        preparedData.end = secondsFromTime(el.end);
      }

      if (el.startLocation && el.startLocation.lng && el.startLocation.lat) {
        preparedData.startLocation = {
          lng: +el.startLocation.lng,
          lat: +el.startLocation.lat,
        };
      }

      if (
        el.useFinishLocation &&
        el.finishLocation &&
        el.finishLocation.lng &&
        el.finishLocation.lat
      ) {
        preparedData.useFinishLocation = el.useFinishLocation;
        preparedData.finishLocation = {
          lng: +el.finishLocation.lng,
          lat: +el.finishLocation.lat,
        };
      }

      return preparedData;
    }),
    deliveryDate: new Date(data.deliveryDate) / 1000,
    createWaybills: data.createWaybills
  };
};

const createSolverJobSaga = function* ({ payload: data }) {
  try {
    const response = yield commonRequest('createSolverJobs', {
      body: createDataHelper(data),
    });

    yield put(solverCreatePopupActions.createSolverJobSuccess());
    yield put(popupActions.closePopup());
    history.push(`${SOLVER}/${response.id}`);
  } catch (e) {
    yield put(solverCreatePopupActions.createSolverJobSuccess());
  }
};

export const solverCreateSaga = function* () {
  yield all([
    takeEvery(
      solverCreatePopupActions.getDriverVehiclesPairs.type,
      getDriverVehiclesPairsSaga,
    ),
    takeEvery(
      solverCreatePopupActions.createSolverJob.type,
      createSolverJobSaga,
    ),
  ]);
};
