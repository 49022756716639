import { all, put, takeEvery } from 'redux-saga/effects';
import { usersPartnersListActions } from '../../slices/partners/usersPartnersList';
import {apiRequest} from "../../../services/apiSdk";

const getUsersPartnersListSaga = function* ({
    payload: { params: partnerId }
  }
) {
  try {
    const response = yield apiRequest(
        `${process.env.REACT_APP_ADMIN_URL}partners/${partnerId}/users`
    );
    yield put(
      usersPartnersListActions.getUsersPartnersListSuccess({
        data: response,
      }),
    );
  } catch (e) {
    console.error(e)
  }
};

export const usersPartnersSaga = function* () {
  yield all([
    takeEvery(
      usersPartnersListActions.getUsersPartnersList.type,
      getUsersPartnersListSaga,
    ),
  ]);
};
