import React from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { BreadcrumbsItem } from './BreadcrumbsItem';
import { Breadcrumbs as MaterialBreadcrumbs } from '@material-ui/core';

export const Breadcrumbs = ({ data }) => {
  return (
    <MaterialBreadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {data.map(BreadcrumbsItem)}
    </MaterialBreadcrumbs>
  );
};
