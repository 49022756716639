import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: driversDebtorActions } = createSlice({
  name: 'driversDebtor',
  initialState: {
    driversDebtor: [],
    page: 0,
    pageSize: 10,
    totalCount: 0,
    isLoading: false,
  },
  reducers: {
    getDriversDebtor: (state) => {
      state.isLoading = true;
    },
    getDriversDebtorSuccess: (
      state,
      { payload: { data, page, totalCount, pageSize } },
    ) => {
      console.log(data);
      state.isLoading = false;
      state.driversDebtor = data;
      state.page = page - 1;
      state.totalCount = totalCount;
      state.pageSize = pageSize;
    },
  },
});

export const driversDebtorSelector = (state) => state.driversDebtor;
