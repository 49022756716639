import { secondsFromTime } from '@vanongo/utils';

export const prepareAreaInfo = (el) => {
  if (el.startOperationSecond) {
    el.startOperationSecond = secondsFromTime(el.startOperationSecond);
  }
  if (el.endOperationSecond) {
    el.endOperationSecond = secondsFromTime(el.endOperationSecond);
  }
  return el;
};
