import React, { createRef } from 'react';
import { Form } from '@vanongo/components/Form';
import {
  configurationDriverMetadata,
  validationSchema,
} from './configurationDriverMetadata';
import { Box } from '@material-ui/core';
import { ButtonConfigRender } from '@vanongo/components/Buttons/ButtonConfigRender';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../../constants/namespaces';
import { useTransformMetadata } from '../../../hooks/useTransformMetadata';
import { toast } from '@vanongo/components/toast/SnackbarSingleton';
import { commonErrorToast } from '@vanongo/components/toast/commonErrorToast';

export const ConfigureDrivers = ({
  activeStep: { selectedDrivers },
  handleBack,
  handleNext,
  closePopup,
}) => {
  const { t } = useTranslation(NAMESPACES.ORDERS);
  const { t: tGlobal } = useTranslation(NAMESPACES.GLOBAL);

  const elRefs = Array(selectedDrivers.length)
    .fill()
    .map(() => createRef());

  const getFormsDataAndHandleNext = async () => {
    try {
      const nextSelectedDrivers = await Promise.all(
        selectedDrivers.map(async (el, index) => {
          const isValid = await elRefs[index].current.trigger();
          if (!isValid) {
            throw new Error();
          }

          return {
            ...el,
            ...elRefs[index].current.getValues(),
          };
        }),
      );
      handleNext({ selectedDrivers: nextSelectedDrivers });
    } catch (e) {
      toast('Some fields are not valid', commonErrorToast());
    }
  };

  const leftButton = [{ text: tGlobal('Close'), onClick: closePopup }];
  const buttonConfig = [
    { text: tGlobal('Back'), onClick: handleBack },
    {
      text: tGlobal('Next'),
      color: 'secondary',
      variant: 'contained',
      onClick: getFormsDataAndHandleNext,
    },
  ];

  const metadata = useTransformMetadata({
    metadata: configurationDriverMetadata,
    t,
  });

  return (
    <>
      <Box mb={2} p={2} height="55vh" overflow="auto">
        {selectedDrivers.map((driverData, index) => (
          <Box mb={2} key={driverData.id}>
            <Form
              ref={elRefs[index]}
              title={`${driverData.firstName} ${driverData.lastName}`}
              metadata={metadata}
              defaultValues={driverData}
              validationSchema={validationSchema}
              buttonConfig={[]}
              columnSize={2}
              backgroundColor="#f4f6f8"
            />
          </Box>
        ))}
      </Box>
      <ButtonConfigRender buttonConfig={buttonConfig} leftSide={leftButton} />
    </>
  );
};
