import React from 'react';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  makeStyles,
} from '@material-ui/core';
import { DetailsRow } from './DetailsRow';
import { DetailsHeader } from './DetailsHeader';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: theme.typography.fontWeightMedium,
    width: '50%',
  },
  value: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
}));

export const Details = ({
  metadata,
  title,
  data,
  className,
  onEdit,
  hasEdit,
  canEdit,
  width,
}) => {
  const classes = useStyles();

  return (
    <Card className={clsx(className)} style={{ width }}>
      <CardHeader
        className={classes.header}
        title={
          <DetailsHeader
            title={title}
            canEdit={canEdit}
            hasEdit={hasEdit}
            onEdit={onEdit}
          />
        }
      />
      <Divider />
      <Table>
        <TableBody>
          {metadata.map(DetailsRow.bind(null, { classes, data }))}
        </TableBody>
      </Table>
    </Card>
  );
};
