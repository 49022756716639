const metadata = [
  {
    name: 'city',
    type: 'string',
    title: 'City',
  },
  {
    name: 'address',
    type: 'string',
    title: 'Address',
  },
  {
    name: 'shopName',
    type: 'string',
    title: 'Shop name',
  },
  {
    name: 'shopPhone',
    type: 'string',
    title: 'Shop phone',
  },
  {
    name: 'productName',
    type: 'string',
    title: 'Product name',
  },
];

export default metadata;
