import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: solverCreatePopupActions } = createSlice({
  name: 'solverCreate',
  initialState: {
    isPending: false,

    driverVehiclesPairs: [],
    driverVehiclesPairsTotalCount: 0,
    driverVehiclesPairsPage: 0,
    driverVehiclesPairsPageSize: 10,
    isDriverVehiclesPairsFetching: false,
  },
  reducers: {
    getDriverVehiclesPairs: (state) => {
      state.isDriverVehiclesPairsFetching = true;
    },
    getDriverVehiclesPairsSuccess: (
      state,
      { payload: { data, page, totalCount, pageSize } },
    ) => {
      state.isDriverVehiclesPairsFetching = false;
      state.driverVehiclesPairs = data;
      state.driverVehiclesPairsTotalCount = totalCount;
      state.driverVehiclesPairsPage = page;
      state.driverVehiclesPairsPageSize = pageSize;
    },
    clearDriverVehicleData: (state) => {
      state.driverVehiclesPairs = [];
    },
    createSolverJob: (state) => {
      state.isPending = true;
    },
    createSolverJobSuccess: (state) => {
      state.isPending = false;
    },
  },
});

export const solverCreatePopupSelector = (state) => state.solver.solverCreate;
export const isSolverCreatePendingSelector = (state) =>
  state.solver.solverCreate.isPending;
