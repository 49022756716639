import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: serviceDetailsActions } = createSlice({
  name: 'serviceDetails',
  initialState: {
    data: {},
    isLoading: false,
  },
  reducers: {
    getServicesDetails: (state) => {
      state.isLoading = true;
    },
    getServicesDetailsSuccess: (state, { payload: { data } }) => {
      state.isLoading = false;
      state.data = data;
    },
  },
});

export const serviceDetailsSelector = (state) =>
  state.services.b2cServiceDetails;
