export const DRIVERS = '/drivers';
export const DRIVERS_INFO = '/drivers/:driverId';
export const DRIVERS_CREATE = '/drivers/create';
export const PARTNERS = '/partners/:tab';
export const PARTNERS_CREATE = '/partners/create';

export const CUSTOMERS = '/customers';
export const CUSTOMERS_CREATE = '/customers/create';
export const CUSTOMERS_INFO = '/customers/:customerId';
export const CUSTOMERS_EDIT_INFO = '/customers/:customerId/edit-info';

export const AREAS = '/areas';
export const AREAS_CREATE = '/areas/create';
export const AREAS_INFO = '/areas/:areaId/:tab';
export const AREAS_EDIT_INFO = '/areas/:areaId/details/edit-info';
export const AREAS_EDIT_REGION = '/areas/:areaId/details/edit-region';
export const AREAS_EDIT_CENTER = '/areas/:areaId/details/edit-center';
export const AREAS_ADD_POLYGON = '/areas/:areaId/pricing-polygon/create';
export const AREAS_EDIT_POLYGON =
  '/areas/:areaId/pricing-polygon/edit-pricing-polygon/:pricingPolygonId';

export const VEHICLES = '/vehicles';
export const VEHICLES_CREATE = '/vehicles/create';
export const VEHICLES_DETAILS = '/vehicles/:vehicleId';
export const VEHICLES_EDIT_INFO = '/vehicles/:vehicleId/edit-info';

export const WAYBILLS = '/waybills';
export const WAYBILLS_CREATE = '/waybills/create';
export const WAYBILLS_DETAILS = '/waybills/:waybillId';
export const WAYBILLS_EDIT_INFO = '/waybills/:waybillId/edit-info';

export const SERVICES = '/services/:tab';
export const SERVICES_DETAILS = '/services/:tab/:id';
export const SERVICES_CREATE = '/services/:tab/create';

export const SOLVER = '/solver';
export const SOLVER_INFO = '/solver/:jobId';

export const CARGO = '/cargo';
export const CARGO_DETAILS = '/cargo/:cargoId';
export const CARGO_EDIT_DETAILS = '/cargo/:cargoId/edit';

export const ORDERS = '/orders';
export const ORDERS_CREATE = '/orders/create';
export const ORDERS_DETAILS = '/orders/:orderId';
export const ORDERS_EDIT_DELIVERY_INFO = '/orders/:orderId/edit-delivery-info';
export const ORDERS_EDIT_PICK_UP_INFO = '/orders/:orderId/edit-pick-up-info';

export const VOUCHERS = '/vouchers';
export const VOUCHERS_CREATE = '/vouchers/create';
export const VOUCHERS_UPDATE = '/vouchers/:id';

export const DRIVERS_DEBTOR = '/drivers-debtor';
export const STATISTICS = '/statistics/:tab';
export const MAP = '/map';

export const GROUPS = '/gr/:tab';
export const GROUPS_CREATE = '/gr/:tab/create';
export const GROUPS_EDIT = '/gr/:tab/edit/:id';

export const SMS_CONFIGURATION = '/sms-configuration';
export const SMS_CONFIGURATION_CREATE = '/sms-configuration/create';
export const SMS_CONFIGURATION_EDIT = '/sms-configuration/:id/edit';
