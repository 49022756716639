import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: waybillsListActions } = createSlice({
  name: 'waybillsList',
  initialState: {
    waybills: [],
    page: 0,
    totalCount: 0,
    pageSize: 10,
    isLoading: false,

    isFetchingExport: false,
  },
  reducers: {
    getWaybillsList: (state) => {
      state.isLoading = true;
    },
    getWaybillsListSuccess: (
      state,
      { payload: { data, page, totalCount, pageSize } },
    ) => {
      state.isLoading = false;
      state.waybills = data;
      state.page = page - 1;
      state.totalCount = totalCount;
      state.pageSize = pageSize;
    },
    dataExport: (state) => {
      state.isFetchingExport = true;
    },
    dataExportSuccess: (state) => {
      state.isFetchingExport = false;
    },
  },
});

export const waybillsListSelector = (state) => state.waybills.waybillsList;
