import { all, put, takeEvery } from 'redux-saga/effects';
import { ordersCreateActions } from '../../slices/orders/ordersCreate';
import { commonRequest } from '../apiSaga';
import history from '../../../services/history';

const createOrdersSaga = function* ({ payload: data }) {
  try {
    const response = yield commonRequest('createOrder', {
      body: data,
    });
    yield put(ordersCreateActions.createOrdersSuccess());
    history.push(`/orders/${response.id}`);
  } catch (e) {
    yield put(ordersCreateActions.createOrdersSuccess());
  }
};

export const ordersCreateSaga = function* () {
  yield all([
    takeEvery(ordersCreateActions.createOrders.type, createOrdersSaga),
  ]);
};
