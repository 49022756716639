export const NAMESPACES = {
  DRIVERS: 'drivers',
  SERVICES: 'services',
  PARTNERS: 'partners',
  CUSTOMERS: 'customers',
  AREAS: 'areas',
  VEHICLES: 'vehicles',
  ORDERS: 'orders',
  GLOBAL: 'global',
  CARGO: 'cargo',
  MENU: 'menu',
  WAYBILL: 'waybill',
  VOUCHERS: 'vouchers',
  DRIVERS_DEBTOR: 'driversDebtor',
  STATISTICS: 'statistics',
  MAP: 'map',
  GROUPS: 'groups',
  SMS: 'sms',
  SOLVER: 'solver',
  UI_KIT: 'ui-kit',
};
