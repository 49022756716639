import { useMemo } from 'react';

export const useSetColumnWidth = ({
  width,
  enableRowSelection,
  userColumns,
}) => {
  return useMemo(() => {
    const columns = userColumns;
    const collectionWithSize = columns.filter((e) => !!e.width && !e.isHidden);
    const collectionWithoutSize = columns.filter(
      (e) => !e.width && !e.isHidden,
    );

    let remainingWidth =
      width - collectionWithSize.reduce((result, b) => result + b.width, 0);

    if (enableRowSelection) {
      remainingWidth -= 30;
    }
    // if (this.enableRowExpanding) {
    //   remainingWidth -= 20;
    // }

    const defaultWidth = Math.floor(
      remainingWidth / collectionWithoutSize.length,
    );

    const autoWidth = Math.max(50, defaultWidth);
    return columns.map((el) => {
      if (!el.width) {
        return { ...el, width: autoWidth };
      }

      return el;
    });
  }, [width, enableRowSelection, userColumns]);
};
