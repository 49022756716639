import { reduxHelper } from '@vanongo/utils';
import { api } from '../../services/api';

export const {
  actions: partnerFeaturesOptionsActions,
  reducer: partnerFeaturesOptions,
  selector: partnerFeaturesOptionsSelector,
  saga: partnerFeaturesOptionsSaga,
} = reduxHelper({
  actionName: 'getPartnerFeaturesOptions',
  name: 'partnerFeaturesOptions',
  promise: () =>
    api.get(`${process.env.REACT_APP_ADMIN_URL}partners/features`),
});
