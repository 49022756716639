import { all, put, takeEvery, select } from 'redux-saga/effects';
import {
  attributesValuesActions,
  attributesValuesSelector,
} from '../../slices/groups/attributesValues';
import { api } from '../../../services/api';

const parseResponse = (data) => {
  return data.values.map((el) => ({ value: el.id, label: el.value }));
};

const getAttributeValuesSaga = function* ({ payload: id }) {
  const { options } = yield select(attributesValuesSelector);

  if (options[id]) {
    return yield put(
      attributesValuesActions.getAttributeValuesSuccess({
        options: options[id],
        id,
      }),
    );
  }

  try {
    const response = yield api.get(
      `${process.env.REACT_APP_ADMIN_URL}attributes/${id}`,
    );

    yield put(
      attributesValuesActions.getAttributeValuesSuccess({
        options: parseResponse(response),
        id,
      }),
    );
  } catch (e) {
    console.error(e);
  }
};

export const attributesValuesSaga = function* () {
  yield all([
    takeEvery(
      attributesValuesActions.getAttributeValues.type,
      getAttributeValuesSaga,
    ),
  ]);
};
