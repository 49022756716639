import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: driversCreateActions } = createSlice({
  name: 'driversCreate',
  initialState: {
    isUpdating: false,
  },
  reducers: {
    createDrivers: (state) => {
      state.isUpdating = true;
    },
    createDriversSuccess: (state) => {
      state.isUpdating = false;
    },
  },
});

export const driversCreateSelector = (state) => state.drivers.driversCreate;
