import { all, takeEvery, put, call, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import {authorizationActions, SIGN_OUT, WINDOW_FOCUS} from '../../slices/authorization';
import { commonRequest } from '../apiSaga';
import firebase from '../../../services/firebase';
import {checkTokenAndRefresh, clearGetTokenTimeout, executeGetToken} from './token.saga';

const provider = new firebase.auth.OAuthProvider('microsoft.com');
provider.setCustomParameters({
  tenant: 'vanongo.com',
});

export const signInSaga = function* () {
  const auth = firebase.auth();

  try {
    const response = yield call([auth, auth.signInWithPopup], provider);
    const { uid: firebaseUid, email, displayName } = response.user;
    const [firstName, lastName] = displayName.split(' ');
    yield commonRequest('createOperator', {
      body: {
        firebaseUid,
        email,
        firstName,
        lastName,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const signOutSaga = function* () {
  const auth = firebase.auth();

  try {
    yield call(clearGetTokenTimeout);
    yield call([auth, auth.signOut]);
  } catch (error) {
    console.log(error);
  }
};

const createAuthChannel = () =>
  eventChannel((emit) =>
    firebase.auth().onAuthStateChanged((user) => emit({ user })),
  );

export const userAuthorizationStatus = function* () {
  const chan = yield call(createAuthChannel);
  while (true) {
    const data = yield take(chan);
    const { user } = data;

    if (user) {
      yield put(authorizationActions.setUserInfo({ user }));
      yield executeGetToken(user); // eslint-ignore

      console.info('User data received');
    } else {
      yield call(clearGetTokenTimeout);
      yield put(authorizationActions.clearAuth());
    }
  }
};

export const authorizationSaga = function* () {
  yield all([
    takeEvery(authorizationActions.signIn.type, signInSaga),
    takeEvery(SIGN_OUT, signOutSaga),
    takeEvery(WINDOW_FOCUS, checkTokenAndRefresh),
    userAuthorizationStatus(),
  ]);
};
