import React from 'react';
import { TextField } from '@material-ui/core';

export const CustomInput = React.forwardRef(
  ({ value, onClick, error, onChange, title, addOnChange, disabled }, ref) => (
    <TextField
      ref={ref}
      value={value}
      onClick={onClick}
      onChange={addOnChange && onChange}
      error={!!error}
      label={title}
      disabled={disabled}
      fullWidth
      variant="outlined"
      helperText={error && error.message}
    />
  ),
);
