import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: solverLogActions } = createSlice({
  name: 'solverLog',
  initialState: {
    content: null,
    isLoading: false,
  },
  reducers: {
    getSolverLogForJob: (state) => {
      state.isLoading = true;
      state.content = null;
    },
    getSolverLogForJobSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.content = payload;
    },
  },
});

export const solverLogSelector = (state) => state.solver.solverLog;
