import { get } from './get';
import L from 'leaflet';

export const getCoordinatesOfPolygon = (data) => {
  let geometry = null;

  if (!data) {
    return [];
  }

  switch (data.type) {
    case 'Feature':
      geometry = data.geometry;
      break;
    case 'FeatureCollection':
      geometry = get(data, 'features.0.geometry', null);
      break;
    default:
      break;
  }

  if (!geometry || !['Polygon', 'MultiPolygon'].includes(geometry.type)) {
    return [];
  }

  let coords = geometry ? geometry.coordinates : null;

  if (!coords) {
    return [];
  }

  return L.GeoJSON.coordsToLatLngs(coords, geometry.type === 'Polygon' ? 1 : 2);
};
