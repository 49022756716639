import React, { useRef, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { signOut, userInfoSelector } from '../../../redux/slices/authorization';
import { getInitials } from '@vanongo/utils';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 36,
    height: 36,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}));

const Account = () => {
  const { t } = useTranslation('global');
  const userInfo = useSelector(userInfoSelector);
  const dispatch = useDispatch();
  const logOut = useCallback(() => {
    dispatch(signOut());
  }, [dispatch]);
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    handleClose();
    logOut();
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar alt="User" className={classes.avatar} src={userInfo.photoURL}>
          {getInitials(userInfo.displayName)}
        </Avatar>
        <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {userInfo.displayName}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={handleLogout}>{t('Logout')}</MenuItem>
      </Menu>
    </>
  );
};

export default Account;
