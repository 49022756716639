import { all, takeLatest, put } from 'redux-saga/effects';
import { apiRequest } from '../../../services/apiSdk';
import { servicesOptionActions } from '../../slices/b2cServices/b2cServicesOption';

const getServicesOptionListSaga = function* ({ payload }) {
  try {
    const response = yield apiRequest(
      `${process.env.REACT_APP_ADMIN_URL}service-types`,
    );

    yield put(servicesOptionActions.getServicesOptionSuccess(response));
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const b2cServicesOptionSaga = function* () {
  yield all([
    takeLatest(
      servicesOptionActions.getServicesOption.type,
      getServicesOptionListSaga,
    ),
  ]);
};
