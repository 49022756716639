import { all, takeLatest, put } from 'redux-saga/effects';
import { earningsListActions } from '../../slices/services/earningsList';
import { api } from '../../../services/api';
import { popupActions } from '../../slices/popup';

const aggregatorParamsValues = (items) =>
  items.reduce((total, value) => {
    const key = Object.keys(value)[0];
    total[key] = +value[key];
    return total;
  }, {});

const listSaga = function* ({
  payload: { pageSize = 10, page = 1 } = {
    pageSize: 10,
    page: 1,
  },
}) {
  try {
    const response = yield api.get(
      `${process.env.REACT_APP_ADMIN_URL}driver-services`,
      {
        query: {
          page,
          limit: pageSize,
        },
      },
    );

    yield put(
      earningsListActions.getEarningsListSuccess({
        data: response.results,
        page: response.currentPage,
        pageSize,
        totalCount: response.totalCount,
      }),
    );
  } catch (e) {
    console.log(e);
  }
};

const createSaga = function* ({ payload: data }) {
  const paramsValues = aggregatorParamsValues(data.paramsValues);

  try {
    const response = yield api.post(
      `${process.env.REACT_APP_ADMIN_URL}driver-services`,
      {
        body: { ...data, paramsValues },
      },
    );

    yield put(popupActions.closePopup());
    yield put(earningsListActions.createSuccess(response));
  } catch (e) {
    console.log(e);
  }
};

const updateSaga = function* ({ payload: { data, id } }) {
  const paramsValues = aggregatorParamsValues(data.paramsValues);

  try {
    const response = yield api.patch(
      `${process.env.REACT_APP_ADMIN_URL}driver-services/${id}`,
      {
        body: { ...data, paramsValues },
      },
    );

    yield put(popupActions.closePopup());
    yield put(earningsListActions.updateSuccess(response));
  } catch (e) {
    console.log(e);
  }
};

const deleteSaga = function* ({ payload: { selection } }) {
  try {
    yield Promise.all(
      selection.map((item) =>
        api.delete(
          `${process.env.REACT_APP_ADMIN_URL}driver-services/${item.id}`,
        ),
      ),
    );

    yield put(popupActions.closePopup());
    yield put(earningsListActions.deleteSuccess());
  } catch (e) {
    console.log(e);
  }
};

export const earningsListSaga = function* () {
  yield all([
    takeLatest(earningsListActions.getEarningsList.type, listSaga),
    takeLatest(earningsListActions.create.type, createSaga),
    takeLatest(earningsListActions.update.type, updateSaga),
    takeLatest(earningsListActions.delete.type, deleteSaga),
    takeLatest(earningsListActions.deleteSuccess.type, listSaga),
  ]);
};
