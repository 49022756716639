import React, { useCallback } from 'react';
import { Row } from './Row';

export const TableComponent = ({
  data,
  columns,
  width,
  rowKey,
  classes,
  onRowClick,
  enableRowSelection,
  rowHeight,
  ellipsisHeight,
}) => {
  const handleRowClick = useCallback(
    ({ event, rowData }) => {
      if (!onRowClick) {
        return;
      }
      if (event.target.tagName === 'A') {
        return;
      }
      if (event.target.type !== 'checkbox') {
        onRowClick(event, rowData);
      }
    },
    [onRowClick],
  );

  return data.map((rowData) => (
    <Row
      key={rowData[rowKey]}
      width={width}
      columns={columns}
      rowData={rowData}
      rowKey={rowKey}
      classes={classes}
      onRowClick={handleRowClick}
      rowHeight={rowHeight}
      ellipsisHeight={ellipsisHeight}
      enableRowSelection={enableRowSelection}
    />
  ));
};
