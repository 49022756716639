import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: solverInfoActions } = createSlice({
  name: 'solverDetails',
  initialState: {
    data: {},
    exportRoutesAsText: '',
    isLoading: false,
    isUpdating: false,
    isError: false,
  },
  reducers: {
    checkSolverInfoStatusChanging: () => {},
    getSolverInfo: (state) => {
      state.isLoading = true;
    },
    getSolverInfoSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
    getSolverInfoFail: (state) => {},
    updateSolverInfo: (state) => {
      state.isUpdating = true;
    },
    updateSolverInfoSuccess: (state, { payload }) => {
      state.isUpdating = false;
      state.data = payload;
    },
    updateSolverInfoFailed: (state) => {
      state.isUpdating = false;
    },
    createWaybill: (state) => {},
    createWaybillSuccess: (state) => {
      state.data = { ...state.data, status: 'WAYBILL_CREATION_SUCCESS' };
    },
    restartJob: () => {},
    restartJobSuccess: (state, { payload }) => {
      state.data = payload;
    },
    exportRoutesAsText: (state) => {
      state.isLoading = true;
    },
    exportRoutesAsTextSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.exportRoutesAsText = payload;
    },
    exportRoutesAsTextFailed: (state) => {
      state.isLoading = false;
    },
  },
});

export const solverInfoSelector = (state) => state.solver.solverInfo;
