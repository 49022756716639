import { reduxHelper } from '@vanongo/utils';
import { api } from '../../services/api';

export const {
  actions: servicesListBriefActions,
  reducer: servicesListBrief,
  selector: servicesListBriefSelector,
  saga: servicesListBriefSaga,
} = reduxHelper({
  actionName: 'get',
  name: 'servicesListBrief',
  promise: () =>
    api.get(`${process.env.REACT_APP_ADMIN_URL}services/list-brief`),
  mapFunc: (arr) =>
    arr.map((el) => ({
      label: el.name,
      value: el.id,
    })),
  fetchOnce: false,
});
