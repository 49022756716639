import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  solversOptionsActions,
  solversOptionsSelector,
} from '../../redux/options/solvers';

export const useSolversOptions = () => {
  const dispatch = useDispatch();
  const { options, isLoading } = useSelector(solversOptionsSelector);

  useEffect(() => {
    dispatch(solversOptionsActions.getSolversOptions());
  }, [dispatch]);

  return {
    options,
    isLoading,
  };
};
