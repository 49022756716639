import { createSlice } from '@reduxjs/toolkit';
import { LSManager } from '@vanongo/utils';
import { AREAS } from '../../../constants/STORAGE_KEYS';

export const { reducer, actions: areasFilterOptionsActions } = createSlice({
  name: 'areasFilter',
  initialState: {
    options: [],
    selected: LSManager.get(AREAS) || [],
    isLoading: false,
  },
  reducers: {
    getAreasFilterOptions: (state) => {
      state.isLoading = true;
    },
    getAreasFilterOptionsSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.options = payload;
    },
    selectValue: (state, { payload }) => {
      state.selected = payload;
      LSManager.save(AREAS, payload);
    },
  },
});

export const areasFilterValueSelector = (state) =>
  state.areas.areasFilter.selected.join(',') || undefined;
export const areasFilterSelector = (state) => state.areas.areasFilter;
