import { all } from 'redux-saga/effects';
import { combineReducers } from '@reduxjs/toolkit';
import { partnersListBrief, partnersListBriefSaga } from './partnersListBrief';
import {
  partnersListBriefImportEnabled,
  partnersListBriefImportEnabledSaga,
} from './partnersListBriefImportEnabled';
import {
  reducer as pricingPolygonsOptions,
  pricingPolygonsSaga,
} from './pricingPolygonsByAreaId';
import { deliveryTypeOptions, deliveryTypeOptionsSaga } from './deliveryType';
import {
  partnerFeaturesOptions,
  partnerFeaturesOptionsSaga,
} from './partnerFeatures';
import { smsStatusList, smsStatusListSaga } from './smsStatusList';
import {
  groupAttributesOptionsSaga,
  groupAttributesOptions,
} from './groupAttributes';

import { areaById, areaByIdSaga } from './areaById';
import {
  driverGroupsListBriefOptions,
  driverGroupsListBriefOptionsSaga,
} from './driverGroupsListBrief';
import {
  groupAttributesToValueOptions,
  groupAttributesToValueOptionsSaga,
} from './groupAttributesToValue';
import {
  pricingModelListBrief,
  pricingModelListBriefSaga,
} from './pricingModelListBrief';

import {
  pricingPolygonsListBriefSaga,
  pricingPolygonsListBrief,
} from './pricingPolygonsListBrief';

import { servicesListBrief, servicesListBriefSaga } from './servicesListBrief';

import { attributeInfo, attributeInfoSaga } from './attributeInfo';
import { currenciesOptions, currenciesOptionsSaga } from './currenciesOptions';
import { serviceInfo, serviceInfoSaga } from './serviceInfo';
import {
  pricingModelGetItem,
  pricingModelGetItemSaga,
} from './pricingModelGetItem';
import { countriesOptions, countriesOptionsSaga } from './countriesOptions';
import {
  b2bServicesListBrief,
  b2bServicesListBriefSaga,
} from './b2bServicesListBrief';
import {
  smsProvidersOptions,
  smsProvidersOptionsSaga,
} from './smsProviderOptions';
import {
  spaceList,
  spaceListSaga,
} from './spaceList';

import {
  organisationListBrief,
  organisationListBriefSaga,
} from './organisationListBrief';

export const gettersSaga = function* optionsSaga() {
  yield all([
    partnersListBriefSaga(),
    partnersListBriefImportEnabledSaga(),
    pricingPolygonsSaga(),
    deliveryTypeOptionsSaga(),
    partnerFeaturesOptionsSaga(),
    areaByIdSaga(),
    smsStatusListSaga(),
    groupAttributesOptionsSaga(),
    driverGroupsListBriefOptionsSaga(),
    groupAttributesToValueOptionsSaga(),
    pricingModelListBriefSaga(),
    attributeInfoSaga(),
    servicesListBriefSaga(),
    pricingPolygonsListBriefSaga(),
    currenciesOptionsSaga(),
    serviceInfoSaga(),
    pricingModelGetItemSaga(),
    countriesOptionsSaga(),
    b2bServicesListBriefSaga(),
    smsProvidersOptionsSaga(),
    spaceListSaga(),
    organisationListBriefSaga(),
  ]);
};

export const gettersReducer = combineReducers({
  partnersListBrief,
  partnersListBriefImportEnabled,
  pricingPolygonsOptions,
  deliveryTypeOptions,
  partnerFeaturesOptions,
  areaById,
  smsStatusList,
  groupAttributesOptions,
  driverGroupsListBriefOptions,
  groupAttributesToValueOptions,
  pricingModelListBrief,
  attributeInfo,
  servicesListBrief,
  pricingPolygonsListBrief,
  currenciesOptions,
  serviceInfo,
  pricingModelGetItem,
  countriesOptions,
  b2bServicesListBrief,
  smsProvidersOptions,
  spaceList,
  organisationListBrief,
});
