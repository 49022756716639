import { reduxHelper } from '@vanongo/utils';
import { api } from '../../services/api';

export const {
  actions: pricingModelListBriefActions,
  reducer: pricingModelListBrief,
  selector: pricingModelListBriefSelector,
  saga: pricingModelListBriefSaga,
} = reduxHelper({
  actionName: 'get',
  name: 'pricingModelListBrief',
  promise: (query = '') =>
    api.get(
      `${process.env.REACT_APP_ADMIN_URL}pricing-models/list-brief${query}`,
    ),
  mapFunc: (arr) =>
    arr.map((el) => ({
      label: el.name,
      value: el.id,
    })),
  fetchOnce: false,
});
