import React, { useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { ButtonConfigRender } from '@vanongo/components/Buttons/ButtonConfigRender';
import {
  isSolverCreatePendingSelector,
  solverCreatePopupActions,
} from '../../../redux/slices/solver/solverCreate';
import { useDispatch, useSelector } from 'react-redux';
import { areasFilterSelector } from '../../../redux/slices/global/areasFilter';
import { dateddmmyyFormat } from '@vanongo/utils';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../../constants/namespaces';

export const FinalCheck = ({
  activeStep: { selectedOrders, selectedDrivers, deliveryDate, solver, createWaybills },
  closePopup,
  handleBack,
}) => {
  const { t } = useTranslation(NAMESPACES.ORDERS);
  const { t: tGlobal } = useTranslation(NAMESPACES.GLOBAL);

  const dispatch = useDispatch();
  const isPending = useSelector(isSolverCreatePendingSelector);
  const { options } = useSelector(areasFilterSelector);
  const onSubmit = () => {
    dispatch(
      solverCreatePopupActions.createSolverJob({
        selectedOrders,
        selectedDrivers,
        deliveryDate,
        solver,
        createWaybills
      }),
    );
  };

  const leftButton = [{ text: tGlobal('Close'), onClick: closePopup }];
  const buttonConfig = [
    { text: tGlobal('Back'), onClick: handleBack },
    {
      color: 'secondary',
      variant: 'contained',
      text: t('Run solver'),
      onClick: onSubmit,
      disabled: isPending,
    },
  ];

  const area = useMemo(
    () =>
      options.find((el) => el.value === +selectedOrders[0].pickupStop.areaId),
    [selectedOrders, options],
  );

  return (
    <>
      <Box mb={2} p={2} height="55vh">
        <Typography variant="body2" color="textSecondary">
          {t('You have selected order items', {
            orders: selectedOrders.length,
          })}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t('You have selected drivers', { drivers: selectedDrivers.length })}
        </Typography>
        {!!area && (
          <Typography variant="body2" color="textSecondary">
            {t('Area is', { area: area.label })}
          </Typography>
        )}
        <Typography variant="body2" color="textSecondary">
          {t('Job is for', { date: dateddmmyyFormat(deliveryDate) })}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t('Solver is')} {solver.value}
        </Typography>
        {createWaybills && <Typography variant="body2" color="textSecondary">
          {t('Waybill will be automatically created')}
        </Typography>}
      </Box>

      <ButtonConfigRender buttonConfig={buttonConfig} leftSide={leftButton} />
    </>
  );
};
