import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: driversVehiclesActions } = createSlice({
  name: 'driversVehicles',
  initialState: {
    data: [],
    isLoading: false,
    isUpdating: false,

    vehicleOptions: [],
    vehicleOptionsIsLoading: false,
  },
  reducers: {
    getDriversVehicles: (state) => {
      state.isLoading = true;
      state.data = {};
    },
    getDriversVehiclesSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
    getVehicleOptions: (state) => {
      state.vehicleOptionsIsLoading = true;
    },
    receiveVehicleOptions: (state, { payload }) => {
      state.vehicleOptionsIsLoading = false;
      state.vehicleOptions = payload;
    },
    connectVehicleStart: (state) => {},
    connectVehicleSuccessfully: (state, { payload }) => {
      state.data = [...state.data, payload];
      state.vehicleOptions = [];
    },
    disconnectVehicleStart: (state) => {},
    disconnectVehicleSuccessfully: (state, { payload }) => {
      const index = state.data.findIndex((el) => el.id === payload);
      if (index < 0) {
        return;
      }
      const data = [...state.data];
      data.splice(index, 1);
      state.data = data;
    },
  },
});

export const driversVehiclesSelector = (state) => state.drivers.driversVehicles;
