export const clearObjectOfEmptyParams = function (obj) {
  if (!obj) {
    return {};
  }

  Object.keys(obj).forEach((key) => {
    if ((!obj[key] && obj[key] !== 0)  || (Array.isArray(obj[key]) && !obj[key].length)) {
      delete obj[key];
    }
  });

  return obj;
};
