import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CitySelector } from './CitySelector';
import {
  areasFilterOptionsActions,
  areasFilterSelector,
} from '../../../redux/slices/global/areasFilter';
import { useDispatch, useSelector } from 'react-redux';

export const CityFilter = () => {
  const { t } = useTranslation('global');
  const dispatch = useDispatch();
  const { options, selected, isLoading } = useSelector(areasFilterSelector);
  const handleAreaChange = (value) => {
    dispatch(areasFilterOptionsActions.selectValue(value));
  };

  useEffect(() => {
    if (!options.length) {
      dispatch(areasFilterOptionsActions.getAreasFilterOptions());
    }
  }, [dispatch, options]);

  return (
    <span style={{ minWidth: 150 }}>
      <CitySelector
        options={options}
        onChange={handleAreaChange}
        value={selected}
        isLoading={isLoading}
        title={t('Areas')}
      />
    </span>
  );
};
