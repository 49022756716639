import { all, put, select, takeEvery } from 'redux-saga/effects';
import {
  cargoStatusHistoryActions,
  cargoStatusHistorySelector,
} from '../../slices/cargo/cargoStatusHistory';
import { commonRequest } from '../apiSaga';

const getCargoStatusHistorySaga = function* ({ payload }) {
  const { cargoItemId, data } = yield select(cargoStatusHistorySelector);
  if (payload === cargoItemId) {
    yield put(
      cargoStatusHistoryActions.getCargoStatusHistorySuccess({
        data,
        cargoItemId,
      }),
    );
    return;
  }

  try {
    const response = yield commonRequest('getCargoStatusHistory', {
      parameters: { cargoId: payload },
    });
    yield put(
      cargoStatusHistoryActions.getCargoStatusHistorySuccess({
        data: response,
        cargoItemId: payload,
      }),
    );
  } catch (e) {}
};

export const cargoStatusHistorySaga = function* () {
  yield all([
    takeEvery(
      cargoStatusHistoryActions.getCargoStatusHistory.type,
      getCargoStatusHistorySaga,
    ),
  ]);
};
