import { object, number, string, mixed } from 'yup';
import { usePricingPolygonsListBrief } from '../../../hooks/options/usePricingPolygonsListBrief';
import { useCurrenciesOptions } from '../../../hooks/options/useCurrenciesOptions';

export const getValidationSchema = (fields) =>
  object().shape({
    ...fields.reduce((acc, el) => {
      acc[el] = number().required('Name is required');
      return acc;
    }, {}),
    startDate: mixed().required('start Date is required'),
    name: string().required(),
    endDate: mixed().required('end Date is required'),
    currency: mixed().required('currency is required'),
  });

export const step2Metadata = [
  {
    title: 'Name',
    name: 'name',
    type: 'string',
  },
  {
    title: 'Start date',
    name: 'startDate',
    type: 'date',
  },
  {
    title: 'End date',
    name: 'endDate',
    type: 'date',
  },
  {
    title: 'Pricing polygons',
    name: 'pricingPolygons',
    type: 'multiselect',
    effect: usePricingPolygonsListBrief,
  },
  {
    name: 'currency',
    type: 'select',
    title: 'Currency',
    effect: useCurrenciesOptions,
  },
];

export const pricingModelReadOnlyMetadata = {
  title: 'Pricing model',
  name: 'pricingModel',
  type: 'string',
  disabled: true,
};
