import { all, put, takeEvery } from 'redux-saga/effects';
import { driversScheduleActions } from '../../slices/drivers/driversSchedule';
import { commonRequest } from '../apiSaga';
import { userTimezoneOffset } from '@vanongo/utils';
import history from '../../../services/history';

const minutesToDate = (minutes) => {
  return new Date(minutes * 60000 + userTimezoneOffset);
};

const dateToMinutes = (receivedDate) => {
  const date = new Date(receivedDate);
  return date.getHours() * 60 + date.getMinutes();
};

const prepareData = (data, dateFunc) => {
  return Object.entries(data).reduce((total, [key, value]) => {
    total[key] = value.map(({ start, end }) => ({
      start: dateFunc(start),
      end: dateFunc(end),
    }));
    return total;
  }, {});
};

const getDriversScheduleSaga = function* ({ payload }) {
  try {
    const response = yield commonRequest('getDriverSchedule', {
      parameters: { driverId: payload },
    });

    const data = prepareData(response, minutesToDate);

    yield put(driversScheduleActions.getDriversScheduleSuccess(data));
  } catch (e) {}
};

const updateDriverScheduleSaga = function* ({ payload: { driverId, data } }) {
  try {
    yield commonRequest('setDriverSchedule', {
      parameters: { driverId: driverId },
      body: prepareData(data, dateToMinutes),
    });

    yield put(driversScheduleActions.updateDriverScheduleSuccess(data));
    history.push(`/drivers/${driverId}`);
  } catch (e) {
    yield put(driversScheduleActions.updateDriverScheduleReject());
  }
};

export const driversScheduleSaga = function* () {
  yield all([
    takeEvery(
      driversScheduleActions.getDriversSchedule.type,
      getDriversScheduleSaga,
    ),
    takeEvery(
      driversScheduleActions.updateDriverSchedule.type,
      updateDriverScheduleSaga,
    ),
  ]);
};
