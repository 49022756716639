import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: servicesOptionActions } = createSlice({
  name: 'servicesOption',
  initialState: {
    options: [],
    isLoading: false,
  },
  reducers: {
    getServicesOption: (state) => {
      state.isLoading = true;
    },
    getServicesOptionSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.options = payload;
    },
  },
});

export const servicesOptionSelector = (state) =>
  state.services.b2cServicesOption;
