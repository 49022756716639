import React, { Suspense } from 'react';

import { UsePopupConnect } from './UsePopupConnect';
import { popupMap } from './popupMap';
import { makeStyles } from '@material-ui/core';
import { LoadingScreen } from '@vanongo/components';

const stopPropagationFunction = (e) => {
  e.stopPropagation();
};

const useStyles = makeStyles((theme) => ({
  popup: {
    position: 'fixed',
    top: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(38,38,38, 0.8)',
    zIndex: 2,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  container: {
    position: 'relative',
    background: '#fff',
    boxSizing: 'border-box',
    maxWidth: 'calc(100% - 20px)',
    margin: '20px 10px',
  },
}));

export const PopupRenderer = () => {
  const classes = useStyles();
  const { openedPopups, closePopup } = UsePopupConnect();
  if (!openedPopups.length) {
    return null;
  }

  return openedPopups.map((popupProps, index) => {
    const { componentKey, hideOnClickOutside = true } = popupProps;

    if (!popupMap[componentKey]) {
      return null;
    }

    const Component = popupMap[componentKey];

    return (
      <div
        className={classes.popup}
        key={componentKey}
        style={{ zIndex: 1250 + index }}
        onClick={hideOnClickOutside ? closePopup : undefined}
      >
        <div className={classes.container} onClick={stopPropagationFunction}>
          <Suspense fallback={<LoadingScreen />}>
            <Component closePopup={closePopup} {...popupProps} />
          </Suspense>
        </div>
      </div>
    );
  });
};

export default PopupRenderer;
