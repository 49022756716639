const mapValue = (option, settings) => {
  if (option.key) {
    return option.key;
  }

  if (option.value != null) {
    return option.value;
  }

  if (settings.valueParam) {
    return option[settings.valueParam];
  }

  return option;
};

const mapLabel = (option, settings) => {
  if (option.label) {
    return option.label;
  }

  if (settings.labelParam) {
    return option[settings.labelParam];
  }
  return option;
};

export const transformOptions = ({ options, t, settings = {} }) => {
  return options.map((option) => {
    const value = mapValue(option, settings);
    const label = mapLabel(option, settings);

    const additionalInfo = typeof option === 'object' ? option : {};
    return { ...additionalInfo, value, label: t ? t(label) : label };
  });
};
