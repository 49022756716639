import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';

export const DetailsRow = (
  { classes, data },
  { title, field, renderLabel, renderValue },
) => {
  return (
    <TableRow key={field}>
      <TableCell className={classes.label}>
        {renderLabel ? renderLabel(data, field) : title}
      </TableCell>
      <TableCell className={classes.value}>
        {renderValue ? renderValue(data, field) : data[field]}
      </TableCell>
    </TableRow>
  );
};
