import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: groupsListActions } = createSlice({
  name: 'groupsList',
  initialState: {
    collection: [],
    page: 0,
    totalCount: 0,
    pageSize: 10,
    isLoading: false,

    isCreating: false,
    errors: null,

    isEditing: false,
    editErrors: null,
  },
  reducers: {
    getGroupsList: (state) => {
      state.isLoading = true;
    },
    getGroupsListSuccess: (
      state,
      { payload: { data, page, totalCount, pageSize } },
    ) => {
      state.isLoading = false;
      state.collection = data;
      state.page = page - 1;
      state.totalCount = totalCount;
      state.pageSize = pageSize;
    },

    updateGroup: (state) => {
      state.isEditing = true;
    },
    updateGroupSuccess: (state, { payload: item }) => {
      state.isEditing = false;
      const index = state.collection.findIndex((el) => el.id === item.id);

      state.collection.splice(index, 1, item);
    },

    updateGroupFailed: (state, { payload: errors }) => {
      state.isEditing = false;
    },

    create: (state) => {
      state.isCreating = true;
      state.errors = null;
    },
    createSuccess: (state) => {
      state.isCreating = false;
      state.errors = null;
    },
    createFailed: (state, { payload: errors }) => {
      state.isCreating = false;
      state.errors = errors;
    },

    delete: (state) => {
      state.isLoading = true;
    },
    deleteSuccess: () => {},
  },
});

export const groupsListSelector = (state) => state.groups.groupsList;
