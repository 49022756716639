import React from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';
import { NAMESPACES } from '../../../constants/namespaces';
import { makeStyles } from '@material-ui/core';
import history from '../../../services/history';
import clsx from 'clsx';
import { CustomIcon } from '@vanongo/components/CustomIcon/CustomIcon';
import { SubMenuItem } from './SubMenuItem';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: '#546E7A',
  },
  title: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '14px',
    color: '#546E7A',
    marginTop: 2,
  },
  item: {
    padding: '0 8px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    position: 'relative',
    marginTop: 0,
    marginBottom: 30,

    '&:last-child': {
      marginBottom: 0,
    },

    '&:hover': {
      '& > div': {
        display: 'flex',
      },
    },
  },
  active: {
    color: theme.palette.secondary.main,
    '& $title': {
      fontWeight: 'bold',
      color: theme.palette.secondary.main,
    },
    '& $icon': {
      color: theme.palette.secondary.main,
    },
  },
  subMenu: {
    padding: '8px 34px',
    backgroundColor: '#1791FF',
    position: 'absolute',
    left: 97,
    height: 84,
    color: '#fff',
    fontSize: 12,
    display: 'none',
    top: '50%',
    transform: 'translate(0, -50%)',

    'subMenu:hover': {
      display: 'flex',
    },
  },
  subMenuArrow: {
    transform: 'rotate(-45deg) translate(50%, -50%);',
    width: 11,
    height: 11,
    backgroundColor: '#1791FF',
    position: 'absolute',
    left: -5,
    top: 35,
  },
  '@media (max-height: 840px)': {
    item: {
      marginBottom: 10,
      padding: '0 2px 0',
    },
    subMenu: {
      padding: '4px 17px',
      height: 70,
    },
  },
}));

export const MenuItem = ({
  href,
  matchHref,
  icon: Icon,
  iconHeight = 60,
  iconWidth = 60,
  customIcon,
  title,
  subMenu,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation(NAMESPACES.MENU);
  const active = !!matchPath(location.pathname, {
    path: matchHref || href,
    exact: false,
  });

  const handleClick = () => {
    history.push(href);
  };

  return (
    <div
      key={title}
      className={clsx(classes.item, {
        [classes.active]: active,
      })}
      onClick={handleClick}
    >
      {customIcon && (
        <CustomIcon
          glyph={customIcon}
          className={classes.icon}
          width={iconWidth}
          height={iconHeight}
        />
      )}
      {Icon && <Icon className={classes.icon} size="24" />}
      <span className={classes.title}>{t(title)}</span>

      {subMenu && (
        <div className={classes.subMenu}>
          <div className={classes.subMenuArrow} />
          {subMenu.map(SubMenuItem)}
        </div>
      )}
    </div>
  );
};
