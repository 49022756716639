import React, { useState, useEffect } from 'react';
import { defaultValueMapper, FormMapper } from '../Form/FormMapper';
import { Box } from '@material-ui/core';
import { get } from '@vanongo/utils';

export const FilterOnTheFlyItem = ({ item, onChange, classes, values }) => {
  const [value, onChangeValue] = useState(
    values[item.name] || defaultValueMapper[item.type],
  );

  const Component = FormMapper[item.type];

  // Workaround to change filters on browser back
  useEffect(() => {
    const nextValue = values[item.name] || defaultValueMapper[item.type];
    onChangeValue(nextValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleChange = (e) => {
    const value = get(e, 'target.value', e);
    onChangeValue(value);
    onChange(value, item.name);
  };

  return (
    <Box className={classes.item} key={item.name} style={{ width: item.width }}>
      <Component value={value} onChange={handleChange} {...item} />
    </Box>
  );
};
