import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Popover,
  SvgIcon,
  Typography,
  Divider,
} from '@material-ui/core';
import { Settings, Check, X as NotChecked } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../../../webAdmin/src/constants/namespaces';

export const TableSettings = ({
  classes,
  userColumns,
  setVisibilityColumn,
  resetColumns,
}) => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);
  const { t } = useTranslation(NAMESPACES.UI_KIT);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={ref}
        onClick={handleOpen}
        className={classes.refresh}
        color="secondary"
      >
        <SvgIcon fontSize="small">
          <Settings />
        </SvgIcon>
      </IconButton>

      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <>
          <Box pb={1} className="df aic jcsb">
            <Typography variant="h4" color="textPrimary">
              {t('Table elements')}
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={resetColumns}
            >
              Reset
            </Button>
          </Box>
          <Divider />

          {userColumns.map((el) => (
            <Box
              key={el.field}
              mt={2}
              className="df aic"
              onClick={() => {
                setVisibilityColumn(el.field, !el.isHidden);
              }}
              style={{
                cursor: 'pointer',
              }}
            >
              <SvgIcon
                fontSize="small"
                style={{ color: '#ffbe36', marginRight: 4 }}
              >
                {!el.isHidden ? <Check /> : <NotChecked />}
              </SvgIcon>
              {el.title}
            </Box>
          ))}
        </>
      </Popover>
    </>
  );
};
