import React from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapContainer as Map } from 'react-leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { MapCenterZoom } from './MapCenterZoom';
import { MapCenter } from './MapCenter';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

export const MapContainer = React.forwardRef(
  ({ listenOnlyCenter, center, zoom, children, ...props }, ref) => {
    const handleCreate = (map) => {
      if (ref) {
        ref.current = map;
      }

      if (props?.points && props?.points.length > 0) {
        const customLatLngBounds = L.latLngBounds(props.points);
        map.fitBounds(customLatLngBounds);
      }

      map.setView(center, zoom);
    };

    return (
      <Map {...props} whenCreated={handleCreate}>
        {listenOnlyCenter ? (
          <MapCenter center={center} />
        ) : (
          <MapCenterZoom center={center} zoom={zoom} />
        )}
        {children}
      </Map>
    );
  },
);
