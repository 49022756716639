import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: groupsAttributesListActions } = createSlice({
  name: 'groupsAttributes',
  initialState: {
    attributes: [],
    page: 0,
    totalCount: 0,
    pageSize: 10,
    isLoading: false,

    isCreating: false,
    errors: null,

    isEditing: false,
    editErrors: null,
  },
  reducers: {
    getGroupsAttributesList: (state) => {
      state.isLoading = true;
    },
    getGroupsAttributesListSuccess: (
      state,
      { payload: { data, page, totalCount, pageSize } },
    ) => {
      state.isLoading = false;
      state.attributes = data;
      state.page = page - 1;
      state.totalCount = totalCount;
      state.pageSize = pageSize;
    },
    create: (state) => {
      state.isCreating = true;
      state.errors = null;
    },
    createSuccess: (state) => {
      state.isCreating = false;
      state.errors = null;
    },
    createFailed: (state, { payload: errors }) => {
      state.isCreating = false;
      state.errors = errors;
    },
    updateAttributes: (state) => {
      state.isEditing = true;
      state.editErrors = null;
    },
    updateAttributesSuccess: (state) => {
      state.isEditing = false;
      state.editErrors = null;
    },
    updateAttributesFailed: (state, { payload: errors }) => {
      state.isEditing = false;
      state.editErrors = errors;
    },
    deleteAttributes: (state) => {
      state.isLoading = true;
    },
    deleteAttributesSuccess: () => {},
  },
});

export const groupsAttributesListSelector = (state) => state.groups.attributes;
