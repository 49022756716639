import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: cargoStatusHistoryActions } = createSlice({
  name: 'cargoStatusHistory',
  initialState: {
    data: [],
    cargoItemId: null,
    isLoading: false,
  },
  reducers: {
    getCargoStatusHistory: (state) => {
      state.isLoading = true;
    },
    getCargoStatusHistorySuccess: (
      state,
      { payload: { data, cargoItemId } },
    ) => {
      state.isLoading = false;
      state.data = data;
      state.cargoItemId = cargoItemId;
    },
  },
});

export const cargoStatusHistorySelector = (state) =>
  state.cargo.cargoStatusHistory;
