import { TextField } from '@material-ui/core';
import React from 'react';

export const Input = ({
  title,
  error,
  value,
  onChange,
  disabled,
  multiline,
  rows,
  width,
  classes,
  size,
  helperText,
  type,
}) => {
  const textHelper = error && error.message ? error.message : helperText;
  return (
    <TextField
      value={value}
      classes={classes}
      size={size}
      onChange={onChange}
      error={!!error}
      label={title}
      disabled={disabled}
      multiline={multiline}
      rows={rows}
      type={type}
      fullWidth
      inputProps={{
        style: {
          maxWidth: width,
        },
      }}
      variant="outlined"
      helperText={textHelper}
    />
  );
};
