import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { CustomInput } from './CustomInput';

export const Time = ({ title, value, onChange, error }) => {
  const handleChange = (value, e) => {
    if (!e || !e.target) {
      onChange(value);
    } else if (e.target.value.length === 5) {
      onChange(value);
    }
  };

  return (
    <ReactDatePicker
      selected={value}
      onChange={handleChange}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption="Time"
      title={title}
      dateFormat="HH:mm"
      customInput={<CustomInput addOnChange error={error} />}
    />
  );
};
