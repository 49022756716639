import React from 'react';
import GoogleLayer from 'react-leaflet-google-layer';
import { ROADMAP } from '../../../webAdmin/src/constants/googleMapTypes';
import { Popup } from 'react-leaflet';
import { Box, makeStyles } from '@material-ui/core';
import { GOOGLE_MAP_KEY } from '../../../webAdmin/src/constants/app';
import { MapContainer } from '../../Map/MapContainer';
import { DraggableMarker } from '../../Map/DragableMarker';
import { useGetCenterCoordinates } from './useGetCenterCoordinates';

const useStyles = makeStyles(() => ({
  map: {
    marginTop: 5,
    height: '500px',
    width: '100%',
  },
  unavailable: {
    marginTop: 5,
    height: '500px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const Map = ({ value, onChange, title }) => {
  const classes = useStyles();
  const { lng, lat } = value;

  const position = [+lat, +lng];
  const centerPosition = [position[0] || 0, position[1] || 0];
  const { center } = useGetCenterCoordinates(centerPosition);

  if (isNaN(position[0]) || isNaN(position[1])) {
    return (
      <Box className={classes.unavailable}>
        Map is unavailable for these coordinates
      </Box>
    );
  }

  const handleChange = (position) => {
    onChange(position);
  };

  return (
    <MapContainer
      className={classes.map}
      center={center}
      zoom={16}
      listenOnlyCenter
    >
      <GoogleLayer apiKey={GOOGLE_MAP_KEY} type={ROADMAP} />

      <DraggableMarker position={position} onChange={handleChange} draggable>
        <Popup>{title}</Popup>
      </DraggableMarker>
    </MapContainer>
  );
};
