import React, { useState } from 'react';
import { Box } from '@material-ui/core';

import { ConfirmPopup } from '../ConfirmPopup/ConfirmPopup';
import { Input } from '@vanongo/components/formComponents/Input/Input';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../constants/namespaces';

export const ConfirmWithCommentPopup = ({ onConfirm, ...props }) => {
  const { t } = useTranslation(NAMESPACES.GLOBAL);
  const [comment, setComment] = useState('');

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = () => {
    onConfirm(comment);
  };

  const render = (
    <Box mt={3}>
      <Input
        multiline
        title={t('Comment')}
        rows={4}
        value={comment}
        onChange={handleChange}
      />
    </Box>
  );
  return (
    <ConfirmPopup
      {...props}
      render={render}
      disabled={!comment}
      onConfirm={handleSubmit}
    />
  );
};
