import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: cargoListActions } = createSlice({
  name: 'cargoList',
  initialState: {
    cargo: [],
    page: 0,
    totalCount: 0,
    pageSize: 10,
    isLoading: false,
  },
  reducers: {
    getCargoList: (state) => {
      state.isLoading = true;
    },
    getCargoListSuccess: (
      state,
      { payload: { data, page, totalCount, pageSize } },
    ) => {
      state.isLoading = false;
      state.cargo = data;
      state.page = page - 1;
      state.totalCount = totalCount;
      state.pageSize = pageSize;
    },
    changeStatus: (state) => {},
    changeStatusSuccess: (state, { payload: { cargoId, status } }) => {
      const item = state.cargo.find((el) => el.cargoId === cargoId);
      if (item) {
        item.status = status;
      }
    },
  },
});

export const cargoListSelector = (state) => state.cargo.cargoList;
