export const SSManager = {
  get(key, def) {
    try {
      return JSON.parse(sessionStorage.getItem(key)) || def;
    } catch (e) {
      console.error(e);
      return def;
    }
  },
  save(key, data) {
    try {
      sessionStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.error(e);
    }
  },
  delete(key) {
    sessionStorage.removeItem(key);
  },
};
