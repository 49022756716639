import { reduxHelper } from '@vanongo/utils';
import { api } from '../../services/api';

const mapFunc = (response) => {
  return response.map((el) => ({
    value: el.id,
    label: el.attributeType,
  }));
};

export const {
  actions: groupAttributesOptionsActions,
  reducer: groupAttributesOptions,
  selector: groupAttributesOptionsSelector,
  saga: groupAttributesOptionsSaga,
} = reduxHelper({
  actionName: 'getOptions',
  name: 'groupAttributesOptions',
  mapFunc,
  promise: () =>
    api.get(`${process.env.REACT_APP_ADMIN_URL}attributes/list-brief`),
  fetchOnce: false,
});
