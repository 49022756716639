import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: driversInfoActions } = createSlice({
  name: 'driversDetails',
  initialState: {
    data: {},
    isLoading: false,
    isUpdating: false,
    isError: false,
  },
  reducers: {
    getDriversInfo: (state) => {
      state.isLoading = true;
    },
    getDriversInfoSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
    getDriversInfoPutError: (state) => {
      state.isError = true;
    },
    updateDriversInfo: (state) => {
      state.isUpdating = true;
    },
    updateDriversInfoSuccess: (state, { payload }) => {
      state.isUpdating = false;
      state.data = payload;
    },
    updateDriversInfoReject: (state) => {
      state.isUpdating = false;
    },
    removeDriver: (state) => {
      state.isLoading = true;
    },
  },
});

export const driversInfoSelector = (state) => state.drivers.driversInfo;
