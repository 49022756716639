import React from 'react';
import logoWhite from 'packages/webAdmin/src/assets/images/logo-white.png';
import logoBlue from 'packages/webAdmin/src/assets/images/logo-blue.png';
import logoBlack from 'packages/webAdmin/src/assets/images/logo-black.png';

export const WHITE_LOGO = 'blue';
export const BLUE_LOGO = 'white';
export const BLACK_LOGO = 'black';

const logoMap = {
  [WHITE_LOGO]: logoWhite,
  [BLUE_LOGO]: logoBlue,
  [BLACK_LOGO]: logoBlack,
};

const Logo = ({ type = BLUE_LOGO, ...props }) => {
  return <img alt="Logo" src={logoMap[type]} {...props} />;
};

export default Logo;
