export const decodeJWTToken = (token) => {
  const [header, payloadBase64, signature] = token.split('.');
  const { exp, iat } = JSON.parse(window.atob(payloadBase64));
  return {
    issued: iat * 1000,
    expiration: exp * 1000,
    header,
    signature,
  };
};
