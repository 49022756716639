import React, { createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../../constants/namespaces';
import { useTransformMetadata } from '../../../hooks/useTransformMetadata';
import { Box } from '@material-ui/core';
import { Form } from '@vanongo/components/Form';
import { ButtonConfigRender } from '@vanongo/components/Buttons/ButtonConfigRender';

export const StepForm = ({
  handleBack,
  validationSchema,
  closePopup,
  metadata,
  onSubmit,
  defaultValues,
  hasBackButton,
}) => {
  const formRef = createRef();
  const { t } = useTranslation(NAMESPACES.PARTNERS);
  const { t: tGlobal } = useTranslation(NAMESPACES.GLOBAL);

  const leftButtonConfig = [{ text: tGlobal('Close'), onClick: closePopup }];

  const getFormsDataAndHandleNext = () => {
    formRef.current.handleSubmit(onSubmit)();
  };

  const buttonConfig = [
    {
      text: tGlobal('Next'),
      color: 'secondary',
      variant: 'contained',
      onClick: getFormsDataAndHandleNext,
    },
  ];
  if (hasBackButton) {
    buttonConfig.unshift({ text: tGlobal('Back'), onClick: handleBack });
  }

  const translatedMetadata = useTransformMetadata({
    metadata: metadata,
    t,
  });

  return (
    <>
      <Box mb={2} p={2} height="55vh" overflow="auto">
        <Form
          ref={formRef}
          metadata={translatedMetadata}
          validationSchema={validationSchema}
          buttonConfig={[]}
          defaultValues={defaultValues}
          columnSize={1}
        />
      </Box>
      <ButtonConfigRender
        buttonConfig={buttonConfig}
        leftSide={leftButtonConfig}
      />
    </>
  );
};
