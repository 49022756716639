import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: vouchersListActions } = createSlice({
  name: 'voucherList',
  initialState: {
    vouchers: [],
    page: 0,
    pageSize: 10,
    totalCount: 0,
    isLoading: false,
    isCreating: false,

    errors: {},
  },
  reducers: {
    getVouchersList: (state) => {
      state.isLoading = true;
    },
    getVouchersListSuccess: (
      state,
      { payload: { data, page, totalCount, pageSize } },
    ) => {
      state.isLoading = false;
      state.vouchers = data;
      state.page = page - 1;
      state.totalCount = totalCount;
      state.pageSize = pageSize;
    },
    createVouchers: (state) => {
      state.isCreating = true;
      state.errors = {};
    },
    createVouchersSuccess: (state, { payload: { data } }) => {
      state.isCreating = false;
      state.errors = {};
    },
    createVouchersFailed: (state, { payload: errors }) => {
      state.isCreating = false;
      state.errors = errors;
    },
    changeStatus: (state) => {},
    changeStatusSuccess: (state, { payload: { id, data } }) => {
      const index = state.vouchers.findIndex((item) => item.id === id);
      state.vouchers[index].isActive = data.isActive;
    },
  },
});

export const vouchersListSelector = (state) => state.vouchers;
