import React, {useState} from 'react';
import {makeStyles, Box} from '@material-ui/core';

import PopupLayout from '../PopupLayout/PopupLayout';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../constants/namespaces';

import {Input} from "@vanongo/components";
import {File} from "react-feather";
import {useDispatch} from "react-redux";
import {driversUploadFilesActions} from "../../redux/slices/drivers/driversFiles";

const useStyles = makeStyles(() => ({
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  textContainer: {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  uploadFile: {
    maxWidth: 400,
    marginTop: 20,
    maxHeight: 500,
  },
  mt: {
    marginTop: 10
  }
}));

const mimeTypeSupported = (mime_type) => {
  return (mime_type || '').trim().indexOf('image/') === 0;
};

export const UploadPopup = ({
  titlePopup,
  text,
  buttonText,
  url,
  closePopup,
  file,
  mime_type,
  driverId,
  fileId,
  edit,
}) => {
  const { t } = useTranslation(NAMESPACES.GLOBAL);
  const classes = useStyles();

  const dispatch = useDispatch();
  const [inputText, changeInputText] = useState(text || '');
  const [isDirty, setDirty] = useState(false)

  const img = {};

  if (file) {
    img.src = URL.createObjectURL(file);
  }

  if (edit) {
    // eslint-disable-next-line
    img.name = url.match('\/([^/]+)$')[1];
  }

  const onSubmitUpload = () => {
    dispatch(
      driversUploadFilesActions.uploadFiles({
        title: inputText,
        file: file,
        driverId: driverId,
      }),
    );
  };

  const onSubmitEdit = () => {
    dispatch(
      driversUploadFilesActions.updateUploadFiles({
        title: inputText,
        driverId: driverId,
        fileId: fileId
      }),
    );
  };

  const confirmButtons = [
    { text: t('Cancel'), onClick: closePopup },
    {
      color: 'secondary',
      type: 'submit',
      disabled: !inputText.length,
      variant: 'contained',
      text: buttonText || t('Upload'),
      onClick: edit ? onSubmitEdit : onSubmitUpload,
    },
  ];

  const handleChangeTitle = (e) => {
    changeInputText(e.target.value);
    setDirty(true);
  };

  return (
    <PopupLayout
      width={500}
      title={titlePopup}
      buttonConfig={confirmButtons}
    >
      <Box mt={2} className={classes.textContainer}>
        <Input
          title="Title"
          value={inputText}
          onChange={handleChangeTitle}
          error={isDirty && !inputText.length}
          helperText={isDirty && "This field is required"}
        />
        <Box mt={2}>
          <Input
            title="Name"
            value={edit ? img.name : file?.name}
            disabled={true}
          />
        </Box>
        {mimeTypeSupported(edit ? mime_type : file?.type) ? (
          <img
            src={edit ? url : img.src}
            alt="img"
            className={classes.uploadFile}
          />
        ) : (
          <Box
            className={classes.iconContainer}
            mt={2}
          >
            <File
              style={{
                width: 50,
                height: 50,
                color: "gray"
              }}
            />
          </Box>
          )}
      </Box>
    </PopupLayout>
  );
};
