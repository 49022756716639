import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { lazyImportIcon } from './importIcons';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  icon: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
}));

const propTypes = {
  /** Icon name from Icons set. */
  glyph: PropTypes.string.isRequired,
  /** Icon width. */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Icon height. */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Default icon color. */
  fill: PropTypes.string,
  /** Additional class modifier for icon. */
  className: PropTypes.string,
  /** Fallback icon name * */
  fallback: PropTypes.string,
  /** OnClick action */
  onClick: PropTypes.func,
};

const defaultProps = {
  width: 24,
  height: 24,
  fill: 'currentColor',
  className: undefined,
  fallback: undefined,
  onClick: Function.prototype,
};

export const cacheListGlyph = new Map();

export const CustomIcon = (props) => {
  const classes = useStyles();
  const { glyph, width, height, fill, className, fallback, onClick } = props;

  const cachedGlyph = cacheListGlyph.get(glyph);
  const [href, setHref] = useState(cachedGlyph);

  useEffect(
    () => {
      if (!cachedGlyph) {
        if (lazyImportIcon(glyph)) {
          cacheListGlyph.set(glyph, glyph);
          setHref(glyph);
        } else if (fallback) {
          if (!cacheListGlyph.get(fallback)) {
            lazyImportIcon(fallback);
            cacheListGlyph.set(fallback, fallback);
          }
          cacheListGlyph.set(glyph, fallback);
          setHref(fallback);
        }
      } else if (cachedGlyph !== href) {
        setHref(cachedGlyph);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [glyph],
  );

  return href ? (
    <svg
      className={clsx(className, classes.icon)}
      width={width}
      fill={fill}
      height={height}
      onClick={onClick}
    >
      <use xlinkHref={`#${href}`} />
    </svg>
  ) : null;
};

CustomIcon.propTypes = propTypes;
CustomIcon.defaultProps = defaultProps;
