import { useSnackbar } from 'notistack';

export let toast = null;
export let closeToast = null;

export const SnackbarSingleton = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  toast = enqueueSnackbar;
  closeToast = closeSnackbar;

  return children;
};
