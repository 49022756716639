import { reduxHelper } from '@vanongo/utils';
import { api } from '../../services/api';

export const {
  actions: pricingPolygonsListBriefActions,
  reducer: pricingPolygonsListBrief,
  selector: pricingPolygonsListBriefSelector,
  saga: pricingPolygonsListBriefSaga,
} = reduxHelper({
  actionName: 'get',
  name: 'pricingPolygonsListBrief',
  promise: (organization) =>
    api.get(
      `${process.env.REACT_APP_ADMIN_URL}pricing-polygons/list-brief?organization=${organization}`,
    ),
  mapFunc: (arr) =>
    arr.map((el) => ({
      label: el.name,
      value: el.id,
    })),
  fetchOnce: false,
});
