import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  partnersListBriefActions,
  partnersListBriefSelector,
} from '../../redux/getters/partnersListBrief';

export const usePartnersListOptions = () => {
  const dispatch = useDispatch();
  const { data: options, isLoading } = useSelector(partnersListBriefSelector);

  useEffect(() => {
    dispatch(partnersListBriefActions.getList());
  }, [dispatch]);

  return {
    options,
    isLoading,
  };
};
