import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export const useListActions = ({
  action,
  queryString,
  pageSize,
  page,
  params,
  hasSorting,
}) => {
  const [sorting, changeSorting] = useState(hasSorting && {});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(action({ queryString, params, pageSize, page: 1, sorting }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString, params]);

  const onChangePage = (newPage) => {
    if (newPage !== page) {
      dispatch(
        action({ page: newPage + 1, queryString, params, pageSize, sorting }),
      );
    }
  };

  const onChangeRowsPerPage = (pageSize) =>
    dispatch(action({ pageSize, queryString, params, page: 1, sorting }));

  const onRefresh = () => {
    dispatch(
      action({
        page: page + 1,
        queryString,
        params,
        pageSize,
        sorting,
        isReload: true,
      }),
    );
  };

  const onChangeSorting = (value, direction) => {
    const newSorting = {
      value: value,
      direction: direction === 'asc' ? 'desc' : 'asc',
    };

    dispatch(
      action({
        page: page + 1,
        queryString,
        params,
        pageSize,
        sorting: newSorting,
      }),
    );
    changeSorting(newSorting);
  };

  return {
    onChangePage,
    onChangeRowsPerPage,
    onRefresh,
    onChangeSorting,
    sorting,
  };
};
