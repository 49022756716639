import React from 'react';
import { Box, Button } from '@material-ui/core';

export const ButtonConfigRender = ({
  buttonConfig,
  disabled,
  leftSide = [],
}) => {
  return (
    <Box p={2} className="df jcsb aic">
      <Box>
        {leftSide.map((button) => (
          <Button
            key={button.text}
            color={button.color}
            type={button.type}
            variant={button.variant}
            onClick={button.onClick}
            style={{ marginLeft: 10 }}
            disabled={button.disabled || (button.type === 'submit' && disabled)}
          >
            {button.text}
          </Button>
        ))}
      </Box>
      <Box>
        {buttonConfig.map((button) => (
          <Button
            key={button.text}
            color={button.color}
            type={button.type}
            variant={button.variant}
            onClick={button.onClick}
            style={{ marginLeft: 10 }}
            disabled={button.disabled || (button.type === 'submit' && disabled)}
          >
            {button.text}
          </Button>
        ))}
      </Box>
    </Box>
  );
};
