import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: driversScheduleActions } = createSlice({
  name: 'driversSchedule',
  initialState: {
    data: {},
    isLoading: true,
  },
  reducers: {
    getDriversSchedule: (state) => {
      state.isLoading = true;
      state.data = {};
    },
    getDriversScheduleSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
    updateDriverSchedule: (state) => {
      state.isLoading = true;
    },
    updateDriverScheduleSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
    updateDriverScheduleReject: (state) => {
      state.isLoading = false;
    },
  },
});

export const driversScheduleSelector = (state) => state.drivers.driversSchedule;
