import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: servicesListActions } = createSlice({
  name: 'servicesList',
  initialState: {
    collection: [],
    page: 0,
    totalCount: 0,
    pageSize: 10,
    isLoading: false,

    isCreating: false,
    creatingErrors: null,
  },
  reducers: {
    getList: (state) => {
      state.isLoading = true;
    },
    getListSuccess: (
      state,
      { payload: { data, page, totalCount, pageSize } },
    ) => {
      state.isLoading = false;
      state.collection = data;
      state.page = page - 1;
      state.totalCount = totalCount;
      state.pageSize = pageSize;
    },
    create: (state) => {
      state.isCreating = true;
      state.creatingErrors = null;
    },
    createSuccess: (state) => {
      state.isCreating = false;
      state.creatingErrors = null;
    },
    createFailed: (state, { payload: errors }) => {
      state.isCreating = false;
      state.creatingErrors = errors;
    },
    update: (state) => {
      state.isUpdating = true;
      state.updatingErrors = null;
    },
    updateSuccess: (state, { payload: data }) => {
      state.isUpdating = false;
      state.updatingErrors = null;

      const index = state.collection.findIndex((el) => el.id === data.id);
      if (index > -1) {
        state.collection.splice(index, 1, data);
      }
    },
    updateFailed: (state, { payload: errors }) => {
      state.isUpdating = false;
      state.updatingErrors = null;
    },
    delete: () => {},
    deleteSuccess: () => {},
  },
});

export const servicesListSelector = (state) => state.services.servicesList;
