import { transformOptions } from '../formComponents/Select/transformOptions';
const putValue = (prevValue, value) =>
  `${prevValue ? prevValue + '; ' : ''}${value}`;

  const indFixUUID = (metadata, arr, type) => arr.findIndex((item) => metadata[item]?.type === type);

// TODO WRITE UNIT FOR THIS HARCODE
export const defaultFilterValuesMapper = (values, metadata, arrIndex, isSelectUUID) => {
  return Object.entries(values).reduce((total, [key, value]) => {
    const meta = metadata.find((el) => el.name === key);

    if (!meta) {
      return total;
    }

    if (meta.type === 'number') {
      total = putValue(total, `${meta.title}: ${value}`);
      return total;
    }

    if (meta.type === 'multiselect') {
      if (meta.optionsEffect) {
        const indexUUID = indFixUUID(metadata, arrIndex, "multiselect");
        let arrayStr = metadata[arrIndex[indexUUID]]?.optionsEffect?.options?.filter((obj) => {
          return value.indexOf(obj.value) >= 0
        } );

        total = putValue(
          total,
          arrayStr.reduce((string, el) => {
            string = putValue(string, el.label);
            return string;
          }, ''),
        );

        return total;
      }

      if (meta.effect) {
        total = putValue(
          total,
          value.reduce((string, el) => {
            string = putValue(string, el);
            return string;
          }, ''),
        );
        return total;
      }

      const transformedOptions = transformOptions({
        options: meta.options,
      });
      total = putValue(
        total,
        value.reduce((string, el) => {
          const option = transformedOptions.find(
            (option) => option.value === el,
          );
          if (!option) {
            return string;
          }
          string = putValue(string, option.label);
          return string;
        }, ''),
      );
      return total;
    }

    if (meta.type === 'select') {
      let indexItemId;
      let valueText;
      const indexUUID = indFixUUID(metadata, arrIndex, "select");

      if(arrIndex && metadata[arrIndex[indexUUID]]?.optionsEffect?.options.length ) {
        indexItemId = metadata[arrIndex[indexUUID]].optionsEffect.options?.findIndex((i) => i.value === values[key]);
      }

      valueText = isSelectUUID && indexItemId !== -1 ? metadata[arrIndex[0]].optionsEffect.options[indexItemId]?.label : value

      return putValue(total, valueText);
    }

    if (Array.isArray(value)) {
      const childString = value.reduce((string, child) => {
        if (typeof child === 'object') {
          return string;
        }
        string = putValue(string, child);

        return string;
      }, '');

      total = putValue(total, childString);
    }

    if (typeof value !== 'object') {
      total = putValue(total, value);
    }

    return total;
  }, '');
};
