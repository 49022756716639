import { reduxHelper } from '@vanongo/utils';
import { api } from '../../services/api';

export const {
  actions: groupAttributesToValueOptionsActions,
  reducer: groupAttributesToValueOptions,
  selector: groupAttributesToValueOptionsSelector,
  saga: groupAttributesToValueOptionsSaga,
} = reduxHelper({
  actionName: 'getOptions',
  name: 'groupAttributesToValueOptions',
  promise: () =>
    api.get(`${process.env.REACT_APP_ADMIN_URL}attributes/list-brief-full`),
  fetchOnce: false,
});
