import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import PopupLayout from '../PopupLayout/PopupLayout';
import { Select } from '@vanongo/components/formComponents/Select/Select';
import { useVehiclesOptionsByLicensePlate } from '../../hooks/options/useVehiclesOptionsByLicensePlate';
import { driversVehiclesActions } from '../../redux/slices/drivers/driversVehicles';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../constants/namespaces';

export const ConnectVehicleToDriver = ({ closePopup, driverId, areaId }) => {
  const { t } = useTranslation(NAMESPACES.DRIVERS);
  const { t: tGlobal } = useTranslation(NAMESPACES.GLOBAL);
  const [vehicle, onChange] = useState(null);
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(
      driversVehiclesActions.connectVehicleStart({
        vehicle: vehicle.item,
        driverId,
      }),
    );
    closePopup();
  };

  const confirmButtons = [
    { text: tGlobal('Cancel'), onClick: closePopup },
    {
      color: 'secondary',
      type: 'submit',
      variant: 'contained',
      text: tGlobal('Save'),
      onClick: onSubmit,
      disabled: !vehicle,
    },
  ];

  const handleChange = (vehicle, option) => onChange(option);

  return (
    <PopupLayout
      width={600}
      title={t('Connect vehicle')}
      buttonConfig={confirmButtons}
    >
      <Box mb={2}>
        <Typography gutterBottom variant="body2" color="textPrimary">
          {t('By license plate')}
        </Typography>
      </Box>
      <Select
        value={vehicle && vehicle.value}
        onChange={handleChange}
        effect={useVehiclesOptionsByLicensePlate.bind(null, areaId)}
        title={t('License plate')}
      />
    </PopupLayout>
  );
};
