export const sizeConfig = {
  medium: {
    headerHeight: 53,
    rowHeight: 87,
    ellipsisHeight: 3,
    footerHeight: 48,
  },
  large: {
    headerHeight: 53,
    rowHeight: 120,
    ellipsisHeight: 5,
    footerHeight: 48,
  },
  small: {
    headerHeight: 40,
    rowHeight: 53,
    footerHeight: 30,
  },
  oneRowSmall: {
    headerHeight: 40,
    rowHeight: 35,
    footerHeight: 30,
  },
};
