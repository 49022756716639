import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: deliveryDynamicsActions } = createSlice({
  name: 'deliveryDynamics',
  initialState: {
    data: [],
    isLoading: true,
  },
  reducers: {
    getDeliveryDynamics: (state) => {
      state.isLoading = true;
    },
    getDeliveryDynamicsSuccess: (state, { payload: { data } }) => {
      state.isLoading = false;
      state.data = data;
    },
  },
});

export const deliveryDynamicsSelector = (state) =>
  state.statistics.deliveryDynamics;
