import { all, takeLatest, put } from 'redux-saga/effects';

import { areasAttributesActions } from '../../slices/areas/areasAttributes';
import { commonRequest } from '../apiSaga';

const mapAttributes = (el) => ({
  value: el.attributes[0],
  label: el.attributes[0],
  geometry: el.geometry,
});

const getAreasAttributesSaga = function* ({ payload: { areaId } }) {
  try {
    const response = yield commonRequest('getAreaAttributes', {
      parameters: { area: areaId },
    });

    yield put(
      areasAttributesActions.getAreasAttributesSuccess({
        data: response.map(mapAttributes),
      }),
    );
  } catch (e) {
    areasAttributesActions.getAreasAttributesSuccess({ data: [] });
  }
};

export const areasAttributesSaga = function* () {
  yield all([
    takeLatest(
      areasAttributesActions.getAreasAttributes.type,
      getAreasAttributesSaga,
    ),
  ]);
};
