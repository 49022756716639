import { useCallback } from 'react';
import { LSManager } from '@vanongo/utils';

export const useTableSettings = ({ setUserColumns, columns, LS_KEY }) => {
  const resetColumns = useCallback(() => {
    setUserColumns([...columns]);
    LSManager.delete(LS_KEY);
  }, [columns, setUserColumns, LS_KEY]);

  const setVisibilityColumn = useCallback(
    (field, isHidden) => {
      setUserColumns((prevUserColumns) => {
        const index = prevUserColumns.findIndex((el) => el.field === field);
        // DEEP COPY
        const nextItem = Object.entries(prevUserColumns[index]).reduce(
          (total, [key, value]) => {
            total[key] = value;
            return total;
          },
          {},
        );
        nextItem.isHidden = isHidden;
        const nextColumns = [...prevUserColumns];
        nextColumns.splice(index, 1, nextItem);

        LSManager.save(LS_KEY, nextColumns);
        return nextColumns;
      });
    },
    [setUserColumns, LS_KEY],
  );

  return {
    resetColumns,
    setVisibilityColumn,
  };
};
