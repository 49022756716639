import { all, put, takeEvery } from 'redux-saga/effects';
import { solverLogActions } from '../../slices/solver/solverLog';
import { customRequest } from '../apiSaga';

const getSolverLogForJobSaga = function* ({ payload }) {
  try {
    const response = yield customRequest(payload, {
      withoutToken: true,
      baseURL: '',
    });

    yield put(solverLogActions.getSolverLogForJobSuccess(response));
  } catch (e) {
    console.error(e);
  }
};

export const solverLogSaga = function* () {
  yield all([
    takeEvery(solverLogActions.getSolverLogForJob.type, getSolverLogForJobSaga),
  ]);
};
