import React, { useMemo } from 'react';
import {
  step2Metadata,
  getValidationSchema,
  pricingModelReadOnlyMetadata,
} from './step2Metadata';
import { StepForm } from '../StepForm';
import { useDispatch } from 'react-redux';
import { b2bServicesActions } from '../../../redux/slices/b2bServices/list';
import { useServiceInfo } from '../../../hooks/services/useServiceInfo';
import { get } from '@vanongo/utils';
import { Spinner } from '@vanongo/components';

export const Step2 = (props) => {
  const dispatch = useDispatch();
  const { activeStep } = props;

  const onSubmit = (data) => {
    const { serviceId, step1Data } = props.activeStep;
    const action = serviceId ? 'update' : 'create';

    dispatch(
      b2bServicesActions[action]({
        data: { ...data, ...step1Data },
        serviceId,
      }),
    );
  };

  const { step2Data } = activeStep;

  const metadata = step2Metadata;

  // pass partnerId to metadata
  const pricingPolygonsItem = metadata.find(
    (el) => el.name === 'pricingPolygons',
  );
  pricingPolygonsItem.effectParams = activeStep.step1Data.organization;

  // generate params fields from service - pricing model
  const { data: serviceInfoData, isLoading } = useServiceInfo(
    activeStep.step1Data.service,
  );

  const fields = useMemo(() => {
    if (!serviceInfoData.pricingModel || !serviceInfoData.pricingModel.params) {
      return [];
    }

    return (serviceInfoData.pricingModel.params || [])
      .map((name) => ({
        title: name,
        name: name,
        type: 'number',
      }))
      .concat(pricingModelReadOnlyMetadata);
  }, [serviceInfoData]);

  const validationSchema = useMemo(() => {
    return getValidationSchema(
      get(serviceInfoData, 'pricingModel.params', []) || [],
    );
  }, [serviceInfoData]);

  const preparedMetadata = metadata.concat(fields);

  const defaultValues = useMemo(() => {
    return {
      ...step2Data,
      pricingModel: get(serviceInfoData, 'pricingModel.name', ''),
    };
  }, [serviceInfoData, step2Data]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <StepForm
      {...props}
      metadata={preparedMetadata}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      hasBackButton
    />
  );
};
