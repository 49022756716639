import { all, put, select, takeEvery } from 'redux-saga/effects';
import {
  partnersInfoActions,
  partnersInfoSelector,
} from '../../slices/partners/partnersInfo';
import { commonRequest } from '../apiSaga';
import history from '../../../services/history';
import { toast } from '@vanongo/components/toast/SnackbarSingleton';

const getPartnersInfoSaga = function* ({ payload }) {
  const { data } = yield select(partnersInfoSelector);
  if (payload === data.id) {
    yield put(partnersInfoActions.getPartnersInfoSuccess(data));
    return;
  }

  try {
    const response = yield commonRequest('getPartnerProfile', {
      parameters: { partnerId: payload },
    });
    yield put(partnersInfoActions.getPartnersInfoSuccess(response));
  } catch (e) {
    yield put(partnersInfoActions.getPartnersInfoPutError());
  }
};

const updatePartnerSaga = function* ({ data, partnerId }) {
  try {
    const response = yield commonRequest('postPartnerProfile', {
      body: data,
      parameters: { partnerId },
    });

    yield put(
      partnersInfoActions.updatePartnersInfoSuccess({
        id: partnerId,
        ...response,
      }),
    );
    history.push(`/partners/details/${partnerId}`);
    toast('Partner data is successfully updated', { variant: 'success' });
  } catch (e) {
    yield put(partnersInfoActions.updatePartnersInfoFailed());
  }
};

const updatePartnersInfoSaga = function* ({ payload: { data, partnerId } }) {
  const {
    data: { solverSettings },
  } = yield select(partnersInfoSelector);
  const sendData = { ...data, solverSettings };

  yield updatePartnerSaga({ data: sendData, partnerId });
};

const updatePartnersSolverConfigSaga = function* ({
  payload: { data: solverSettings, partnerId },
}) {
  const { data } = yield select(partnersInfoSelector);
  const sendData = { ...data, space: data?.space?.id, solverSettings };
  delete sendData.id;

  yield updatePartnerSaga({
    data: sendData,
    partnerId,
  });
};

const updateImportMappingConfigSaga = function* ({
  payload: { data: orderImportMapping, partnerId },
}) {
  const { data } = yield select(partnersInfoSelector);
  const sendData = {
    ...data,
    space: data?.space?.id,
    orderImportMapping: orderImportMapping,
  };
  delete sendData.id;

  yield updatePartnerSaga({ data: sendData, partnerId });
};

const updateOrdersImportConfigSaga = function* ({
  payload: { warehouseAddresses, partnerId },
}) {
  const { data } = yield select(partnersInfoSelector);
  const sendData = { ...data, space: data?.space?.id, warehouseAddresses };
  delete sendData.id;

  yield updatePartnerSaga({ data: sendData, partnerId });
};

const reverseIntegrationConfigSaga = function* ({
  payload: { data: reverseIntegrationConfig, partnerId },
}) {
  const { data } = yield select(partnersInfoSelector);
  console.log('data', data);
  const sendData = {
    ...data,
    space: data?.space?.id,
    reverseIntegrationConfig: reverseIntegrationConfig,
  };
  delete sendData.id;

  yield updatePartnerSaga({ data: sendData, partnerId });
};

const removePartnerSaga = function* ({ payload }) {
  try {
    yield commonRequest('deletePartner', {
      parameters: { partnerId: payload },
    });

    history.push('/partners');
  } catch (e) {}
};

export const partnersInfoSaga = function* () {
  yield all([
    takeEvery(partnersInfoActions.getPartnersInfo.type, getPartnersInfoSaga),
    takeEvery(
      partnersInfoActions.updatePartnersInfo.type,
      updatePartnersInfoSaga,
    ),
    takeEvery(
      partnersInfoActions.updatePartnersSolverConfig.type,
      updatePartnersSolverConfigSaga,
    ),
    takeEvery(
      partnersInfoActions.updateImportMappingConfig.type,
      updateImportMappingConfigSaga,
    ),
    takeEvery(
      partnersInfoActions.updateOrdersImportConfig.type,
      updateOrdersImportConfigSaga,
    ),
    takeEvery(
      partnersInfoActions.reverseIntegrationConfig.type,
      reverseIntegrationConfigSaga,
    ),
    takeEvery(partnersInfoActions.removePartner.type, removePartnerSaga),
  ]);
};
