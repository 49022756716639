export const createQueryParams = (query) => {
  if (!query) {
    return '';
  }

  const searchParams = new URLSearchParams();
  Object.entries(query).forEach(([key, value]) => {
    if (value || value === 0 || value === false) {
      searchParams.set(key, value);
    }
  });

  const queryString = searchParams.toString();

  return queryString ? `?${queryString}` : '';
};
