import React from 'react';
import { step1Metadata, validationSchema } from './step1Metadata';
import { StepForm } from '../StepForm';

export const Step1 = (props) => {
  const { handleNext, activeStep } = props;

  const onSubmit = (data) => {
    handleNext({
      step1Data: data,
    });
  };

  const defaultValues = activeStep.step1Data;

  return (
    <StepForm
      {...props}
      metadata={step1Metadata}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
    />
  );
};
