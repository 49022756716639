import { reduxHelper } from '@vanongo/utils';
import { api } from '../../services/api';

export const {
  actions: smsProvidersOptionsActions,
  reducer: smsProvidersOptions,
  selector: smsProvidersOptionsSelector,
  saga: smsProvidersOptionsSaga,
} = reduxHelper({
  actionName: 'get',
  name: 'smsProvidersOptions',
  promise: () =>
    api.get(`${process.env.REACT_APP_ADMIN_URL}lists/sms-providers`),
  mapFunc: (arr) =>
    arr.map((el) => ({
      label: el.name,
      value: el.id,
    })),
});
