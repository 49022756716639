import OpenAPIClientAxios from 'openapi-client-axios';
import { HTTP_HEADERS, METHOD } from '../constants/api';
import { get } from '@vanongo/utils';
import { toast } from '@vanongo/components/toast/SnackbarSingleton';
import { localization } from './localization';
import { commonErrorToast } from '@vanongo/components/toast/commonErrorToast';
import store from 'packages/webAdmin/src/redux/store';
import yaml from '../vanongo_admin_api.yaml';

const apiClient = new OpenAPIClientAxios({
  definition: yaml,
  validate: false,
});

export const apiSdk = apiClient.init().then((client) => {
  client.defaults.baseURL = process.env.REACT_APP_ADMIN_URL;
  return client;
});

export function commonError(e) {
  if (get(e, 'status') === 401) {
    console.error('401');
    return;
  }

  toast(localization.t('Something went wrong'), commonErrorToast());
}

export const commonApiRequest = async (
  operationId,
  {
    body,
    parameters,
    customHeaders = {},
    withoutToken,
    disableErrorHandling,
  } = {
    customHeaders: {},
  },
) => {
  const token = store.getState().authorization.token;
  const headers = {
    [HTTP_HEADERS.CONTENT_TYPE]: 'application/json',
    ...customHeaders,
  };

  if (token && !withoutToken) {
    headers[HTTP_HEADERS.AUTHORIZATION] = token;
  }

  try {
    const api = await apiSdk;
    const response = await api[operationId](parameters, body, {
      headers,
    });
    return response.data;
  } catch (e) {
    if (!disableErrorHandling) {
      commonError(e);
    }
    throw e;
  }
};

export async function apiRequest(
  url,
  {
    body,
    customHeaders = {},
    method = METHOD.GET,
    withoutToken,
    disableErrorHandling,
    baseURL,
  } = {
    customHeaders: {},
    method: METHOD.GET,
  },
) {
  const token = store.getState().authorization.token;

  const headers = {
    [HTTP_HEADERS.CONTENT_TYPE]: 'application/json',
    ...customHeaders,
  };

  if (token && !withoutToken) {
    headers[HTTP_HEADERS.AUTHORIZATION] = token;
  }

  try {
    const api = await apiSdk;
    const response = await api.request({
      url,
      method,
      headers,
      data: body,
      baseURL,
    });

    return response.data;
  } catch (e) {
    if (!disableErrorHandling) {
      commonError(e, { url, body, e });
    }

    throw e;
  }
}
