import { all, takeLatest, put } from 'redux-saga/effects';

import { areasPricingPolygonsActions } from '../../slices/areas/areasPricingPolygons';
import history from '../../../services/history';
import { apiRequest } from '../../../services/apiSdk';

const getAreasPricingPolygonsSaga = function* ({ payload: areaId }) {
  try {
    const response = yield apiRequest(
      `${process.env.REACT_APP_ADMIN_URL}areas/${areaId}/pricing-polygons/`,
    );
    yield put(
      areasPricingPolygonsActions.getAreasPricingPolygonsSuccess({
        data: response,
      }),
    );
  } catch (e) {
    console.log(e);
  }
};

const createAreasPricingPolygonSaga = function* ({
  payload: { areaId, formState, operationArea },
}) {
  try {
    const response = yield apiRequest(
      `${process.env.REACT_APP_ADMIN_URL}areas/${areaId}/pricing-polygons/`,
      {
        method: 'post',
        body: {
          name: formState.name,
          priority: formState.priority,
          extraMinutePrice: formState.extraMinutePrice,
          operationArea,
        },
      },
    );

    yield put(
      areasPricingPolygonsActions.createPricingPolygonSuccess({
        data: response,
      }),
    );
    history.push(`/areas/${areaId}/pricing-polygons`);
  } catch (e) {
    yield put(areasPricingPolygonsActions.createPricingPolygonFailed());
  }
};

const editAreasPricingPolygonSaga = function* ({
  payload: { areaId, formState, id, operationArea },
}) {
  try {
    const response = yield apiRequest(
      `${process.env.REACT_APP_ADMIN_URL}areas/${areaId}/pricing-polygons/${id}`,
      {
        method: 'patch',
        body: {
          name: formState.name,
          priority: formState.priority,
          extraMinutePrice: formState.extraMinutePrice,
          operationArea,
        },
      },
    );

    yield put(
      areasPricingPolygonsActions.editPricingPolygonSuccess({
        data: response,
      }),
    );
    history.push(`/areas/${areaId}/pricing-polygons`);
  } catch (e) {
    console.log(e);
    yield put(areasPricingPolygonsActions.editPricingPolygonFailed());
  }
};

const deleteAreasPricingPolygonSaga = function* ({ payload: { id, areaId } }) {
  try {
    yield apiRequest(
      `${process.env.REACT_APP_ADMIN_URL}areas/${areaId}/pricing-polygons/${id}`,
      {
        method: 'delete',
      },
    );

    yield put(areasPricingPolygonsActions.getAreasPricingPolygons(areaId));
  } catch (e) {
    console.log(e);
    yield put(areasPricingPolygonsActions.deletePricingPolygonFailed());
  }
};

const reorderAreasPricingPolygonSaga = function* ({ payload: { list } }) {
  const ids = list.map((item) => item.id);
  try {
    yield apiRequest(
      `${process.env.REACT_APP_ADMIN_URL}pricing-polygons/priorities`,
      {
        method: 'post',
        body: ids,
      },
    );

    yield put(areasPricingPolygonsActions.reorderPricingPolygonSuccess());
  } catch (e) {
    console.log(e);
    yield put(areasPricingPolygonsActions.reorderPricingPolygonSuccess());
  }
};

export const areasPricingPolygonsSaga = function* () {
  yield all([
    takeLatest(
      areasPricingPolygonsActions.getAreasPricingPolygons.type,
      getAreasPricingPolygonsSaga,
    ),
    takeLatest(
      areasPricingPolygonsActions.createPricingPolygon.type,
      createAreasPricingPolygonSaga,
    ),
    takeLatest(
      areasPricingPolygonsActions.editPricingPolygon.type,
      editAreasPricingPolygonSaga,
    ),
    takeLatest(
      areasPricingPolygonsActions.deletePricingPolygon.type,
      deleteAreasPricingPolygonSaga,
    ),
    takeLatest(
      areasPricingPolygonsActions.reorderPricingPolygon.type,
      reorderAreasPricingPolygonSaga,
    ),
  ]);
};
