import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: waybillsInfoActions } = createSlice({
  name: 'waybillsInfo',
  initialState: {
    data: {},
    isLoading: false,
    isUpdating: false,
    isError: false,
  },
  reducers: {
    getWaybillsInfo: (state) => {
      state.isLoading = true;
    },
    getWaybillsInfoSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
    getWaybillsPutError: (state) => {
      state.isError = true;
    },
    assignDrivers: (state) => {
      state.isLoading = true;
    },
    assignDriversSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
    updateWaybillsInfo: (state) => {
      state.isUpdating = true;
    },
    updateWaybillsInfoSuccess: (state, { payload }) => {
      state.isUpdating = false;
      state.data = payload;
    },
    updateWaybillsInfoFailed: (state) => {
      state.isUpdating = false;
    },
    cancelWaybill: (state) => {
      state.isLoading = true;
    },
    cancelWaybillSuccess: (state) => {
      state.isLoading = false;
      state.data.status = 'CANCELLED';
    },
  },
});

export const waybillsInfoSelector = (state) => state.waybills.waybillsInfo;
