import { optionsHelper } from './optionsHelper';

export const {
  actions: solversOptionsActions,
  reducer: solversOptions,
  selector: solversOptionsSelector,
  saga: solversOptionsSaga,
} = optionsHelper({
  actionName: 'getSolversOptions',
  name: 'solversOptions',
  operationId: 'getSolvers',
  mapFunc: (arr) =>
    arr.map((el) => ({
      label: el.label,
      value: el.label,
      container: el.container,
    })),
});
