const HOURS = 60 * 60;
const MINUTES = 60;

const twoDigits = (value) => {
  if (value.toString().length === 1) {
    return '0' + value;
  } else return value;
};

export const timeStringFromSeconds = (seconds) => {
  const hours = Math.floor(seconds / HOURS);
  const minutes = Math.floor((seconds - hours * HOURS) / MINUTES);
  const sec = (seconds - hours * HOURS - minutes * MINUTES).toFixed(0);

  return `${twoDigits(hours)}:${twoDigits(minutes)}:${twoDigits(sec)}`;
};
