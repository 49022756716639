import React, { Suspense, useCallback, useState } from 'react';
import PopupLayout from '../PopupLayout/PopupLayout';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../constants/namespaces';
import { Table } from '@vanongo/components/Table';
import LoadingScreen from '@vanongo/components/LoadingScreen';
import { useDispatch, useSelector } from 'react-redux';
import { waybillsInfoActions } from '../../redux/slices/waybills/waybillsInfo';
import {
  driversListActions,
  driversListSelector,
} from '../../redux/slices/drivers/driversList';
import { useListActions } from '../../hooks/useListActions';
import { useTransformMetadata } from '../../hooks/useTransformMetadata';
import {filterListAssignDriversMetadata} from '../../views/common/DriverList/filterListMetadata';
import { driversTableMetadata } from '../../views/common/DriverList/driversTableMetadata';
import { FilterPanel } from '@vanongo/components/Filter/FilterPanel';
import { Card } from '@material-ui/core';
import { usePartnersListOptions } from '../../hooks/options/usePartnersListOptions';

export const AssignDrivers = ({ waybillId, areaId, closePopup }) => {
  const dispatch = useDispatch();
  const { t: tGlobal } = useTranslation(NAMESPACES.GLOBAL);
  const { t } = useTranslation(NAMESPACES.WAYBILL);
  const [filters, changeFilters] = useState({
    area: areaId,
  });

  const {options: partnersListOptions, isLoading: isLoadingPartnersList } = usePartnersListOptions();

  const handleFilters = (filters) => {
    changeFilters({ ...filters, area: areaId });
  };
  const [selectedDrivers, changeSelectedDrivers] = useState([]);

  const onDriverSelectionChange = useCallback(
    (selection) => {
      changeSelectedDrivers(selection);
    },
    [changeSelectedDrivers],
  );

  const buttonConfig = [
    { text: tGlobal('Close'), onClick: closePopup },
    {
      text: tGlobal('Save'),
      color: 'secondary',
      variant: 'contained',
      onClick: () => {
        dispatch(
          waybillsInfoActions.assignDrivers({
            waybillId,
            drivers: selectedDrivers,
          }),
        );
        closePopup();
      },
      disabled: !selectedDrivers.length,
    },
  ];

  const { drivers, totalCount, page, pageSize, isLoading } =
    useSelector(driversListSelector);

  const { onChangePage, onChangeRowsPerPage, onRefresh } = useListActions({
    action: driversListActions.getDriverList,
    page,
    pageSize,
    params: filters,
  });

  const filterMetadata = useTransformMetadata({
    metadata: filterListAssignDriversMetadata,
    t,
  });

  filterMetadata[2].optionsEffect = {options: partnersListOptions, isLoading: isLoadingPartnersList};

  const metadata = useTransformMetadata({
    metadata: driversTableMetadata,
    t,
  });

  return (
    <PopupLayout
      width="90vw"
      title={t('Assign drivers')}
      buttonConfig={buttonConfig}
    >
      <div style={{ height: '55vh' }}>
        <Suspense fallback={<LoadingScreen />}>
          <Card style={{ height: '100%' }}>
            <FilterPanel
              metadataOnTheFly={filterMetadata}
              values={filters}
              onChange={handleFilters}
            />
            <div style={{ height: 'calc(100% - 48px)' }}>
              <Table
                rowCount={totalCount}
                data={drivers}
                onRefresh={onRefresh}
                isLoading={isLoading}
                onSelectionChange={onDriverSelectionChange}
                selection={selectedDrivers}
                enableRowSelection
                page={page}
                pageSize={pageSize}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                columns={metadata}
                rowSize="small"
                enableResizing
                tableName="drivers"
              />
            </div>
          </Card>
        </Suspense>
      </div>
    </PopupLayout>
  );
};
