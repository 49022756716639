export const CARGO_STATUS = [
  { value: 'UNKNOWN', label: 'UNKNOWN' },
  { value: 'CREATED', label: 'CREATED' },
  { value: 'AT_WAREHOUSE', label: 'AT_WAREHOUSE' },
  { value: 'AT_WAREHOUSE_CONNECTED', label: 'AT_WAREHOUSE_CONNECTED' },
  { value: 'AT_WAREHOUSE_FULL_ORDER', label: 'AT_WAREHOUSE_FULL_ORDER' },
  { value: 'PICKED_UP_BY_DRIVER', label: 'PICKED_UP_BY_DRIVER' },
  { value: 'DELIVERED', label: 'DELIVERED' },
  { value: 'RETURNED_TO_PARTNER', label: 'RETURNED_TO_PARTNER' },
  { value: 'LOST', label: 'LOST' },
  { value: 'DAMAGED', label: 'DAMAGED' },
];
