import { all, put, select, takeEvery } from 'redux-saga/effects';
import {
  areasPaymentMethodsActions,
  areasPaymentMethodsSelector,
} from '../../slices/areas/areasPaymentMethods';
import { commonRequest } from '../apiSaga';
import { get } from '@vanongo/utils';

const getAreasPaymentMethodsSaga = function* ({ payload }) {
  const { data } = yield select(areasPaymentMethodsSelector);
  if (+payload === get(data, '0.areaId')) {
    yield put(areasPaymentMethodsActions.getAreasPaymentMethodsSuccess(data));
    return;
  }

  try {
    const response = yield commonRequest('getAreaPaymentMethodsByAreaId', {
      parameters: { areaId: payload },
    });

    yield put(
      areasPaymentMethodsActions.getAreasPaymentMethodsSuccess(response),
    );
  } catch (e) {}
};

const getPaymentMethodsOptionsSaga = function* () {
  const { payMethodsOptions } = yield select(areasPaymentMethodsSelector);
  if (payMethodsOptions.length) {
    yield put(
      areasPaymentMethodsActions.getPaymentMethodsOptionsSuccess(
        payMethodsOptions,
      ),
    );
    return;
  }

  try {
    const response = [
      { label: 'BANK_TRANSFER', value: 'BANK_TRANSFER' },
      { label: 'CARD', value: 'CARD' },
      { label: 'CASH', value: 'CASH' },
    ];

    yield put(
      areasPaymentMethodsActions.getPaymentMethodsOptionsSuccess(response),
    );
  } catch (e) {}
};

const addNewPaymentMethodSaga = function* ({
  payload: {
    areaId,
    paymentMethod: { userType, payMethod, cardProcessor },
  },
}) {
  try {
    const response = yield commonRequest('createPaymentMethod', {
      parameters: { areaId },
      body: {
        areaId: +areaId,
        type: userType.value,
        paymentMethodType: payMethod.value,
        cardPaymentProcessor: cardProcessor ? cardProcessor.value : '',
      },
    });

    yield put(
      areasPaymentMethodsActions.addNewPaymentMethodSuccess({
        ...response,
        paymentMethodType: payMethod.label,
      }),
    );
  } catch (e) {
    console.log(e);
  }
};

const removePaymentMethodSaga = function* ({
  payload: { areaId, paymentMethodId },
}) {
  try {
    yield commonRequest('deleteAreaPaymentMethod', {
      parameters: { areaId, paymentMethodId },
    });

    yield put(
      areasPaymentMethodsActions.removePaymentMethodSuccess(paymentMethodId),
    );
  } catch (e) {
    console.log(e);
  }
};

export const areasPaymentMethodsSaga = function* () {
  yield all([
    takeEvery(
      areasPaymentMethodsActions.getAreasPaymentMethods.type,
      getAreasPaymentMethodsSaga,
    ),
    takeEvery(
      areasPaymentMethodsActions.getPaymentMethodsOptions.type,
      getPaymentMethodsOptionsSaga,
    ),
    takeEvery(
      areasPaymentMethodsActions.addNewPaymentMethod.type,
      addNewPaymentMethodSaga,
    ),
    takeEvery(
      areasPaymentMethodsActions.removePaymentMethod.type,
      removePaymentMethodSaga,
    ),
  ]);
};
