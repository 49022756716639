import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',

        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      },
      '.df': {
        display: 'flex',
      },
      '.gmnoprint': {
        zIndex: '1000 !important',
      },
      '.jcc': {
        justifyContent: 'center',
      },
      '.fdc': {
        flexDirection: 'column',
      },
      '.aife': {
        alignItems: 'flex-end',
      },
      '.aic': {
        alignItems: 'center',
      },
      '.jcsb': {
        justifyContent: 'space-between',
      },
      '#root': {
        height: '100%',
        width: '100%',

        '& .react-datepicker-popper': {
          zIndex: 50,
        },

        '& .react-datepicker-wrapper': {
          width: '100%',
        },
      },
      '#root .MuiAccordion-root.Mui-expanded': {
        margin: 0,
      },
      '#root .MuiAccordion-root:before': {
        content: 'none',
      },
      '.react-date-picker-popover': {
        zIndex: 10000,
      },
      '.leaflet-popup-content p': {
        margin: 0,
      },
      '.MuiInputLabel-outlined': {
        transform: 'translate(14px, 16px) scale(1)',
      },
      '.MuiOutlinedInput-input': {
        padding: '14.5px 14px',
      },
      '.MuiCard-root': {
        overflow: 'visible',
      },
      '.MuiTableRow-root.MuiTableRow-footer': {
        display: 'flex',
        justifyContent: 'center',
      },
      '.MuiTableHead-root': {
        position: 'sticky',
        top: 0,
      },
      '.MuiTooltip-tooltip *': {
        color: '#fff',
      },
      '.table-container': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',

        '&.hasToolbar': {
          height: 'calc(100% - 64px)',
        },

        '&.noData > div:not(.MuiToolbar-root)  tr td': {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          border: 'none',
        },

        '&> div:not(.MuiToolbar-root):not(.custom)': {
          height: '100%',
          '&> div': {
            height: '100%',
            '&> div': {
              height: '100%',
              overflowY: 'visible !important',
            },
          },
        },
      },
      '@media (min-width: 600px)': {
        '.MuiToolbar-regular': {
          minHeight: 72,
        },
      },
      'input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button':
        {
          '-webkit-appearance': 'none',
          margin: 0,
        },
    },
  }),
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
