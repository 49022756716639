import React from 'react';
import {makeStyles, Box, TextareaAutosize} from '@material-ui/core';

import PopupLayout from '../PopupLayout/PopupLayout';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../constants/namespaces';

const useStyles = makeStyles(() => ({
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  textContainer: {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
}));

export const InfoPopup = ({
  title,
  text,
  buttonText,
  onConfirm,
  closePopup,
  disabled,
}) => {
  const { t } = useTranslation(NAMESPACES.GLOBAL);
  const classes = useStyles();

  const confirmButtons = [
    { text: t('Cancel'), onClick: closePopup },
    {
      color: 'secondary',
      type: 'submit',
      disabled,
      variant: 'contained',
      text: buttonText || t('Confirm'),
      onClick: onConfirm,
    },
  ];

  return (
    <PopupLayout
      width={500}
      title={title || t('Confirm action')}
      buttonConfig={confirmButtons}
    >
      <Box mt={2} className={classes.textContainer}>
        <TextareaAutosize
          defaultValue={text}
          style={{
            minWidth: 200,
            maxWidth: 450,
            width: 450,
            minHeight: 250,
            maxHeight: 450,
            height: 300,
            overflow: 'auto'
          }}
        />
      </Box>
    </PopupLayout>
  );
};
