import { all, takeLatest, put } from 'redux-saga/effects';

import { areasListActions } from '../../slices/areas/areasList';
import { commonRequest } from '../apiSaga';

const getAreasListSaga = function* () {
  try {
    const response = yield commonRequest('getAreas');

    yield put(
      areasListActions.getAreasListSuccess({
        data: response,
      }),
    );
  } catch (e) {}
};

export const areasListSaga = function* () {
  yield all([takeLatest(areasListActions.getAreasList.type, getAreasListSaga)]);
};
