import React, { useRef } from 'react';
import { Box, IconButton, makeStyles, SvgIcon } from '@material-ui/core';
import { RefreshCw as RefreshIcon } from 'react-feather';
import { ToolbarSelection } from './ToolbarSelection';
import { Pagination } from '../pagination/Pagination';
import { TableSettings } from './TableSettings';
import { useGetTableWidth } from '../../hooks/useGetTableWidth';

const useStyles = makeStyles((theme) => ({
  refresh: {
    marginLeft: 5,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '2px 8px',
    borderBottom: '1px solid #e0e0e0',
  },
  popover: {
    overflow: 'auto',
    width: 320,
    padding: theme.spacing(2),
  },
}));

export const Toolbar = ({
  setTableWidth,
  onRefresh,
  rowCount,
  onSelectAll,
  selectionButtonConfig,
  page,
  pageSize,
  onChangePage,
  onChangeRowsPerPage,
  rowsPerPageOptions,
  userColumns,
  resetColumns,
  setVisibilityColumn,
  tableName,
}) => {
  const classes = useStyles();
  const tableRef = useRef();

  useGetTableWidth({ setTableWidth, ref: tableRef });

  return (
    <Box ref={tableRef} className={classes.wrapper}>
      <ToolbarSelection
        rowCount={rowCount}
        onSelectAll={onSelectAll}
        selectionButtonConfig={selectionButtonConfig}
      >
        {onRefresh && (
          <IconButton
            color="secondary"
            onClick={onRefresh}
            className={classes.refresh}
          >
            <SvgIcon fontSize="small">
              <RefreshIcon />
            </SvgIcon>
          </IconButton>
        )}
      </ToolbarSelection>
      <div className="df aic">
        <Pagination
          pageSize={pageSize}
          page={page}
          rowCount={rowCount}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
        {tableName && (
          <TableSettings
            classes={classes}
            userColumns={userColumns}
            resetColumns={resetColumns}
            setVisibilityColumn={setVisibilityColumn}
          />
        )}
      </div>
    </Box>
  );
};
