import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: orderSmsHistoryActions } = createSlice({
  name: 'orderSmsHistory',
  initialState: {
    data: [],
    orderItemId: null,
    isLoading: false,
  },
  reducers: {
    getOrderSmsHistory: (state) => {
      state.isLoading = true;
    },
    getOrderSmsHistorySuccess: (state, { payload: { data, orderItemId } }) => {
      state.isLoading = false;
      state.data = data;
      state.orderItemId = orderItemId;
    },
  },
});

export const orderSmsHistorySelector = (state) => state.orders.orderSmsHistory;
