import { all, takeLatest, put, select } from 'redux-saga/effects';
import { waybillsListActions } from '../../slices/waybills/waybillsList';
import { commonRequest } from '../apiSaga';
import { areasFilterValueSelector } from '../../slices/global/areasFilter';
import { getObjFromQuery } from '@vanongo/utils';
import { downloadBlob, backendTime, takeFirst } from '@vanongo/utils';

export const createFilters = (queryString) => {
  const query = getObjFromQuery(queryString);

  if (!query) {
    return {};
  }
  if (!Object.keys(query).length) {
    return {};
  }

  const filters = {
    status: query.status,
    shortId: query.shortId,
    search: query.search,
    driver: query.driver,
    service: query.service,
    organization: query.organization,
  };

  if (query.dateType && (query.dateFrom || query.dateTo)) {
    if (query.dateFrom) {
      filters[`>=${query.dateType}`] = backendTime(+query.dateFrom);
    }
    if (query.dateTo) {
      filters[`<=${query.dateType}`] = backendTime(+query.dateTo);
    }
  }

  return filters;
};

const getWaybillsListSaga = function* ({
  payload: { pageSize = 10, page = 1, queryString } = { pageSize: 10, page: 1 },
}) {
  const area = yield select(areasFilterValueSelector);

  try {
    const response = yield commonRequest('getWaybills', {
      parameters: {
        page,
        limit: pageSize,
        area,
        ...createFilters(queryString),
      },
    });

    yield put(
      waybillsListActions.getWaybillsListSuccess({
        data: response.results,
        page: response.currentPage,
        pageSize,
        totalCount: response.totalCount,
      }),
    );
  } catch (e) {}
};

const dataExportSaga = function* ({ payload }) {
  try {
    const response = yield commonRequest('exportWaybills', {
      body: { waybills: payload.map(({ id }) => id) },
    });

    downloadBlob('', '', response.url);
    yield put(waybillsListActions.dataExportSuccess());
  } catch (e) {
    yield put(waybillsListActions.dataExportSuccess());
    console.error(e);
  }
};

export const waybillsListSaga = function* () {
  yield all([
    takeLatest(waybillsListActions.getWaybillsList.type, getWaybillsListSaga),
    takeFirst(waybillsListActions.dataExport.type, dataExportSaga),
  ]);
};
