import { all, put, fork, take } from 'redux-saga/effects';
import { areasFilterOptionsActions } from '../../slices/global/areasFilter';
import { apiRequest } from '../../../services/apiSdk';

const mapAreaOptions = (el) => ({
  value: el.id,
  label: el.name,
});

const GET_AREAS_TYPE = areasFilterOptionsActions.getAreasFilterOptions.type;

function* getAreasSaga() {
  while (true) {
    yield take(GET_AREAS_TYPE);

    try {
      const response = yield apiRequest(
        `${process.env.REACT_APP_ADMIN_URL}areas/list-brief`,
      );

      yield put(
        areasFilterOptionsActions.getAreasFilterOptionsSuccess(
          response.map(mapAreaOptions),
        ),
      );
    } catch (e) {}
  }
}

export const areasFilterSaga = function* () {
  yield all([fork(getAreasSaga)]);
};
