import { all, takeLatest, put } from 'redux-saga/effects';
import { api } from '../../../services/api';
import { mapStopListActions } from '../../slices/map/mapStopList';
import { getObjFromQuery } from '@vanongo/utils';

const getMapStopListSaga = function* ({ payload: { queryString } }) {
  if (!queryString) {
    yield put(
      mapStopListActions.getMapStopListSuccess({
        data: {},
      }),
    );
  }

  const filtersValue = getObjFromQuery(queryString);
  let response;

  try {
    if (filtersValue && filtersValue?.externalOrderId) {
      response = yield api.get(`${process.env.REACT_APP_ADMIN_URL}map`, {
        query: {
          externalOrderId: filtersValue.externalOrderId,
        },
      });
    }

    yield put(
      mapStopListActions.getMapStopListSuccess({
        data: response,
      }),
    );
  } catch (e) {
    console.log(e);

    if(e?.response?.status === 400) {
      yield put(
        mapStopListActions.setErrorMessage({
          data: e?.response?.body?.errorContent?.map(item => item?.errorText).reduce((totalItem, item) => totalItem + "\n" + item ),
        }),
      );
    } else {
      yield put(
        mapStopListActions.setErrorMessage({
          data: e?.response?.body?.errorMessage,
        }),
      );
    }
  }
};

export const getMapStopSaga = function* () {
  yield all([
    takeLatest(mapStopListActions.getMapStopList.type, getMapStopListSaga),
  ]);
};
