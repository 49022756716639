import { reduxHelper } from '@vanongo/utils';
import { api } from '../../services/api';

export const {
  actions: attributeInfoActions,
  reducer: attributeInfo,
  selector: attributeInfoSelector,
  saga: attributeInfoSaga,
} = reduxHelper({
  actionName: 'get',
  name: 'attributeInfo',
  defaultValue: {},
  fetchOnce: false,
  promise: (id) =>
    api.get(`${process.env.REACT_APP_ADMIN_URL}attributes/${id}`),
});
