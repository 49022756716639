import { get } from './get';

export const getValidationErrors = (e) => {
  const errorContent = get(e, 'response.data.errorContent') ||  get(e, 'response.body.errorContent');

  if (!errorContent || typeof errorContent !== 'object') {
    return {};
  }



  return Object.entries(errorContent).reduce((acc, [field, error]) => {
    // TODO recursive for deep error
    if (!Array.isArray(error)) {
      return acc;
    }


    acc[field] = get(error, '0.errorText') ;

    return acc;
  }, {});
};
