import { reduxHelper } from '@vanongo/utils';
import { api } from '../../services/api';

export const {
  actions: countriesOptionsActions,
  reducer: countriesOptions,
  selector: countriesOptionsSelector,
  saga: countriesOptionsSaga,
} = reduxHelper({
  actionName: 'get',
  name: 'countriesOptions',
  promise: () =>
    api.get(`${process.env.REACT_APP_ADMIN_URL}countries/list-brief`),
  mapFunc: (arr) =>
    arr.map((el) => ({
      label: el.name,
      value: el.id,
    })),
});
