import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: driversUploadFilesActions } = createSlice({
  name: 'driversUploadFiles',
  initialState: {
    data: [],
    page: 0,
    totalCount: 0,
    pageSize: 10,

    isLoading: false,
    isUpdating: false,
    isUpdateList : true,
  },
  reducers: {
    getUploadFilesList: (state) => {
      state.isLoading = true;
    },
    getUploadFilesListSuccess: (state, { payload: { data, page, totalCount, pageSize } }) => {
      state.isLoading = false;
      state.data = data;
      state.page = page;
      state.totalCount = totalCount;
      state.pageSize = pageSize;
    },
    getUploadFilesListFailed: (state) => {
      state.isLoading = false;
    },
    updateUploadFiles: (state) => {
      state.isUpdating = true;
    },
    updateUploadFilesSuccess: (state ) => {
      state.isUpdating = false;
    },
    updateUploadFilesFailed: (state) => {
      state.isUpdating = false;
    },
    uploadFiles: (state) => {
      state.isLoading = true;
      // state.isUpdateList = true;
    },
    uploadFilesSuccess: (state, { payload: { data } }) => {
      state.isLoading = false;
      state.isUpdateList = false;
      state.data.unshift(data);
    },
    uploadFilesFailed: (state) => {
      state.isLoading = false;
      state.isUpdateList = false;
    },
    deleteUploadFiles: (state) => {
      state.isLoading = true;
    },
    deleteUploadFilesSuccess: (state) => {
      state.isLoading = false;
    },
    deleteUploadFilesFailed: (state) => {
      state.isLoading = false;
    },
    downloadUploadFiles: (state) => {
      state.isLoading = true;
    },
    downloadUploadFilesSuccess: (state) => {
      state.isLoading = false;
    },
    downloadUploadFilesFailed: (state) => {
      state.isLoading = false;
    },
  },
});

export const driversUploadFilesSelector = (state) => state.drivers.driversUploadFiles;
