import { reduxHelper } from '@vanongo/utils';
import { api } from '../../services/api';

export const {
  actions: currenciesOptionsActions,
  reducer: currenciesOptions,
  selector: currenciesOptionsSelector,
  saga: currenciesOptionsSaga,
} = reduxHelper({
  actionName: 'get',
  name: 'currenciesOptions',
  promise: () => api.get(`${process.env.REACT_APP_ADMIN_URL}currencies`),
  mapFunc: (arr) =>
    arr.map((el) => ({
      label: el.alphabetic,
      value: el.id,
    })),
});
