import { createSlice, createAction } from '@reduxjs/toolkit';

export const { reducer, actions: authorizationActions } = createSlice({
  name: 'authorization',
  initialState: {
    token: '',
    isPending: true,
    userInfo: {},
  },
  reducers: {
    signIn: (state) => {
      state.isPending = true;
    },
    clearAuth: (state) => {
      state.token = '';
      state.isPending = false;
      state.userInfo = {};
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUserInfo: (state, { payload: { user, token } }) => {
      state.userInfo = user;
      state.token = token;
    },
  },
});

export const SIGN_OUT = 'SIGN_OUT';
export const signOut = createAction(SIGN_OUT);

export const tokenSelector = (state) => state.authorization.token;
export const isTokenPendingSelector = (state) => state.authorization.isPending;
export const userInfoSelector = (state) => state.authorization.userInfo;

export const WINDOW_FOCUS = 'WINDOW_FOCUS';
export const windowFocus = createAction(WINDOW_FOCUS);
