import React, { forwardRef } from 'react';
import {
  ChevronLeft,
  ChevronRight,
  SkipBack,
  SkipForward,
} from 'react-feather';

export const paginationIcons = {
  FirstPage: forwardRef((props, ref) => (
    <SkipBack {...props} size={22} ref={ref} />
  )),
  LastPage: forwardRef((props, ref) => (
    <SkipForward {...props} size={22} ref={ref} />
  )),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
};
