import React from 'react';
import { paginationIcons } from './paginationIcons';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../../../webAdmin/src/constants/namespaces';

export const ActionsComponent = ({
  onPageChange: onChangePage,
  page,
  count,
  rowsPerPage,
}) => {
  const { t } = useTranslation(NAMESPACES.UI_KIT);

  const handleFirstPageButtonClick = () => {
    onChangePage(0);
  };

  const handleBackButtonClick = () => {
    onChangePage(page - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(page + 1);
  };

  const handleLastPageButtonClick = () => {
    onChangePage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className="df aic">
      <Tooltip title={t('First Page')}>
        <span>
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
          >
            <paginationIcons.FirstPage />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title={t('Previous Page')}>
        <span>
          <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
            <paginationIcons.PreviousPage />
          </IconButton>
        </span>
      </Tooltip>
      <Typography
        variant="caption"
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        {t('DISPLAYED_ROWS')
          .replace('{from}', count === 0 ? 0 : page * rowsPerPage + 1)
          .replace('{to}', Math.min((page + 1) * rowsPerPage, count))
          .replace('{count}', count)}
      </Typography>
      <Tooltip title={t('Next Page')}>
        <span>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          >
            <paginationIcons.NextPage />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t('Last Page')}>
        <span>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          >
            <paginationIcons.LastPage />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
};
