import { all, put, takeEvery } from 'redux-saga/effects';
import { waybillsCreateActions } from '../../slices/waybills/waybillsCreate';
import { commonRequest } from '../apiSaga';
import history from '../../../services/history';

const createWaybillsSaga = function* ({ payload: data }) {
  try {
    const response = yield commonRequest('createWaybill', {
      body: data,
    });
    yield put(waybillsCreateActions.createWaybillsSuccess());
    history.push(`/waybills/${response.id}`);
  } catch (e) {
    console.error(e);
    yield put(waybillsCreateActions.createWaybillsSuccess());
  }
};

export const waybillsCreateSaga = function* () {
  yield all([
    takeEvery(waybillsCreateActions.createWaybills.type, createWaybillsSaga),
  ]);
};
