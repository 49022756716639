import { CARGO_STATUS } from '../../../constants/options/cargoStatus';

export const cargoFilterListMetadata = [
  {
    name: 'status',
    type: 'select',
    title: 'Status',
    options: CARGO_STATUS,
    width: 200,
  },
  {
    name: 'cargoExternalId',
    type: 'string',
    title: 'CargoExternalId',
  },
  {
    name: 'orderShortId',
    type: 'string',
    title: 'OrderShortId',
  },
  {
    name: 'waybillShortId',
    type: 'string',
    title: 'WaybillShortId',
  },
  {
    name: 'driverName',
    type: 'string',
    title: 'Driver name',
  },
];
