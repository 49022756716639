import React, { useMemo, useRef } from 'react';
import { Marker } from 'react-leaflet';
import { defaultMarker } from './icons/defaultMarker';

export const DraggableMarker = ({
  position,
  onChange,
  children,
  draggable,
}) => {
  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          onChange(marker.getLatLng());
        }
      },
    }),
    [onChange],
  );

  return (
    <Marker
      position={position}
      draggable={draggable}
      eventHandlers={eventHandlers}
      icon={defaultMarker}
      ref={markerRef}
    >
      {children}
    </Marker>
  );
};
