import { reduxHelper } from '@vanongo/utils';
import { api } from '../../services/api';

export const {
  actions: smsStatusListActions,
  reducer: smsStatusList,
  selector: smsStatusListSelector,
  saga: smsStatusListSaga,
} = reduxHelper({
  actionName: 'getSmsStatusListOptions',
  name: 'smsStatusList',
  promise: () =>
    api.get(
      `${process.env.REACT_APP_ADMIN_URL}lists/sms-status-list`,
    )
});
