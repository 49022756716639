import React, { useMemo } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { X as ClearIcon } from 'react-feather';
import { defaultFilterValuesMapper } from './defaultFilterValuesMapper';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    background: '#1791FF',
    border: '1px solid #1791FF',
    borderRadius: 47,
    marginLeft: 10,
    padding: '4px 42px 4px 10px',

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    maxWidth: 600,

    color: '#fff',
    fontWeight: 500,
    fontSize: 16,
  },

  clearButton: {
    cursor: 'pointer',
    padding: 0,
    position: 'absolute',
    right: 6,
    top: '50%',
    transform: 'translate(0, -50%)',
    background: '#fff',
    borderRadius: '100%',
    width: 18,
    height: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  svg: {
    stroke: 'rgb(23, 145, 255)',
    strokeWidth: '4px',
  },
}));

export const FilterValues = ({
  values,
  onChange,
  valuesMapper = defaultFilterValuesMapper,
  metadata,
  metadataOnTheFly,
}) => {
  const classes = useStyles();

  const handleClear = () => {
    onChange({});
  };

  const arrIndex = [];
  let isSelectUUID = false;
  const valuesString = useMemo(() => {
    const arrConcarMetadata = metadataOnTheFly.concat(metadata);
    for (const item in values) {
      const itemIndex = arrConcarMetadata?.findIndex((value) => {
        isSelectUUID = !!value?.optionsEffect?.options?.length;

        return value.name === item && value.optionsEffect;
      });

      if (values[item] === null) {
        delete values[item];
      }

      if (itemIndex !== -1) {
        arrIndex.push(itemIndex);
      }
    }

    return valuesMapper(values, arrConcarMetadata, arrIndex, isSelectUUID);
  }, [values, valuesMapper, metadata, metadataOnTheFly]);

  if (!valuesString) {
    return null;
  }

  return (
    <Tooltip title={valuesString}>
      <div className={classes.container}>
        {valuesString}

        <div onClick={handleClear} className={classes.clearButton}>
          <ClearIcon size={12} className={classes.svg} />
        </div>
      </div>
    </Tooltip>
  );
};
