import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: waybillsCreateActions } = createSlice({
  name: 'waybillsCreate',
  initialState: {
    isUpdating: false,
  },
  reducers: {
    createWaybills: (state) => {
      state.isUpdating = true;
    },
    createWaybillsSuccess: (state) => {
      state.isUpdating = false;
    },
  },
});

export const waybillsCreateSelector = (state) => state.waybills.waybillsCreate;
