import React, { useRef, useState } from 'react';
import {
  IconButton,
  makeStyles,
  Popover,
  SvgIcon,
  Tooltip,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../webAdmin/src/constants/namespaces';
import { Filter as FilterIcon } from 'react-feather';
import { Form } from '../Form';

const useStyles = makeStyles(() => ({
  popover: {
    overflow: 'visible',
    width: 600,
  },
}));

export const Filter = ({ metadata, columnSize, values, onSubmit }) => {
  const { t } = useTranslation(NAMESPACES.UI_KIT);
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const buttonConfig = [
    {
      color: 'secondary',
      type: 'submit',
      variant: 'contained',
      text: t('Search'),
    },
  ];

  const handleSubmit = (props) => {
    handleClose();
    onSubmit(props);
  };

  return (
    <>
      <Tooltip title={t('Filters')}>
        <IconButton style={{ color: '#1791FF' }} onClick={handleOpen} ref={ref}>
          <SvgIcon fontSize="medium">
            <FilterIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Form
          canSubmit
          metadata={metadata}
          defaultValues={values}
          columnSize={columnSize}
          onSubmit={handleSubmit}
          buttonConfig={buttonConfig}
        />
      </Popover>
    </>
  );
};
