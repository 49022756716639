import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: mapStopListActions } = createSlice({
  name: 'mapStopList',
  initialState: {
    data: null,
    isLoading: false,
    errorMessage: '',
  },
  reducers: {
    getMapStopList: (state) => {
      state.isLoading = true;
    },
    getMapStopListSuccess: (state, { payload: { data } }) => {
      state.data = data;
      state.isLoading = false;
      state.errorMessage = '';
    },
    setErrorMessage: (state, { payload: { data } }) => {
      state.errorMessage = data;
      state.isLoading = false;
    },
  },
});

export const mapStopListSelector = (state) => state.mapStopList;
