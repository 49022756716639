import React, { useContext } from 'react';
import { Button, Checkbox, makeStyles } from '@material-ui/core';
import { SelectionContext } from '../../context/SelectionContext';
import { XSquare } from 'react-feather';

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: 10,
    height: 30,
    borderRadius: 7,
    padding: '0 13px',
  },
}));

export const ToolbarSelection = ({
  onSelectAll,
  children,
  selectionButtonConfig = [],
}) => {
  const classes = useStyles();
  const { selection, onSelectionChange } = useContext(SelectionContext);
  const hasSelected = !!selection.length;

  const handleSelectAll = () => {
    if (selection.length) {
      onSelectionChange([]);
      onSelectAll(true);
    } else {
      onSelectAll();
    }
  };

  return (
    <div className="df aic">
      {(onSelectAll || !!selection.length) && (
        <Checkbox
          color="primary"
          checkedIcon={<XSquare fill="#21A04C" stroke="#fff" size={26} />}
          checked={!!selection.length}
          onChange={handleSelectAll}
        />
      )}
      {hasSelected && `${selection.length} selected`}
      {children}
      {hasSelected &&
        selectionButtonConfig.map((button) => (
          <Button
            key={button.text}
            color={button.color}
            variant={button.variant}
            onClick={() => button.onClick(selection)}
            disabled={button.disabled}
            className={classes.button}
          >
            {button.text}
          </Button>
        ))}
    </div>
  );
};
