import { all, put, takeEvery, select } from 'redux-saga/effects';
import {
  ordersInfoActions,
  ordersInfoSelector,
} from '../../slices/orders/ordersInfo';
import { commonRequest } from '../apiSaga';
import history from '../../../services/history';
import { get } from '@vanongo/utils';
import { toast } from '@vanongo/components/toast/SnackbarSingleton';
import { localization } from '../../../services/localization';
import { updateAddressSaga } from './updateAddressSaga';
import { updateContactPersonInfo } from './updateContactPersonInfo';
import { NAMESPACES } from '../../../constants/namespaces';
import { CUSTOMER, PARTNER } from '../../../constants/options/userTypes';
import { api } from '../../../services/api';
import { popupActions } from '../../slices/popup';
import { commonErrorToast } from '@vanongo/components';

const prepareAddress = ({
  city,
  zip,
  title,
  street,
  number,
  details,
  position = {},
  elevator,
  floor,
}) => {
  const { lat, lng } = position;
  let positionString;
  if (lat && lng) {
    positionString = `(${lng},${lat})`;
  }

  return {
    city,
    elevator,
    zip,
    title,
    floor,
    street,
    number,
    details,
    position: positionString,
  };
};

const getOrdersInfoSaga = function* ({ payload }) {
  const { data } = yield select(ordersInfoSelector);
  const prevOrderId = get(data, 'orderItemDTO.id');
  if (payload === prevOrderId) {
    yield put(ordersInfoActions.getOrdersInfoSuccess(data));
    return;
  }

  try {
    const response = yield commonRequest('getOneOrder', {
      parameters: { orderId: payload },
    });
    response.userType = response.organizationDTO ? PARTNER : CUSTOMER;

    yield put(ordersInfoActions.getOrdersInfoSuccess(response));
  } catch (e) {
    yield put(ordersInfoActions.getOrdersInfoError());
  }
};

const updateAddressDeliveryInfoSaga = function* ({
  payload: { data, addressId, orderId },
}) {
  try {
    const address = prepareAddress(data);
    yield updateAddressSaga({ address, addressId });

    yield put(
      ordersInfoActions.updateAddressDeliveryInfoSuccess({
        ...address,
        validated: true,
      }),
    );
    history.push(`/orders/${orderId}`);
  } catch (e) {
    console.log(e);
    yield put(ordersInfoActions.updateOrdersInfoFailed());
  }
};

const updatePickUpAddressSaga = function* ({
  payload: { data, addressId, orderId },
}) {
  try {
    const address = prepareAddress(data);
    yield updateAddressSaga({ address, addressId });

    yield put(
      ordersInfoActions.updateOrderItemPickUpAddressSuccess({
        ...address,
        validated: true,
      }),
    );
    history.push(`/orders/${orderId}`);
  } catch (e) {
    console.log(e);
    yield put(ordersInfoActions.updateOrdersInfoFailed());
  }
};

const updateOrderItemDeliveryPersonInfoSaga = function* ({
  payload: { data, personId, orderId },
}) {
  try {
    const person = data;
    yield updateContactPersonInfo({ person, personId });

    yield put(
      ordersInfoActions.updateOrderItemDeliveryPersonInfoSuccess(person),
    );
    history.push(`/orders/${orderId}`);
  } catch (e) {
    console.log(e);
    yield put(ordersInfoActions.updateOrdersInfoFailed());
  }
};

const updateOrderItemPickUpPersonInfoSaga = function* ({
  payload: { data, personId, orderId },
}) {
  try {
    const person = data;
    yield updateContactPersonInfo({ person, personId });

    yield put(ordersInfoActions.updateOrderItemPickUpPersonInfoSuccess(person));
    history.push(`/orders/${orderId}`);
  } catch (e) {
    console.log(e);
    yield put(ordersInfoActions.updateOrdersInfoFailed());
  }
};

const changeStatusSaga = function* ({ payload: { id, comment, status } }) {
  try {
    yield commonRequest('updateStatusOrder', {
      body: {
        status,
        reason: comment,
        order_items: [id],
      },
    });

    toast(
      localization.t('Order status changed successfully', {
        ns: NAMESPACES.ORDERS,
      }),
      {
        variant: 'success',
      },
    );

    yield put(ordersInfoActions.changeStatusSuccess({ status }));
  } catch (e) {
    console.log(e);
  }
};

const updateOrderItemSaga = function* ({ payload: { attributes, id } }) {
  try {
    yield commonRequest('updateOrderItem', {
      body: {
        attributes,
      },
      parameters: {
        orderItemId: id,
      },
    });

    toast(
      localization.t('Order item attributes changed successfully', {
        ns: NAMESPACES.ORDERS,
      }),
      {
        variant: 'success',
      },
    );
    yield put(ordersInfoActions.updateOrderItemSuccess({ attributes }));
  } catch (e) {
    console.log(e);
  }
};

const cloneOrderItemSaga = function* ({ payload: orderItemId }) {
  try {
    const response = yield api.post(
      `${process.env.REACT_APP_ADMIN_URL}orders/${orderItemId}/clone`,
    );

    toast(
      localization.t('Order successfully cloned', {
        ns: NAMESPACES.ORDERS,
      }),
      {
        variant: 'success',
      },
    );

    history.push(`/orders/${response.orderItemId}`);
  } catch (e) {
    console.log(e);
  }
};

const createTtnSaga = function* ({ payload: orderItemId }) {
  try {
    const response = yield commonRequest('getTTN', {
      parameters: {
        orderItemId,
      },
    });

    yield put(ordersInfoActions.createTtnSuccess());
    window.open(response.status.message);
  } catch (e) {
    console.log(e);
    yield put(ordersInfoActions.createTtnSuccess());
  }
};

const updatePickupDeliveryPeriodsSaga = function* ({
  payload: { data, orderId },
}) {
  try {
    const response = yield api.patch(
      `${process.env.REACT_APP_ADMIN_URL}orders/${orderId}/`,
      {
        body: {
          pickupDatetimePeriod: {
            startDatetime: data.pickupDatetimePeriod.from,
            endDatetime: data.pickupDatetimePeriod.to,
          },
          deliveryDatetimePeriod: {
            startDatetime: data.requestedDeliveryDatetimePeriod.from,
            endDatetime: data.requestedDeliveryDatetimePeriod.to,
          },
        },
      },
    );

    yield put(
      ordersInfoActions.updatePickupDeliverySuccess({
        data: response,
      }),
    );

    yield put(popupActions.closePopup());

    yield put(ordersInfoActions.getOrdersInfo(orderId));
  } catch (e) {
    console.log(e);
  }
};

const updateNoteSaga = function* ({ payload: { note, orderId } }) {
  try {
    const response = yield api.patch(
      `${process.env.REACT_APP_ADMIN_URL}orders/${orderId}/`,
      {
        body: {
          comments: note,
        },
      },
    );

    yield put(
      ordersInfoActions.updateNoteSuccess({
        data: response,
      }),
    );

    yield put(ordersInfoActions.getOrdersInfo(orderId));
  } catch (e) {
    toast(localization.t('Something went wrong'), commonErrorToast());
  }
};

export const ordersInfoSaga = function* () {
  yield all([
    takeEvery(ordersInfoActions.getOrdersInfo.type, getOrdersInfoSaga),
    takeEvery(
      ordersInfoActions.updateAddressDeliveryInfo.type,
      updateAddressDeliveryInfoSaga,
    ),
    takeEvery(
      ordersInfoActions.updateOrderItemPickUpAddress.type,
      updatePickUpAddressSaga,
    ),
    takeEvery(
      ordersInfoActions.updateOrderItemDeliveryPersonInfo.type,
      updateOrderItemDeliveryPersonInfoSaga,
    ),
    takeEvery(
      ordersInfoActions.updateOrderItemPickUpPersonInfo.type,
      updateOrderItemPickUpPersonInfoSaga,
    ),
    takeEvery(ordersInfoActions.changeStatus.type, changeStatusSaga),
    takeEvery(ordersInfoActions.updateOrderItem.type, updateOrderItemSaga),
    takeEvery(ordersInfoActions.createTtn.type, createTtnSaga),
    takeEvery(ordersInfoActions.cloneOrderItem.type, cloneOrderItemSaga),
    takeEvery(
      ordersInfoActions.updatePickupDeliveryPeriods.type,
      updatePickupDeliveryPeriodsSaga,
    ),
    takeEvery(ordersInfoActions.updateNote.type, updateNoteSaga),
  ]);
};
