import React from 'react';
import { Typography } from '@material-ui/core';

export const RegistrationStatusRender = ({ registrationStatus }) => {
  return (
    <div>
      <Typography variant="body2" color="inherit">
        {(registrationStatus === 'FINISHED').toString()}
      </Typography>
    </div>
  );
};
