import React, { useMemo, useRef } from 'react';
import {
  Box,
  Button,
  Container,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Breadcrumbs } from '../Breadcrumbs';
import { MapContainer } from '../Map/MapContainer';
import {
  GOOGLE_MAP_KEY,
  MAP_DEFAULT_CENTER,
  MAP_DEFAULT_ZOOM,
} from '../../webAdmin/src/constants/app';
import GoogleLayer from 'react-leaflet-google-layer';
import { ROADMAP } from '../../webAdmin/src/constants/googleMapTypes';
import { GeoMapPolygons } from '../Map/GeoMapPolygons';
import { DefaultLayoutPage } from '../DefaultLayoutPage';
import LoadingScreen from '../LoadingScreen';
import { getCoordinatesOfPolygon } from '@vanongo/utils';
import L from 'leaflet';
import { BorderLine } from '../Map/BorderLine';

const useStyles = makeStyles(() => ({
  map: {
    height: 'calc(100vh - 250px)',
    width: '100%',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const EditMapWrapper = ({
  name,
  operationArea,
  title,
  breadcrumbsOptions,
  actionsButtonConfig,
  borderOperationArea,
  isLoading,
}) => {
  const classes = useStyles();
  const mapRef = useRef(null);

  const center = useMemo(() => {
    const operationAreaCoordinates = getCoordinatesOfPolygon(
      borderOperationArea || operationArea,
    );
    const polygon =
      operationAreaCoordinates.length && L.polygon(operationAreaCoordinates);

    return polygon ? polygon.getBounds().getCenter() : MAP_DEFAULT_CENTER;
  }, [borderOperationArea, operationArea]);

  const areaLineCoordinates = useMemo(() => {
    if (!borderOperationArea) {
      return;
    }
    return getCoordinatesOfPolygon(borderOperationArea);
  }, [borderOperationArea]);

  const zoom = center ? 11 : MAP_DEFAULT_ZOOM;

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <DefaultLayoutPage title={title}>
      <Container maxWidth={false}>
        <Box mb={2}>
          <Breadcrumbs data={breadcrumbsOptions} />
          <Typography variant="h3" color="textPrimary">
            {name}
          </Typography>
        </Box>
        <MapContainer
          ref={mapRef}
          className={classes.map}
          center={center}
          zoom={zoom}
        >
          <GoogleLayer apiKey={GOOGLE_MAP_KEY} type={ROADMAP} />
          <GeoMapPolygons initialData={operationArea} zoom={zoom} isEditMode />
          {areaLineCoordinates && (
            <BorderLine borderLineCoordinates={areaLineCoordinates} />
          )}
        </MapContainer>

        <Box mt={2} className={classes.button}>
          {actionsButtonConfig.map((item) => (
            <Button
              key={item.name}
              style={item.style}
              color={item.color}
              variant={item.variant}
              disabled={item.isUpdating}
              onClick={() => item.onClick(mapRef)}
            >
              {item.name}
            </Button>
          ))}
        </Box>
      </Container>
    </DefaultLayoutPage>
  );
};

export default EditMapWrapper;
