import React, { useContext } from 'react';
import { Checkbox, TableCell as MaterialTableCell } from '@material-ui/core';
import clsx from 'clsx';
import { SelectionContext } from '../context/SelectionContext';

export const SelectionRender = ({ rowData, classes, rowKey }) => {
  const { selection, onSelectionChange } = useContext(SelectionContext);

  return (
    <MaterialTableCell
      component="div"
      className={clsx(classes.tableCell, classes.flexContainer)}
      variant="body"
      style={{ height: '100%', padding: 0 }}
      align="left"
    >
      <Checkbox
        color="primary"
        checked={!!selection.find((e) => rowData[rowKey] === e[rowKey])}
        onChange={onSelectionChange.bind(null, rowData)}
        style={{
          width: 30,
        }}
      />
    </MaterialTableCell>
  );
};
