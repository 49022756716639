import { createQueryParams } from './createQueryParams';

const EventEmitter = require('events');

export class HttpClient extends EventEmitter {
  constructor(getAuthToken, commonErrorHandling) {
    super();
    if (!getAuthToken) {
      throw new Error('getAuthToken function should be defined');
    }
    this.getAuthToken = getAuthToken;
    this.commonErrorHandling = commonErrorHandling;

    for (const method of ['get', 'post', 'put', 'patch', 'delete']) {
      this[method] = (url, { body, headers, query, ...props } = {}) =>
        this.request(url, {
          method: method.toUpperCase(),
          body,
          headers,
          query,
          ...props,
        });
    }
  }

  async request(url, args = {}) {
    if (!url || !url.length) {
      throw new Error('Url is not defined');
    }

    const { json = true, query, ...options } = args;

    options.headers = {
      ...(options.headers || {}),
    };

    const token = this.getAuthToken();
    if (token && !args.withoutToken) {
      options.headers['Authorization'] = token;
    }

    if (json) {
      options.headers['Content-Type'] = 'application/json';
    }
    if (json && options.body) {
      options.body = JSON.stringify(options.body);
    }
    let urlEndpoint = url;
    if (query) {
      const isQueryExist = url.indexOf('?') > 0;
      if (!isQueryExist) {
        urlEndpoint = url + createQueryParams(query);
      } else {
        urlEndpoint = url + createQueryParams(query).slice(1)
      }
    }

    try {
      const response = await fetch(urlEndpoint, options);

      const payload = {
        url: urlEndpoint,
        response: {
          status: response.status,
          statusText: response.statusText,
        },
      };

      const contentType = response.headers.get('content-type');
      const responseIsJson =
        contentType && contentType.includes('application/json');

      if (response.status < 200 || response.status > 300) {
        const method = responseIsJson ? 'json' : 'text';

        try {
          payload.response.body = await response[method]();

          if (this.commonErrorHandling && !args.disableErrorHandling) {
            this.commonErrorHandling(payload.response);
          }
        } catch (e) {
          if (this.commonErrorHandling && !args.disableErrorHandling) {
            this.commonErrorHandling(e);
          }
        }

        return Promise.reject(payload);
      }

      if (responseIsJson) {
        return response.json();
      }

      return response.text();
    } catch (e) {
      if (this.commonErrorHandling && !args.disableErrorHandling) {
        this.commonErrorHandling(e);
      }
      return Promise.reject(e);
    }
  }
}
