import { all, put, select, takeEvery } from 'redux-saga/effects';
import {
  waybillsInfoActions,
  waybillsInfoSelector,
} from '../../slices/waybills/waybillsInfo';
import { commonRequest } from '../apiSaga';
import history from '../../../services/history';

const getWaybillsInfoSaga = function* ({ payload }) {
  const { data } = yield select(waybillsInfoSelector);
  if (payload === data.id) {
    yield put(waybillsInfoActions.getWaybillsInfoSuccess(data));
    return;
  }

  try {
    const response = yield commonRequest('getWaybill', {
      parameters: { waybillId: payload },
    });
    yield put(waybillsInfoActions.getWaybillsInfoSuccess(response));
  } catch (e) {
    yield put(waybillsInfoActions.getWaybillsInfoSuccess({}));
  }
};

const updateWaybillsInfoSaga = function* ({ payload: { data, waybillId } }) {
  try {
    const response = yield commonRequest('updateWaybillDetails', {
      body: data,
      parameters: { waybillId },
    });

    yield put(waybillsInfoActions.updateWaybillsInfoSuccess(response));
    history.push(`/waybills/${waybillId}`);
  } catch (e) {
    yield put(waybillsInfoActions.updateWaybillsInfoFailed());
  }
};

const cancelWaybillSaga = function* ({ payload: { waybillId, status } }) {
  try {
    yield commonRequest('updateStatusOrder', {
      body: {
        status,
        waybill: waybillId,
      },
    });

    yield put(waybillsInfoActions.cancelWaybillSuccess());
  } catch (e) {
    console.error(e);
  }
};

const assignDriversSaga = function* ({ payload: { waybillId, drivers } }) {
  try {
    const response = yield commonRequest('putWaybill', {
      body: drivers.map(({ id }) => id),
      parameters: {
        waybillId,
      },
    });

    yield put(waybillsInfoActions.assignDriversSuccess(response));
  } catch (e) {
    console.error(e);
  }
};

export const waybillsInfoSaga = function* () {
  yield all([
    takeEvery(waybillsInfoActions.getWaybillsInfo.type, getWaybillsInfoSaga),
    takeEvery(
      waybillsInfoActions.updateWaybillsInfo.type,
      updateWaybillsInfoSaga,
    ),
    takeEvery(waybillsInfoActions.cancelWaybill.type, cancelWaybillSaga),
    takeEvery(waybillsInfoActions.assignDrivers.type, assignDriversSaga),
  ]);
};
