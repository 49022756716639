import { HttpClient } from '@vanongo/utils';
import { get } from '@vanongo/utils';
import { toast } from '@vanongo/components/toast/SnackbarSingleton';
import { localization } from './localization';
import { commonErrorToast } from '@vanongo/components/toast/commonErrorToast';
import store from 'src/redux/store';
import {SIGN_OUT} from '../redux/slices/authorization';

export function commonError(response) {
  console.log(response)
  const status = get(response, 'status')


  if ([401, 403].includes(status)) {
    store.dispatch({ type: SIGN_OUT });
    return;
  }

  const errorMessage = get(response, 'body.errorMessage')

  if (errorMessage) {
    toast(errorMessage, commonErrorToast());
    return;
  }

  toast(localization.t('Something went wrong'), commonErrorToast());
}



export const api = new HttpClient(() => store.getState().authorization.token, commonError );
