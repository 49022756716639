import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: orderStatusHistoryActions } = createSlice({
  name: 'orderStatusHistory',
  initialState: {
    data: [],
    orderItemId: null,
    isLoading: false,
  },
  reducers: {
    getOrderStatusHistory: (state) => {
      state.isLoading = true;
    },
    getOrderStatusHistorySuccess: (
      state,
      { payload: { data, orderItemId } },
    ) => {
      state.isLoading = false;
      state.data = data;
      state.orderItemId = orderItemId;
    },
  },
});

export const orderStatusHistorySelector = (state) =>
  state.orders.orderStatusHistory;
