const CONTENT_TYPE = 'Content-Type';
const AUTHORIZATION = 'Authorization';

export const HTTP_HEADERS = {
  CONTENT_TYPE,
  AUTHORIZATION,
};

export const METHOD = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
};
