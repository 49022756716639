export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_LOCALISE_FOLDER = 'global';
export const DEFAULT_TRANSLATION_PATH = '/locales/{{lng}}/{{ns}}.json';

export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
export const OPEN_DATA_BOT_KEY = process.env.REACT_APP_OPEN_DATA_BOT_KEY;

export const MAP_DEFAULT_CENTER = [50.0055192, 21.9184156];
export const MAP_DEFAULT_ZOOM = 5;

export const B2B_API_ORDER_CREATE_URL = process.env.REACT_APP_B2B_API_ORDER_CREATE_URL;
export const B2B_AUTH_API_URL = process.env.REACT_APP_B2B_AUTH_API_URL;
