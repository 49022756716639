import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: cargoInfoActions } = createSlice({
  name: 'cargoInfo',
  initialState: {
    data: {},
    isLoading: false,
    isUpdating: false,
    isError: false,
  },
  reducers: {
    getCargoInfo: (state) => {
      state.isLoading = true;
    },
    getCargoInfoSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
    getCargoPutError: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    updateCargoInfoFailed: (state) => {
      state.isUpdating = false;
    },
    changeStatus: (state) => {},
    changeStatusSuccess: (state, { payload: { status } }) => {
      state.data = {
        ...state.data,
        status,
      };
    },
    updateCargoInfo: (state) => {},
    updateCargoInfoSuccess: (state, { payload }) => {
      state.data = {
        ...state.data,
        ...payload,
      };
    },
  },
});

export const cargoInfoSelector = (state) => state.cargo.cargoInfo;
