import { createSlice } from '@reduxjs/toolkit';
import { all, put, takeLatest } from 'redux-saga/effects';
import { defaultMemoize } from './defaultMemoize';

export function reduxHelper({
  actionName,
  name,
  promise,
  mapFunc,
  defaultValue = [],
  fetchOnce = true,
  reduxName = 'getters',
}) {
  const runPromise = fetchOnce ? defaultMemoize(promise) : promise;

  const { reducer, actions } = createSlice({
    name,
    initialState: {
      data: defaultValue,
      isLoading: false,
    },
    reducers: {
      [actionName]: (state) => {
        state.isLoading = true;
      },
      [`${actionName}Success`]: (state, { payload }) => {
        state.isLoading = false;
        state.data = payload;
      },
      [`${actionName}Failed`]: (state) => {
        state.isLoading = false;
        state.data = defaultValue;
      },
    },
  });

  const selector = (state) => state[reduxName][name];

  const actionSaga = function* ({ payload }) {
    try {
      let response = yield runPromise(payload);

      // TODO move mapFunc to fetchOnce memoize
      if (mapFunc) {
        response = mapFunc(response);
      }

      yield put(actions[`${actionName}Success`](response));
    } catch (e) {
      yield put(actions[`${actionName}Failed`]());
    }
  };

  const saga = function* () {
    yield all([takeLatest(actions[actionName].type, actionSaga)]);
  };

  return {
    reducer,
    actions,
    selector,
    saga,
  };
}
