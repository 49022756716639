import React from 'react';

import {useTranslation} from 'react-i18next';
import {Form} from '@vanongo/components/Form';
import {Box, makeStyles, SvgIcon, Typography} from "@material-ui/core";
import {Check, XCircle} from "react-feather";
import PopupLayout from "../PopupLayout/PopupLayout";
import { NAMESPACES } from '../../constants/namespaces';
import LoadingScreen from "@vanongo/components/LoadingScreen";
import {useDispatch, useSelector} from "react-redux";
import {ordersListActions, ordersListSelector} from "../../redux/slices/orders/ordersList";
import {partnersListBriefImportEnabledSelector} from "../../redux/getters/partnersListBriefImportEnabled";
import {toast} from "@vanongo/components";
import {localization} from "../../services/localization";

const useStyles = makeStyles(() => ({
  icon: {
    width: 60,
    height: 60,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  textContainer: {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
}));

export const ImportOrdersPopup = ({
  title,
  onSubmit,
  closePopup,
  disabled,
  defaultValues,
  metadata,
  validationSchema,
  submitButtonText,
  errors,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {t} = useTranslation(NAMESPACES.GLOBAL);
  const {data: partnersListInfo} = useSelector(partnersListBriefImportEnabledSelector);

  const {
    importOrdersType,
    error,
    isImportOrdersType,
    importOrdersInfo
  } = useSelector(ordersListSelector);

  const handleSubmit = (data) => {
    const index = partnersListInfo?.findIndex((item) => item.value === data.values.partner);

    if (partnersListInfo[index].label !== data.values.pass) {
      toast(
        localization.t('Password must be the same as the Partner\'s name'),
        { variant: 'error', style: { whiteSpace: 'pre-line' } }
      );

      return;
    }

    onSubmit(data);
  };

  let errorKeys = error?.errorContent && Object.keys(error?.errorContent);
  let errorMessage = "";

  if (errorKeys && errorKeys.length) {
    errorKeys.forEach((item) => {
      const errArr = error?.errorContent[item].map((item) => item.errorText);

      errArr.forEach((item, index) => {
        if (errArr.length -1 === index) {
          errorMessage = errorMessage + " " + item;
          return;
        }
        errorMessage = errorMessage + item + ', '
      })
    });
  }

  let successMessage = "";
  if (importOrdersInfo.imported || importOrdersInfo.duplicates) {
    successMessage = `Imported: ${importOrdersInfo.imported ? importOrdersInfo.imported : '0'}. Duplicates: ${importOrdersInfo.duplicates ? importOrdersInfo.duplicates : '0'}`
  }

  const formButtons = [
    {text: t('Close'), onClick: closePopup},
    {
      color: 'secondary',
      type: 'submit',
      disabled,
      variant: 'contained',
      text: submitButtonText || t('Save'),
    },
  ];

  const printButtons = [
    {text: t('Close'), onClick: () => {
      dispatch(ordersListActions.clearImportOrders())
      return closePopup();
      }}
  ];

  const contentMap = {
    0: (
      <Form
        canSubmit
        validationSchema={validationSchema}
        metadata={metadata}
        columnSize={1}
        onSubmit={handleSubmit}
        buttonConfig={formButtons}
        defaultValues={defaultValues}
        errors={errors}
      />
    ),
    1: (
      <>
        <Box className={classes.iconContainer}>
          <SvgIcon style={{color: 'green'}} className={classes.icon} fontSize="large">
            <Check />
          </SvgIcon>
        </Box>
        <Box mt={2} className={classes.textContainer}>
          <Typography
            align="center"
            gutterBottom
            variant="body2"
            color="textPrimary"
          >
            {successMessage || t('Orders Successfully Imported')}
          </Typography>
        </Box>
      </>
    ),
    2: (
      <>
        <Box className={classes.iconContainer}>
          <SvgIcon color="error" className={classes.icon} fontSize="large">
            <XCircle />
          </SvgIcon>
        </Box>
        <Box mt={2} className={classes.textContainer}>
          <Typography
            align="center"
            gutterBottom
            variant="body2"
            color="textPrimary"
          >
            {errorMessage || t('Bad Request')}
          </Typography>
        </Box>
      </>
    )
  };

  return (
    <PopupLayout
      width={650}
      title={title}
      buttonConfig={importOrdersType === 0 ? [] : printButtons}
      overflow="auto"
    >
      {isImportOrdersType ? (<LoadingScreen />) : contentMap[importOrdersType]}
    </PopupLayout>
  );
};
