import React from 'react';
import { makeStyles, Button as MaterialButton } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    '&:disabled': {
      backgroundColor: theme.palette.error.light,
    },
  },
}));

export const Button = ({ type = 'default', className, ...props }) => {
  const classes = useStyles();

  return (
    <MaterialButton className={clsx(className, classes[type])} {...props} />
  );
};
