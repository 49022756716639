import { all, put, takeLatest } from 'redux-saga/effects';
import { groupsAttributesListActions } from '../../slices/groups/attributes';
import { api } from '../../../services/api';
import history from '../../../services/history';
import { getValidationErrors } from '@vanongo/utils';

const getAttributesSaga = function* ({
  payload: { pageSize = 10, page = 1 } = {
    pageSize: 10,
    page: 1,
  },
}) {
  try {
    const response = yield api.get(
      `${process.env.REACT_APP_ADMIN_URL}attributes`,
      {
        query: {
          page,
          limit: pageSize,
        },
      },
    );

    yield put(
      groupsAttributesListActions.getGroupsAttributesListSuccess({
        data: response.results,
        page: response.currentPage,
        pageSize,
        totalCount: response.totalCount,
      }),
    );
  } catch (e) {
    console.log(e);
  }
};

const updateAttributesSaga = function* ({ payload: { data, id } }) {
  try {
    yield api.patch(`${process.env.REACT_APP_ADMIN_URL}attributes/${id}`, {
      body: data,
    });

    yield put(groupsAttributesListActions.updateAttributesSuccess());
    history.push('/gr/attributes');
  } catch (e) {
    console.log(e);
  }
};

const deleteAttributesSaga = function* ({ payload: selection }) {
  try {
    yield Promise.all(
      selection.map(({ id }) =>
        api.delete(`${process.env.REACT_APP_ADMIN_URL}attributes/${id}`),
      ),
    );

    yield put(groupsAttributesListActions.deleteAttributesSuccess());
  } catch (e) {}
};

const createSaga = function* ({ payload: data }) {
  try {
    yield api.post(`${process.env.REACT_APP_ADMIN_URL}attributes/`, {
      body: data,
    });

    yield put(groupsAttributesListActions.createSuccess());
    history.push(`/gr/attributes`);
  } catch (e) {
    console.log(e);
    yield put(groupsAttributesListActions.createFailed(getValidationErrors(e)));
  }
};

export const attributesSaga = function* () {
  yield all([
    takeLatest(
      groupsAttributesListActions.getGroupsAttributesList.type,
      getAttributesSaga,
    ),
    takeLatest(
      groupsAttributesListActions.updateAttributes.type,
      updateAttributesSaga,
    ),
    takeLatest(
      groupsAttributesListActions.deleteAttributes.type,
      deleteAttributesSaga,
    ),
    takeLatest(
      groupsAttributesListActions.deleteAttributesSuccess.type,
      getAttributesSaga,
    ),
    takeLatest(groupsAttributesListActions.create.type, createSaga),
  ]);
};
