import { object, string } from 'yup';
import { usePartnersListOptions } from '../../../hooks/options/usePartnersListOptions';
import { useServiceListBriefOptions } from '../../../hooks/options/useServiceListBriefOptions';

export const validationSchema = object().shape({
  organization: string().required('Partner is required'),
  service: string().required('Service type is required'),
});

export const step1Metadata = [
  {
    title: 'Organization',
    name: 'organization',
    type: 'select',
    effect: usePartnersListOptions,
  },
  {
    title: 'Service type',
    name: 'service',
    type: 'select',
    effect: useServiceListBriefOptions,
  },
];
