import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Divider, Button } from '@material-ui/core';

import { popupActions } from '../../redux/slices/popup';
import { ESC_KEY } from 'packages/webAdmin/src/constants/keyСodes';
import history from 'packages/webAdmin/src/services/history';
import { X as CloseIcon } from 'react-feather';
import { IconButton, makeStyles, SvgIcon } from '@material-ui/core';
import { Spinner } from '@vanongo/components/Spinner';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    maxHeight: 'calc(100vh - 68px)',
    margin: '0 auto',
    minWidth: '300px',
  },

  content: {
    width: '100%',
    height: '100%',
  },

  closeButton: {
    padding: 0,
    cursor: 'pointer',

    position: 'absolute',
    top: '19px',
    right: '20px',
  },

  button: {
    marginRight: theme.spacing(2),

    '&:last-child': {
      marginRight: 0,
    },
  },
}));

export const PopupLayout = ({
  hideCloseIcon = true,
  children,
  width = 600,
  offListeners,
  height,
  maxWidth,
  overflow,
  title,
  isFetching,
  buttonConfig = [],
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClose = useCallback(
    () => dispatch(popupActions.closePopup()),
    [dispatch],
  );

  useEffect(() => {
    if (offListeners) {
      return;
    }
    const handleEsc = (event) => {
      if (event.keyCode === ESC_KEY) {
        event.stopPropagation();
        handleClose();
      }
    };
    document.addEventListener('keyup', handleEsc, true);

    const historyListener = history.listen(() => {
      handleClose();
    });

    return () => {
      document.removeEventListener('keyup', handleEsc, true);
      historyListener && historyListener();
    };
  }, [handleClose, offListeners]);

  return (
    <Box
      className={classes.wrapper}
      style={{ width, height, maxWidth, overflow }}
    >
      {title && (
        <Box p={3} pb={0}>
          <Typography
            align="center"
            gutterBottom
            variant="h3"
            color="textPrimary"
          >
            {title}
          </Typography>
        </Box>
      )}
      {!hideCloseIcon && (
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <SvgIcon fontSize="small">
            <CloseIcon />
          </SvgIcon>
        </IconButton>
      )}

      <Box p={3} pt={4} pb={4} className={classes.content}>
        {isFetching ? <Spinner /> : children}
      </Box>
      {!!buttonConfig.length && (
        <>
          <Divider />
          <Box
            p={2}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            {buttonConfig.map((button) => (
              <Button
                key={button.text}
                color={button.color}
                type={button.type}
                variant={button.variant}
                onClick={button.onClick}
                className={classes.button}
                disabled={
                  button.disabled || (button.type === 'submit' && isFetching)
                }
              >
                {button.text}
              </Button>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default PopupLayout;
