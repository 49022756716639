import React, { useMemo } from 'react';
import Page from '../Page';
import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  makeStyles,
  Tab,
  Tabs,
} from '@material-ui/core';
import { Table } from '../Table';
import { FilterQueryPanel } from '../Filter/FilterQueryPanel';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 23,
  },
  table: {
    maxWidth: '100%',
    height: 'calc(100vh - 240px)',
  },
  buttonContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    paddingRight: 10,
  },
}));

export const TablePage = ({
  pageTitle,
  title,
  actions: Actions,
  tabs,
  isLoading,
  data,
  totalCount,
  filterMetadata,
  filterMetadataOnTheFly,
  filterValuesMapper,
  queryMapper,
  queryBackMapper,
  page,
  pageSize,
  onChangePage,
  onChangeRowsPerPage,
  selection,
  onSelectionChange,
  onSelectAll,
  selectionButtonConfig,
  columns,
  onRowClick,
  onRefresh,
  rowSize,
  enableRowSelection,
  rowKey,
  sorting,
  onChangeSorting,
  enableResizing,
  enableReordering,
  rowsPerPageOptions,
  tableName,
  aggregation,
  hasTabs,
  customHandleTabChange,
  defaultTab = 'all',
  tabRightRender: TabRightRender,
}) => {
  const classes = useStyles();
  const { search: queryString } = useLocation();

  const history = useHistory();
  const handleTabsChange = (event, value) => {
    if (customHandleTabChange) {
      return customHandleTabChange(value);
    }

    const query = new URLSearchParams(queryString);
    if (tabs.length > 1) {
      if (query.get('tab')) {
        query.set('tab', value);
        history.push('?' + query.toString());
      } else {
        history.push(queryString + `${queryString ? '&' : '?'}tab=${value}`);
      }
    }
  };

  const currentTab = useMemo(() => {
    const query = new URLSearchParams(queryString);
    return query.get('tab') || defaultTab;
  }, [queryString]);

  return (
    <Page className={classes.root} title={pageTitle}>
      <Container maxWidth={false}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item className="df aic">
            <FilterQueryPanel
              metadata={filterMetadata}
              title={title}
              metadataOnTheFly={filterMetadataOnTheFly}
              filterValuesMapper={filterValuesMapper}
              queryMapper={queryMapper}
              queryBackMapper={queryBackMapper}
              hasTabs={hasTabs}
            />
          </Grid>
          {Actions && (
            <Grid item>
              <Actions />
            </Grid>
          )}
        </Grid>
        <Box mt={3}>
          <Card style={{ position: 'relative' }}>
            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="secondary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} value={tab.value} label={tab.label} />
              ))}
            </Tabs>
            {TabRightRender && (
              <Box className={classes.buttonContainer}>
                <TabRightRender />
              </Box>
            )}

            <Divider />
            <Box className={classes.table}>
              <Table
                rowCount={totalCount}
                rowKey={rowKey}
                data={data}
                isLoading={isLoading}
                page={page}
                pageSize={pageSize}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                selection={selection}
                onSelectionChange={onSelectionChange}
                onSelectAll={onSelectAll}
                selectionButtonConfig={selectionButtonConfig}
                columns={columns}
                onRowClick={onRowClick}
                sorting={sorting}
                onRefresh={onRefresh}
                rowSize={rowSize}
                onChangeSorting={onChangeSorting}
                enableRowSelection={enableRowSelection}
                enableResizing={enableResizing}
                enableReordering={enableReordering}
                rowsPerPageOptions={rowsPerPageOptions}
                tableName={tableName}
                aggregation={aggregation}
              />
            </Box>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};
