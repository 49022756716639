export const driversVehiclesTableMetadata = [
  {
    title: 'Driver name',
    field: 'name',
    render: ({ firstName, lastName }) => `${firstName} ${lastName}`,
  },
  {
    title: 'License plate',
    field: 'licensePlate',
    width: 180,
  },
  {
    title: 'Make Model',
    field: 'model',
    render: ({ make, model }) => `${make} ${model}`,
  },
  {
    title: 'Vehicle size',
    field: 'vehicleSize',
    width: 150,
  },
];
