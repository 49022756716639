import React from 'react';
import { useAreaOptions } from '../hooks/options/useAreaOptions';
import { CircularProgress } from '@material-ui/core';

export const AreaRender = ({ areaId }) => {
  const { options, isLoading } = useAreaOptions();

  if (isLoading) return <CircularProgress size={20} />;
  const area = options.find((el) => el.value === +areaId);
  if (!area) return areaId;
  return area.label;
};
