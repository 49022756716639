import React, { forwardRef, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@material-ui/core';
import { FormMapper, defaultValueMapper } from './FormMapper';
import { yupResolver } from '@hookform/resolvers/yup';

const columnSizeMap = {
  1: { maxWidth: 600, sm: 12 },
  2: { maxWidth: 1000, md: 6 },
  3: { maxWidth: 800, md: 12 },
};

export const defaultButtonConfig = [
  {
    color: 'secondary',
    type: 'submit',
    variant: 'contained',
    text: 'Save',
  },
];

export const Form = forwardRef(
  (
    {
      metadata,
      validationSchema,
      defaultValues = {},
      onSubmit,
      title,
      canSubmit,
      columnSize = 2,
      buttonConfig = defaultButtonConfig,
      leftButtonConfig = [],
      controller = {},
      backgroundColor,
      errors: errorsFromBe,
    },
    ref,
  ) => {
    const {
      handleSubmit,
      control,
      trigger,
      getValues,
      setValue,
      setError,
      watch,
    } = useForm({
      resolver: validationSchema && yupResolver(validationSchema),
      defaultValues,
    });

    if (ref) {
      ref.current = {
        trigger,
        getValues,
        setValue,
        control,
        handleSubmit,
      };
    }

    useEffect(() => {
      if (!errorsFromBe) return;
      Object.entries(errorsFromBe).forEach(([field, message]) => {
        setError(field, { message });
      });
    }, [errorsFromBe, setError]);

    const defaultMd = columnSizeMap[columnSize].md;
    const defaultSm = columnSizeMap[columnSize].sm;
    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ maxWidth: columnSizeMap[columnSize].maxWidth }}
      >
        <Card
          style={{
            backgroundColor,
          }}
        >
          {title && (
            <>
              <CardHeader title={title} />
              <Divider />
            </>
          )}

          <CardContent>
            <Grid container spacing={4}>
              {metadata.map((item) =>
                item.isHidden ? null : (
                  <Grid
                    item
                    sm={item.sm || defaultSm}
                    md={item.md || defaultMd}
                    xs={12}
                    key={item.name}
                  >
                    {item.type === 'custom' ? (
                      item.component({
                        ...item,
                        control,
                        watch,
                        setError,
                      })
                    ) : (
                      <Controller
                        control={control}
                        name={item.name}
                        defaultValue={
                          defaultValues[item.name] ||
                          (defaultValues[item.name] === 0
                            ? defaultValues[item.name]
                            : defaultValueMapper[item.type])
                        }
                        render={({
                          field: { value, onChange, onBlur },
                          fieldState: { error },
                        }) =>
                          React.createElement(FormMapper[item.type], {
                            ...item,
                            value,
                            onChange,
                            onBlur,
                            error,
                          })
                        }
                      />
                    )}
                  </Grid>
                ),
              )}
            </Grid>
          </CardContent>
          {!!buttonConfig.length && <Divider />}
          <Box className="df aic jcsb">
            <Box>
              {leftButtonConfig.map((button) => (
                <Button
                  key={button.text}
                  color={button.color}
                  type={button.type}
                  variant={button.variant}
                  onClick={button.onClick}
                  disabled={button.disabled}
                  style={{ marginLeft: 10 }}
                >
                  {button.text}
                </Button>
              ))}
            </Box>
            <Box>
              {!!buttonConfig.length && (
                <Box p={2} display="flex" justifyContent="flex-end">
                  {buttonConfig.map((button) => (
                    <Button
                      key={button.text}
                      color={button.color}
                      type={button.type}
                      variant={button.variant}
                      onClick={button.onClick}
                      disabled={
                        button.disabled ||
                        (button.type === 'submit' && !canSubmit)
                      }
                      style={{ marginLeft: 10 }}
                    >
                      {button.text}
                    </Button>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </Card>
      </form>
    );
  },
);
