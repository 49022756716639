import { Select } from '../formComponents/Select/Select';
import { FormSwitch } from '../formComponents/Boolean/Switch';
import { Time } from '../formComponents/Date/Time';
import { DatePicker } from '../formComponents/Date/Date';
import { FormCheckbox } from '../formComponents/Boolean/Checkbox';
import { Number } from '../formComponents/Input/Number';
import { Multiselect } from '../formComponents/Select/Multiselect';
import { MapPoint } from '../formComponents/MapPoint';
import { SelectDateRange } from '../formComponents/Date/SelectDateRange';
import { UploadFile, Input } from '..';

export const defaultValueMapper = {
  string: '',
  number: '',
  select: null,
  multiselect: [],
  switch: false,
  checkbox: false,
  time: null,
  date: null,
  mapPoint: { lat: '', lng: '' },
  selectDateRange: {},
  uploadFile: [],
};

export const FormMapper = {
  string: Input,
  number: Number,
  select: Select,
  switch: FormSwitch,
  time: Time,
  multiselect: Multiselect,
  checkbox: FormCheckbox,
  date: DatePicker,
  mapPoint: MapPoint,
  selectDateRange: SelectDateRange,
  uploadFile: UploadFile,
};
