import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { CustomInput } from './CustomInput';
import { Select } from '../Select/Select';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../../webAdmin/src/constants/namespaces';
import { makeStyles } from '@material-ui/core';
import { FormCheckbox } from '../Boolean/Checkbox';

const useStyles = makeStyles(() => ({
  selectWrapper: {
    width: '50%',
    paddingRight: 16,
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 20,

    '&>div': {
      width: '50%',
      paddingRight: 16,

      '& + div': {
        paddingLeft: 16,
        paddingRight: 0,
      },
    },

    '& .react-datepicker__tab-loop': {
      width: 0,
      padding: 0,
    },
  },
  dateContainerWithOptions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: 30,

    '&>div': {
      minWidth: 269
    },

    '& .react-datepicker__tab-loop': {
      minWidth: 0,
      width: 0,
      padding: 0,
    },
  },
}));

export const SelectDateRange = ({ title, value, onChange, error, options, titleFrom, titleTo }) => {
  const { t } = useTranslation(NAMESPACES.UI_KIT);
  const classes = useStyles();
  const option = options.find((el) => el.value === value.type);
  const hasExact = option && option.hasExact;

  const handleChangeFrom = (dateValue) => {
    onChange({ ...value, from: dateValue });
  };
  const handleChangeSelect = (selectValue) => {
    onChange({
      ...value,
      type: selectValue,
    });
  };

  const handleChangeTo = (dateValue) => {
    onChange({ ...value, to: dateValue });
  };

  const handleChangeExact = (exact) => {
    onChange({ ...value, exact: +exact });
  };

  return (
    <div>
      {options.length ? (
        <div className="df">
          <div className={classes.selectWrapper}>
            <Select
              options={options}
              value={value.type}
              onChange={handleChangeSelect}
              title={t('Date of')}
            />
          </div>

          {hasExact && (
            <FormCheckbox
              value={!!value.exact}
              title={t('Exact')}
              onChange={handleChangeExact}
            />
          )}
        </div>
      ) : ''}

      <div className={options.length ? classes.dateContainer : classes.dateContainerWithOptions }>
        <ReactDatePicker
          className={'From'}
          selected={value.from}
          onChange={handleChangeFrom}
          showTimeSelect
          timeIntervals={15}
          timeCaption="Time"
          title={titleFrom || t('From')}
          isClearable
          dateFormat="dd.MM.yy HH:mm"
          customInput={<CustomInput addOnChange error={error} />}
        />

        <ReactDatePicker
          selected={value.to}
          onChange={handleChangeTo}
          showTimeSelect
          timeIntervals={15}
          timeCaption="Time"
          title={titleTo || t('To')}
          isClearable
          dateFormat="dd.MM.yy HH:mm"
          customInput={<CustomInput addOnChange />}
        />
      </div>
    </div>
  );
};
