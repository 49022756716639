import { all, takeLatest, put, select } from 'redux-saga/effects';
import { partnersListActions } from '../../slices/partners/partnersList';
import { commonRequest } from '../apiSaga';
import { areasFilterValueSelector } from '../../slices/global/areasFilter';
import { getObjFromQuery } from '@vanongo/utils';

const createFilters = (filters) => {
  if (!filters) {
    return {};
  }
  if (!Object.keys(filters).length) {
    return {};
  }

  return {
    space: filters.space,
  };
};

const getPartnersListSaga = function* ({
  payload: { pageSize = 10, page = 1, params, queryString } = {
    pageSize: 10,
    page: 1,
  },
}) {
  const area = yield select(areasFilterValueSelector);
  const filtersValue = params || getObjFromQuery(queryString);

  try {
    const response = yield commonRequest('getPartners', {
      parameters: {
        page,
        limit: pageSize,
        area,
        ...createFilters(filtersValue),
      },
    });

    yield put(
      partnersListActions.getPartnersListSuccess({
        data: response.results,
        page: response.currentPage,
        pageSize,
        totalCount: response.totalCount,
      }),
    );
  } catch (e) {}
};

export const partnersListSaga = function* () {
  yield all([
    takeLatest(partnersListActions.getPartnersList.type, getPartnersListSaga),
  ]);
};
