import { reduxHelper } from '@vanongo/utils';
import { api } from '../../services/api';

export const {
  actions: partnersListBriefImportEnabledActions,
  reducer: partnersListBriefImportEnabled,
  selector: partnersListBriefImportEnabledSelector,
  saga: partnersListBriefImportEnabledSaga,
} = reduxHelper({
  actionName: 'getList',
  name: 'partnersListBriefImportEnabled',
  defaultValue: [],
  promise: (body) =>
    api.get(
      `${process.env.REACT_APP_ADMIN_URL}partners/list-brief?importEnabled=true`,
      { body },
    ),
  mapFunc: (arr) =>
    arr.map((el) => ({
      label: el.name,
      value: el.id,
    })),
  fetchOnce: false,
});
