import { all, put, takeEvery, select } from 'redux-saga/effects';
import {
  driversVehiclesActions,
  driversVehiclesSelector,
} from '../../slices/drivers/driversVehicles';
import { commonRequest } from '../apiSaga';

const getDriversVehiclesSaga = function* ({ payload }) {
  try {
    const response = yield commonRequest('getVehicles', {
      parameters: { driverId: payload },
    });
    yield put(
      driversVehiclesActions.getDriversVehiclesSuccess(response.results),
    );
  } catch (e) {}
};

const changeVehicleOptionsSaga = function* ({ payload: { value, areaId } }) {
  try {
    const response = yield commonRequest('getVehicles', {
      parameters: { licensePlate: value, area: areaId },
    });

    const { data } = yield select(driversVehiclesSelector);

    yield put(
      driversVehiclesActions.receiveVehicleOptions(
        response.results.reduce((total, el) => {
          if (data.find((item) => item.id === el.id)) {
            return total;
          }

          total.push({
            item: el,
            value: el.id,
            label: `${el.licensePlate} ${el.make} ${el.model} ${el.vehicleType} ${el.vehicleSize}`,
          });

          return total;
        }, []),
      ),
    );
  } catch (e) {
    console.error(e);
    yield put(driversVehiclesActions.receiveVehicleOptions([]));
  }
};

const connectVehicleSaga = function* ({ payload: { driverId, vehicle } }) {
  try {
    yield commonRequest('connectVehicleDriver', {
      parameters: { driverId, vehicleId: vehicle.id },
    });

    yield put(driversVehiclesActions.connectVehicleSuccessfully(vehicle));
  } catch (e) {
    console.error(e);
  }
};

const disconnectVehicleSaga = function* ({ payload: { driverId, vehicleId } }) {
  try {
    yield commonRequest('disconnectVehicleDriver', {
      parameters: { driverId, vehicleId: vehicleId },
    });

    yield put(driversVehiclesActions.disconnectVehicleSuccessfully(vehicleId));
  } catch (e) {
    console.error(e);
  }
};

export const driversVehiclesSaga = function* () {
  yield all([
    takeEvery(
      driversVehiclesActions.getDriversVehicles.type,
      getDriversVehiclesSaga,
    ),
    takeEvery(
      driversVehiclesActions.getVehicleOptions.type,
      changeVehicleOptionsSaga,
    ),
    takeEvery(
      driversVehiclesActions.connectVehicleStart.type,
      connectVehicleSaga,
    ),
    takeEvery(
      driversVehiclesActions.disconnectVehicleStart.type,
      disconnectVehicleSaga,
    ),
  ]);
};
