import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  driversVehiclesSelector,
  driversVehiclesActions,
} from '../../redux/slices/drivers/driversVehicles';
import { debounce } from '@material-ui/core';

const getOptions = (dispatch, value, areaId) => {
  if (value.length < 2) {
    return;
  }
  dispatch(driversVehiclesActions.getVehicleOptions({ value, areaId }));
};
const debounceGetOptions = debounce(getOptions, 500);

export const useVehiclesOptionsByLicensePlate = (areaId) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState();
  const { vehicleOptions: options, vehicleOptionsIsLoading: isLoading } =
    useSelector(driversVehiclesSelector);
  const onInputChange = useCallback(
    (value) => {
      setInputValue(value);

      debounceGetOptions(dispatch, value, areaId);
    },
    [dispatch, areaId],
  );

  return {
    options,
    isLoading,
    inputValue,
    onInputChange,
  };
};
