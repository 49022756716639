import { all, takeLatest, put } from 'redux-saga/effects';
import { api } from '../../../services/api';
import { getValidationErrors } from '@vanongo/utils';
import { pricingModelListActions } from '../../slices/services/pricingModelList';
import { popupActions } from '../../slices/popup';

const getParamsArray = (data) => data.map((item) => item.value);

const getListSaga = function* ({
  payload: { pageSize = 10, page = 1 } = {
    pageSize: 10,
    page: 1,
  },
}) {
  try {
    const response = yield api.get(
      `${process.env.REACT_APP_ADMIN_URL}pricing-models`,
      {
        query: {
          page,
          limit: pageSize,
        },
      },
    );

    yield put(
      pricingModelListActions.getListSuccess({
        data: response.results,
        page: response.currentPage,
        pageSize,
        totalCount: response.totalCount,
      }),
    );
  } catch (e) {}
};

const createSaga = function* ({ payload: data }) {
  try {
    const response = yield api.post(
      `${process.env.REACT_APP_ADMIN_URL}pricing-models`,
      {
        body: { ...data, params: getParamsArray(data.params) },
      },
    );

    yield put(popupActions.closePopup());
    yield put(pricingModelListActions.createSuccess(response));
  } catch (e) {
    yield put(pricingModelListActions.createFailed(getValidationErrors(e)));
  }
};

const updateSaga = function* ({ payload: { data, id } }) {
  try {
    const response = yield api.patch(
      `${process.env.REACT_APP_ADMIN_URL}pricing-models/${id}`,
      {
        body: { ...data, params: getParamsArray(data.params) },
      },
    );

    yield put(popupActions.closePopup());
    yield put(pricingModelListActions.updateSuccess(response));
  } catch (e) {
    yield put(pricingModelListActions.updateFailed(getValidationErrors(e)));
  }
};

const deleteSaga = function* ({ payload: { selection } }) {
  try {
    yield Promise.all(
      selection.map((item) =>
        api.delete(
          `${process.env.REACT_APP_ADMIN_URL}pricing-models/${item.id}`,
        ),
      ),
    );

    yield put(popupActions.closePopup());
    yield put(pricingModelListActions.deleteSuccess());
  } catch (e) {
    yield put(pricingModelListActions.deleteFailed(getValidationErrors(e)));
  }
};

export const pricingModelListSaga = function* () {
  yield all([
    takeLatest(pricingModelListActions.getList.type, getListSaga),
    takeLatest(pricingModelListActions.create.type, createSaga),
    takeLatest(pricingModelListActions.update.type, updateSaga),
    takeLatest(pricingModelListActions.delete.type, deleteSaga),
    takeLatest(pricingModelListActions.deleteSuccess.type, getListSaga),
  ]);
};
