import React, { Suspense, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import LoadingScreen from '@vanongo/components/LoadingScreen';

export const Root = ({ route = [] }) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {route.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    <Root route={route.routes} />
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);
