import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: partnersListActions } = createSlice({
  name: 'partnersList',
  initialState: {
    partners: [],
    page: 0,
    totalCount: 0,
    pageSize: 10,
    isLoading: false,
  },
  reducers: {
    getPartnersList: (state) => {
      state.isLoading = true;
    },
    getPartnersListSuccess: (
      state,
      { payload: { data, page, totalCount, pageSize } },
    ) => {
      state.isLoading = false;
      state.partners = data;
      state.page = page - 1;
      state.totalCount = totalCount;
      state.pageSize = pageSize;
    },
  },
});

export const partnersListSelector = (state) => state.partners.partnersList;
