import React, { Suspense, useState } from 'react';
import PopupLayout from '../PopupLayout/PopupLayout';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import { Step1 } from './Step1/Step1';
import LoadingScreen from '@vanongo/components/LoadingScreen';
import { Step2 } from './Step2/Step2';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../constants/namespaces';

const steps = ['Step 1', 'Step 2'];
const prepareDataForStep1 = (data = {}) => ({
  organization: data.organization?.id,
  service: data.service?.id,
});

const prepareDataForStep2 = (data = {}) => ({
  ...data,
    currency: data.currency?.id,
    pricingPolygons: (data.pricingPolygons || []).map((el) => el.id),
  ...(data.paramsValues || {}),
});

export const B2bServiceFormPopup = ({
  closePopup,
  data,
  serviceId,
  startStep = 0,
}) => {
  const { t } = useTranslation(NAMESPACES.ORDERS);
  const [activeStep, setActiveStep] = useState({
    step: startStep,
    step1Data: prepareDataForStep1(data),
    step2Data: prepareDataForStep2(data),
    serviceId,
  });

  const handleNext = (newData = {}) => {
    setActiveStep((prevActiveStep) => ({
      ...prevActiveStep,
      step: prevActiveStep.step + 1,
      ...newData,
    }));
  };

  const handleBack = (newData = {}) => {
    setActiveStep((prevActiveStep) => ({
      ...prevActiveStep,
      step: prevActiveStep.step - 1,
      ...newData,
    }));
  };

  const contentMap = {
    0: (
      <Suspense fallback={<LoadingScreen />}>
        <Step1
          activeStep={activeStep}
          closePopup={closePopup}
          handleNext={handleNext}
        />
      </Suspense>
    ),
    1: (
      <Suspense fallback={<LoadingScreen />}>
        <Step2
          activeStep={activeStep}
          closePopup={closePopup}
          handleBack={handleBack}
          handleNext={handleNext}
        />
      </Suspense>
    ),
  };

  return (
    <PopupLayout width={700} title={t('Form service')}>
      <Stepper
        activeStep={activeStep.step}
        alternativeLabel
        style={{ padding: 12 }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{t(label)}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {contentMap[activeStep.step]}
    </PopupLayout>
  );
};
