import { reduxHelper } from '@vanongo/utils';
import { api } from '../../services/api';

export const {
  actions: deliveryTypeOptionsActions,
  reducer: deliveryTypeOptions,
  selector: deliveryTypeOptionsSelector,
  saga: deliveryTypeOptionsSaga,
} = reduxHelper({
  actionName: 'getDeliveryTypeOptions',
  name: 'deliveryTypeOptions',
  promise: () =>
    api.get(
      `${process.env.REACT_APP_ADMIN_URL}partners/delivery-type-options`,
    ),
});
