import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: areasListActions } = createSlice({
  name: 'areasList',
  initialState: {
    areas: [],
    isLoading: false,
  },
  reducers: {
    getAreasList: (state) => {
      state.isLoading = true;
    },
    getAreasListSuccess: (state, { payload: { data } }) => {
      state.isLoading = false;
      state.areas = data;
    },
  },
});

export const areasListSelector = (state) => state.areas.areasList;
