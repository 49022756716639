import { all, put, select, takeEvery } from 'redux-saga/effects';
import {
  driversInfoActions,
  driversInfoSelector,
} from '../../slices/drivers/driversInfo';
import { commonRequest } from '../apiSaga';
import history from '../../../services/history';
import { parsePointCoordinates, joinPointCoordinates } from '@vanongo/utils';

const mapResponse = (data) => {
  return {
    ...data,
    startLocation: parsePointCoordinates(data.startLocation),
    finishLocation: parsePointCoordinates(data.finishLocation),
  };
};

const prepareData = (data) => {
  return {
    ...data,
    startLocation: joinPointCoordinates(data.startLocation),
    finishLocation: joinPointCoordinates(data.finishLocation),
    driverAttributes: (data.driverAttributes || []).map((el) => el.valueId),
    attributes: data.attributes || [],
  };
};

const getDriversInfoSaga = function* ({ payload }) {
  const { data } = yield select(driversInfoSelector);
  if (payload === data.id) {
    yield put(driversInfoActions.getDriversInfoSuccess(data));
    return;
  }

  try {
    const response = yield commonRequest('getDriversProfile', {
      parameters: { driverId: payload },
    });
    yield put(driversInfoActions.getDriversInfoSuccess(mapResponse(response)));
  } catch (e) {
    console.error(e);
  }
};

const updateDriversInfoSaga = function* ({ payload: { data, driverId } }) {
  try {
    const response = yield commonRequest('updateDriverProfile', {
      body: prepareData(data),
      parameters: { driverId },
    });

    yield put(
      driversInfoActions.updateDriversInfoSuccess({
        id: driverId,
        ...mapResponse(response),
      }),
    );
    history.push(`/drivers/${driverId}`);
  } catch (e) {
    console.error(e);
    yield put(driversInfoActions.updateDriversInfoReject());
  }
};

const removeDriverSaga = function* ({ payload }) {
  try {
    yield commonRequest('deleteDriver', {
      parameters: { driverId: payload },
    });

    history.push('/drivers');
  } catch (e) {}
};

export const driversInfoSaga = function* () {
  yield all([
    takeEvery(driversInfoActions.getDriversInfo.type, getDriversInfoSaga),
    takeEvery(driversInfoActions.updateDriversInfo.type, updateDriversInfoSaga),
    takeEvery(driversInfoActions.removeDriver.type, removeDriverSaga),
  ]);
};
