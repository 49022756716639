import { all, takeLatest, put, select } from 'redux-saga/effects';
import { vouchersListActions } from '../../slices/vouchers/vouchersList';
import history from '../../../services/history';
import { areasFilterValueSelector } from '../../slices/global/areasFilter';
import { getObjFromQuery, getValidationErrors } from '@vanongo/utils';
import { api } from '../../../services/api';
import {
  filterMetadata,
  lifeSearchFilterMetadata,
} from '../../../views/vouchers/VouchersList/vouchersTableFilters';

const createFilters = (query) => {
  if (!query) {
    return {};
  }
  if (!Object.keys(query).length) {
    return {};
  }

  const metadata = [...filterMetadata, ...lifeSearchFilterMetadata];
  return Object.entries(query).reduce((total, [key, value]) => {
    if (key === 'status') {
      total.isActive = value === 'active';
      return total;
    }

    const findItem = metadata.find((el) => el.name === key);
    if (findItem) {
      total[key] = value;
    }

    return total;
  }, {});
};

const getVouchersListSaga = function* ({
  payload: { pageSize = 10, page = 1, queryString },
}) {
  const areaFromFilter = yield select(areasFilterValueSelector);
  const filtersValue = getObjFromQuery(queryString);

  try {
    const response = yield api.get(
      `${process.env.REACT_APP_ADMIN_URL}vouchers`,
      {
        query: {
          page,
          limit: pageSize,
          area: areaFromFilter,
          ...createFilters(filtersValue),
        },
      },
    );

    yield put(
      vouchersListActions.getVouchersListSuccess({
        data: response.results,
        page: response.currentPage,
        pageSize,
        totalCount: response.totalCount,
      }),
    );
  } catch (e) {
    console.log(e);
  }
};

const createVouchersSaga = function* ({ payload: data }) {
  try {
    const response = yield api.post(
      `${process.env.REACT_APP_ADMIN_URL}vouchers/`,
      {
        body: data,
      },
    );
    yield put(
      vouchersListActions.createVouchersSuccess({
        data: response,
      }),
    );
    history.push(`/vouchers`);
  } catch (e) {
    console.log(e);
    yield put(vouchersListActions.createVouchersFailed(getValidationErrors(e)));
  }
};

const changeStatusSaga = function* ({ payload: data }) {
  try {
    const response = yield api.patch(
      `${process.env.REACT_APP_ADMIN_URL}vouchers/${data.id}`,
      {
        body: {
          isActive: data.isActive,
        },
      },
    );
    yield put(
      vouchersListActions.changeStatusSuccess({
        data: response,
        id: response.id,
      }),
    );
  } catch (e) {
    console.log(e);
  }
};

export const vouchersSaga = function* () {
  yield all([
    takeLatest(vouchersListActions.getVouchersList.type, getVouchersListSaga),
    takeLatest(vouchersListActions.createVouchers.type, createVouchersSaga),
    takeLatest(vouchersListActions.changeStatus.type, changeStatusSaga),
  ]);
};
