import { reduxHelper } from '@vanongo/utils';
import { api } from '../../services/api';

export const {
  actions: spaceListActions,
  reducer: spaceList,
  selector: spaceListSelector,
  saga: spaceListSaga,
} = reduxHelper({
  actionName: 'getSpaceListOptions',
  name: 'spaceList',
  promise: () => api.get(`${process.env.REACT_APP_ADMIN_URL}spaces`),
  mapFunc: (arr) => arr.map((el) => ({ label: el.name, value: el.id })),
});
