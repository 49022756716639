import { all, put, takeEvery } from 'redux-saga/effects';
import { partnersCreateActions } from '../../slices/partners/partnersCreate';
import history from '../../../services/history';
import { commonRequest } from '../apiSaga';
import { toast } from '@vanongo/components/toast/SnackbarSingleton';
import { getValidationErrors } from '@vanongo/utils';

const successToast = (partner) => () =>
  toast(`Partner ${partner.name} is registered and verified`, {
    variant: 'success',
  });

const prepareCreateData = ({
  name,
  email,
  password,
  areaIds,
  linkToRescheduleInSms,
  priceInSms,
  geolocationService,
  useAreaAttributes,
  acceptUnvalidatedAddresses,
  sendSmsWithDeliveryTime,
  deliveryConfirmationType,
  deliveryTypeOptions,
  pickupConfirmationType,
  trackingLinkSmsText,
  customerCarePhone,
  logoUrl,
  features,
  bannerImg,
  bannerLink,
  isRescheduleAllowed,
}) => {
  const preparedData = {
    features,
    name,
    email,
    password,
    areaIds,
    linkToRescheduleInSms,
    priceInSms,
    geolocationService,
    useAreaAttributes,
    acceptUnvalidatedAddresses,
    sendSmsWithDeliveryTime,
    deliveryConfirmationType,
    deliveryTypeOptions,
    pickupConfirmationType,
    trackingLinkSmsText,
    customerCarePhone,
    logoUrl,
    bannerImg,
    bannerLink,
    isRescheduleAllowed,
  };

  preparedData.solverSettings = {};

  return preparedData;
};

const createPartnersSaga = function* ({ payload: data }) {
  try {
    const response = yield commonRequest('createPartner', {
      body: prepareCreateData(data),
    });

    yield put(partnersCreateActions.createPartnersSuccess());
    history.push(`/partners`);
    if (response.registrationStatus === 'FINISHED') {
      successToast(data)();
      return;
    }

    toast(
      'Registration is in progress, it can take several minutes to finish.\n You will receive push notification when it finished',
      { variant: 'info', style: { whiteSpace: 'pre-line' } },
    );
  } catch (e) {
    yield put(
      partnersCreateActions.createPartnersFailed(getValidationErrors(e)),
    );
  }
};

export const partnersCreateSaga = function* () {
  yield all([
    takeEvery(partnersCreateActions.createPartners.type, createPartnersSaga),
  ]);
};
