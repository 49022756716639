import { all, put, takeEvery, select } from 'redux-saga/effects';
import {
  areasInfoActions,
  areasInfoSelector,
} from '../../slices/areas/areasInfo';
import { commonRequest } from '../apiSaga';
import history from '../../../services/history';
import { prepareAreaInfo } from './helper/prepareAreaInfo';

const parseBeDataToReturn = (data) => ({
  ...data,
  country: data.country.id,
  currency: data.currency.id,
});

const getAreasInfoSaga = function* ({ payload }) {
  const { data } = yield select(areasInfoSelector);
  if (+payload === data.id) {
    yield put(areasInfoActions.getAreasInfoSuccess(data));
    return;
  }

  try {
    const response = yield commonRequest('getAreaById', {
      parameters: { areaId: payload },
    });
    yield put(areasInfoActions.getAreasInfoSuccess(response));
  } catch (e) {}
};

const updateAreasInfoSaga = function* ({ data, areaId }) {
  try {
    const response = yield commonRequest('updateArea', {
      body: data,
      parameters: { areaId },
    });

    yield put(areasInfoActions.updateAreasInfoSuccess(response));
    history.push(`/areas/${areaId}`);
  } catch (e) {
    yield put(areasInfoActions.updateAreasInfoFailed());
  }
};

const updateAreaBaseInfoSaga = function* ({ payload: { data, areaId } }) {
  const {
    data: { operationArea, cityCentreArea },
  } = yield select(areasInfoSelector);
  data.operationArea = operationArea;
  data.cityCentreArea = cityCentreArea;

  yield updateAreasInfoSaga({ data: prepareAreaInfo(data), areaId });
};

const updateAreasOperationAreaSaga = function* ({
  payload: { operationArea, areaId },
}) {
  const { data } = yield select(areasInfoSelector);
  const nextData = {
    ...parseBeDataToReturn(data),
    operationArea,
  };
  delete nextData.id;

  yield updateAreasInfoSaga({ data: nextData, areaId });
};

const updateAreasCenterAreaSaga = function* ({
  payload: { operationArea, areaId },
}) {
  const { data } = yield select(areasInfoSelector);
  const nextData = {
    ...parseBeDataToReturn(data),
    cityCentreArea: operationArea,
  };
  delete nextData.id;

  yield updateAreasInfoSaga({ data: nextData, areaId });
};

const removeAreaSaga = function* ({ payload }) {
  try {
    yield commonRequest('deleteArea', {
      parameters: { areaId: payload },
    });

    history.push('/areas');
  } catch (e) {}
};

export const areasInfoSaga = function* () {
  yield all([
    takeEvery(areasInfoActions.getAreasInfo.type, getAreasInfoSaga),
    takeEvery(areasInfoActions.updateAreasInfo.type, updateAreaBaseInfoSaga),
    takeEvery(
      areasInfoActions.updateAreasOperationArea.type,
      updateAreasOperationAreaSaga,
    ),
    takeEvery(
      areasInfoActions.updateAreasCenterArea.type,
      updateAreasCenterAreaSaga,
    ),
    takeEvery(areasInfoActions.removeArea.type, removeAreaSaga),
  ]);
};
