export const VOUCHERS_STATUS_LIST = [
  { value: 'inactive', label: 'Inactive' },
  { value: 'active', label: 'Active' },
];

export const lifeSearchFilterMetadata = [
  {
    name: 'status',
    title: 'Status',
    type: 'select',
    options: VOUCHERS_STATUS_LIST,
    width: 200,
  },
  {
    name: 'code',
    title: 'Code',
    type: 'string',
  },
];

export const filterMetadata = [
  {
    name: 'discount',
    title: 'Discount',
    type: 'number',
  },
  {
    name: 'usageFrom',
    title: 'Usage from',
    type: 'number',
  },
  {
    name: 'usageTo',
    title: 'Usage to',
    type: 'number',
  },
  {
    name: 'validFrom',
    title: 'Valid from',
    type: 'date',
  },
  {
    name: 'validTo',
    title: 'Valid to',
    type: 'date',
  },
  {
    name: 'description',
    title: 'Description',
    type: 'string',
  },
];
