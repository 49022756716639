import { all, put, select, takeEvery } from 'redux-saga/effects';
import {
  customersInfoActions,
  customersInfoSelector,
} from '../../slices/customers/customersInfo';
import { commonRequest } from '../apiSaga';
import history from '../../../services/history';

const getCustomersInfoSaga = function* ({ payload }) {
  const { data } = yield select(customersInfoSelector);
  if (payload === data.userId) {
    yield put(customersInfoActions.getCustomersInfoSuccess(data));
    return;
  }

  try {
    const response = yield commonRequest('getCustomerProfile', {
      parameters: { customerId: payload },
    });
    yield put(customersInfoActions.getCustomersInfoSuccess(response));
  } catch (e) {
    yield put(customersInfoActions.getCustomersInfoSuccess);
  }
};

const updateCustomersInfoSaga = function* ({ payload: { data, customerId } }) {
  try {
    const response = yield commonRequest('putCustomerProfile', {
      body: data,
      parameters: { customerId: customerId },
    });

    yield put(customersInfoActions.updateCustomersInfoSuccess(response));
    history.push(`/customers/${customerId}`);
  } catch (e) {
    yield put(customersInfoActions.updateCustomersInfoFailed());
    console.error(e);
  }
};

const removeCustomerSaga = function* ({ payload }) {
  try {
    yield commonRequest('deleteCustomerProfile', {
      parameters: { customerId: payload },
    });

    history.push('/customers');
  } catch (e) {}
};

export const customersInfoSaga = function* () {
  yield all([
    takeEvery(customersInfoActions.getCustomersInfo.type, getCustomersInfoSaga),
    takeEvery(
      customersInfoActions.updateCustomersInfo.type,
      updateCustomersInfoSaga,
    ),
    takeEvery(customersInfoActions.removeCustomer, removeCustomerSaga),
  ]);
};
