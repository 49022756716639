import React from 'react';

import PopupLayout from '../PopupLayout/PopupLayout';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../constants/namespaces';
import { Form } from '@vanongo/components/Form';

export const FormPopup = ({
  title,
  onSubmit,
  closePopup,
  disabled,
  defaultValues,
  metadata,
  closeOnSubmit = true,
  validationSchema,
  submitButtonText,
  errors,
}) => {
  const { t } = useTranslation(NAMESPACES.GLOBAL);

  const handleSubmit = (data) => {
    onSubmit(data);
    if (closeOnSubmit) {
      closePopup();
    }
  };

  const formButtons = [
    { text: t('Close'), onClick: closePopup },
    {
      color: 'secondary',
      type: 'submit',
      disabled,
      variant: 'contained',
      text: submitButtonText || t('Save'),
    },
  ];

  return (
    <PopupLayout width={650} title={title} buttonConfig={[]} overflow="auto">
      <Form
        canSubmit
        validationSchema={validationSchema}
        metadata={metadata}
        columnSize={1}
        onSubmit={handleSubmit}
        buttonConfig={formButtons}
        defaultValues={defaultValues}
        errors={errors}
      />
    </PopupLayout>
  );
};
