import { all, takeLatest, put, select } from 'redux-saga/effects';
import { driversListActions } from '../../slices/drivers/driversList';
import { commonRequest } from '../apiSaga';
import { areasFilterValueSelector } from '../../slices/global/areasFilter';
import { getObjFromQuery } from '@vanongo/utils';

const driversMap = (el) => ({ ...el, name: `${el.firstName} ${el.lastName}` });

const createFilters = (filters) => {
  if (!filters) {
    return {};
  }
  if (!Object.keys(filters).length) {
    return {};
  }

  return {
    name: filters.name,
    phone: filters.phone,
    group: filters.group,
    attributes: filters.attributes,
    organization: filters.organization,
  };
};

const getDriverListSaga = function* ({
  payload: { pageSize = 50, page = 1, params, queryString } = {
    pageSize: 50,
    page: 1,
  },
}) {
  const areaFromFilter = yield select(areasFilterValueSelector);
  const areaValue = (params && params.area) || areaFromFilter;
  const filtersValue = params || getObjFromQuery(queryString);

  try {
    const response = yield commonRequest('getDrivers', {
      parameters: {
        page,
        limit: pageSize,
        area: areaValue,
        ...createFilters(filtersValue),
      },
    });

    yield put(
      driversListActions.getDriverListSuccess({
        data: response.results.map(driversMap),
        page: response.currentPage,
        pageSize,
        totalCount: response.totalCount,
      }),
    );
  } catch (e) {}
};

export const driversListSaga = function* () {
  yield all([
    takeLatest(driversListActions.getDriverList.type, getDriverListSaga),
  ]);
};
