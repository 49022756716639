import { useState, useEffect } from 'react';

export const useGetCenterCoordinates = (centerPosition) => {
  const [center, setCenter] = useState(centerPosition);

  useEffect(() => {
    if (center[0] === centerPosition[0] && center[1] === centerPosition[1]) {
      return;
    }

    setCenter(centerPosition);
  }, [setCenter, centerPosition, center]);

  return {
    center,
    setCenter,
  };
};
