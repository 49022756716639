import { all, takeLatest, put, select } from 'redux-saga/effects';
import {
  servicesListActions,
  servicesListSelector,
} from '../../slices/b2cServices/b2cServicesList';
import history from '../../../services/history';
import { apiRequest } from '../../../services/apiSdk';
import { toast } from '@vanongo/components/toast/SnackbarSingleton';
import { localization } from '../../../services/localization';
import { NAMESPACES } from '../../../constants/namespaces';

const createBody = (data) => ({
  name: data.formState.name,
  description: data.formState.description,
  startDate: data.formState.startDate,
  pricingPolygons: data.formState.pricingPolygons,
  isMandatory: !!+data.formState.servicesType,
  services: data.formState.services,
  isActive: data.formState.isActive,
  // pricingModel: data.formState.pricingModel,
  // pricingConfiguration: data.formState.pricingConfiguration,
  pricesJson: data.matrixState,
  extraCostForLanguage: data.formState.extraCostForLanguage,
});

const getServicesListSaga = function* ({ payload: { isReload } }) {
  const data = yield select(servicesListSelector);
  if (data.services.length && !isReload) {
    yield put(
      servicesListActions.getServicesListSuccess({
        data: data.services,
      }),
    );
    return;
  }

  try {
    const response = yield apiRequest(
      `${process.env.REACT_APP_ADMIN_URL}b2c-services?btb=false`,
    );

    yield put(
      servicesListActions.getServicesListSuccess({
        data: response,
      }),
    );
  } catch (e) {
    console.error(e);
  }
};

const updateIsActiveSaga = function* ({
  payload: { value: isActive, params: id },
}) {
  try {
    yield apiRequest(`${process.env.REACT_APP_ADMIN_URL}b2c-services/${id}`, {
      method: 'patch',
      body: { isActive },
    });

    yield put(
      servicesListActions.updateIsActiveSuccess({
        id,
        isActive,
      }),
    );
    toast(
      localization.t('Status change successfully', {
        ns: NAMESPACES.SERVICES,
      }),
      {
        variant: 'success',
      },
    );
  } catch (e) {}
};

const createServiceSaga = function* ({ payload: data }) {
  try {
    const response = yield apiRequest(
      `${process.env.REACT_APP_ADMIN_URL}b2c-services`,
      {
        method: 'post',
        body: createBody(data),
      },
    );

    yield put(servicesListActions.createServiceSuccess(response));
    history.push('/services/b2c');
  } catch (e) {
    console.log('ERROR', e);
  }
};

const updateServiceSaga = function* ({ payload: data }) {
  try {
    const response = yield apiRequest(
      `${process.env.REACT_APP_ADMIN_URL}b2c-services/${data.id}`,
      {
        method: 'patch',
        body: createBody(data),
      },
    );

    yield put(servicesListActions.updateServiceSuccessfully(response));
    history.push('/services/b2c');
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const b2cServicesListSaga = function* () {
  yield all([
    takeLatest(servicesListActions.getServicesList.type, getServicesListSaga),
    takeLatest(servicesListActions.updateIsActive.type, updateIsActiveSaga),
    takeLatest(servicesListActions.createService.type, createServiceSaga),
    takeLatest(servicesListActions.updateService.type, updateServiceSaga),
  ]);
};
