import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: partnersCreateActions } = createSlice({
  name: 'partnersCreate',
  initialState: {
    isCreating: false,
    errors: {},
  },
  reducers: {
    createPartners: (state) => {
      state.isCreating = true;
      state.errors = {};
    },
    createPartnersSuccess: (state) => {
      state.isCreating = false;
    },
    createPartnersFailed: (state, { payload: errors }) => {
      state.isCreating = false;
      state.errors = errors;
    },
  },
});

export const partnersCreateSelector = (state) => state.partners.partnersCreate;
