import React, {Suspense, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {tokenSelector, windowFocus} from './redux/slices/authorization';
import LoadingScreen from '@vanongo/components/LoadingScreen';
import LoginView from './views/LoginView';

export const Auth = ({ children }) => {
  const dispatch = useDispatch();

  const handleFocusEvent = useCallback(
      () => {
        dispatch(windowFocus());
      },
      [dispatch]
  );

  useEffect(() => {
    window.addEventListener("focus", handleFocusEvent);
    return () => {
      window.removeEventListener("focus", handleFocusEvent);
    };
  }, [handleFocusEvent]);

  const token = useSelector(tokenSelector);

  if (!token) {
    return (
      <Suspense fallback={<LoadingScreen />}>
        <LoginView />
      </Suspense>
    );
  }

  return children;
};
