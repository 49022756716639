import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: usersPartnersListActions } = createSlice({
  name: 'usersPartnersList',
  initialState: {
    data: [],
    isLoading: false,
  },
  reducers: {
    getUsersPartnersList: (state) => {
      state.isLoading = true;
    },
    getUsersPartnersListSuccess: (state, { payload: { data } }) => {
      state.isLoading = false;
      state.data = data;
    },
  },
});

export const usersPartnersListSelector = (state) => state.partners.usersPartnersList;
