import { all, put, select, takeEvery } from 'redux-saga/effects';
import {
  orderStatusHistoryActions,
  orderStatusHistorySelector,
} from '../../slices/orders/orderStatusHistory';
import { commonRequest } from '../apiSaga';

const getOrderStatusHistorySaga = function* ({
  payload: { params: orderId, isReload },
}) {
  const { orderItemId, data } = yield select(orderStatusHistorySelector);
  if (orderId === orderItemId && !isReload) {
    yield put(
      orderStatusHistoryActions.getOrderStatusHistorySuccess({
        data,
        orderItemId,
      }),
    );
    return;
  }

  try {
    const response = yield commonRequest('getOrderItemHistory', {
      parameters: { orderItemId: orderId },
    });
    yield put(
      orderStatusHistoryActions.getOrderStatusHistorySuccess({
        data: response,
        orderItemId: orderId,
      }),
    );
  } catch (e) {}
};

export const orderStatusHistorySaga = function* () {
  yield all([
    takeEvery(
      orderStatusHistoryActions.getOrderStatusHistory.type,
      getOrderStatusHistorySaga,
    ),
  ]);
};
