import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: customersCreateActions } = createSlice({
  name: 'customersCreate',
  initialState: {
    isUpdating: false,
    errors: {},
  },
  reducers: {
    createCustomers: (state) => {
      state.isUpdating = true;
      state.errors = {};
    },
    createCustomersSuccess: (state) => {
      state.isUpdating = false;
      state.errors = {};
    },
    createCustomersFailed: (state, { payload: errors }) => {
      state.isUpdating = false;
      state.errors = errors;
    },
  },
});

export const customersCreateSelector = (state) =>
  state.customers.customersCreate;
