import React from 'react';
import { SelectionRender } from '../SelectionRender';
import { TableCell as MaterialTableCell, Tooltip } from '@material-ui/core';
import clsx from 'clsx';

export const Row = ({
  rowData,
  columns,
  rowKey,
  enableRowSelection,
  classes,
  onRowClick,
  width,
  rowHeight,
}) => {
  const rowClassName = clsx(classes.tableRow, classes.flexContainer, 'df', {
    [classes.tableRowHover]: onRowClick != null,
  });

  return (
    <div
      onClick={(event) => onRowClick({ event, rowData })}
      className={rowClassName}
      style={{ height: rowHeight, width }}
    >
      {!!rowData[rowKey] && enableRowSelection && (
        <SelectionRender rowData={rowData} classes={classes} rowKey={rowKey} />
      )}

      {columns.map(({ render, field, width, showTooltip, isHidden }) => {
        if (isHidden) {
          return null;
        }
        // TODO find out and fix error in console (empty title in tooltip)
        return (
          <Tooltip
            key={field}
            title={render ? render(rowData) || '' : rowData[field] || ''}
            disableHoverListener={!showTooltip}
            disableFocusListener={!showTooltip}
            disableTouchListener={!showTooltip}
          >
            <MaterialTableCell
              component="div"
              className={clsx(classes.tableCell, classes.flexContainer, {
                [classes.noClick]: onRowClick == null,
              })}
              variant="body"
              align="left"
              style={{
                minWidth: width,
                maxWidth: width,
                height: '100%',
              }}
            >
              {render ? render(rowData, field) : rowData[field]}
              <div className={clsx(classes.customEllipses, 'greyHover')} />
            </MaterialTableCell>
          </Tooltip>
        );
      })}
    </div>
  );
};
