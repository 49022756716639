import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: servicesListActions } = createSlice({
  name: 'servicesList',
  initialState: {
    services: [],
    isLoading: false,
    isCreating: false,
  },
  reducers: {
    getServicesList: (state) => {
      state.isLoading = true;
    },
    getServicesListSuccess: (state, { payload: { data } }) => {
      state.isLoading = false;
      state.services = data;
    },
    updateIsActive: (state, { payload }) => {},
    updateIsActiveSuccess: (state, { payload: { id, isActive } }) => {
      const item = state.services.find((el) => el.id === id);
      item.isActive = isActive;
    },
    updateService: () => {},
    updateServiceSuccessfully: (state, { payload: service }) => {
      const index = state.services.findIndex((el) => el.id === service.id);
      if (index >= 0) {
        state.services.splice(index, 1, service);
      }
    },
    createService: (state) => {
      state.isCreating = true;
    },
    createServiceSuccess: (state, { payload }) => {
      state.isCreating = false;
      state.services.unshift(payload);
    },
  },
});

export const servicesListSelector = (state) => state.services.b2cServicesList;
