import React from 'react';
import Logo, { BLACK_LOGO } from '@vanongo/components/Logo';
import { Box, Divider } from '@material-ui/core';
import { dateddmmyyFormat } from '@vanongo/utils';
import Barcode from 'react-barcode';

export const PrintContainer = ({
  classes,
  deliveryStop,
  deliveryContactPersonDTO,
  orderItemDTO,
  externalId,
  weight,
  componentRef,
  formData,
}) => {
  return (
    <div ref={componentRef} className={classes.container}>
      <div className="df fdc aic">
        <Logo type={BLACK_LOGO} />
        <Barcode format="CODE128" value={externalId} />
      </div>
      <Divider />

      <Box mt={1} mb={4} className="df jcsb ">
        <div style={{ width: '50%' }}>
          Отправитель:
          <div className={classes.bigSize}>
            <Box mt={2}>№ заказа:</Box>
            <Box mt={1}>{externalId}</Box>
            <Box mt={3}>
              {deliveryContactPersonDTO.firstName}{' '}
              {deliveryContactPersonDTO.lastName}
            </Box>
          </div>
          <Box mt={4}>{deliveryStop.city}</Box>
          <Box>
            {deliveryStop.street} {deliveryStop.number}
          </Box>
        </div>
        <div style={{ width: '50%' }}>
          Получатель:
          <Box mt={3}>{formData.city}</Box>
          <Box mt={2}>{formData.address}</Box>
          <Box mt={2}>{formData.shopName}</Box>
          <Box>{formData.shopPhone}</Box>
        </div>
      </Box>

      <Box className="df jcsb">
        <div style={{ width: '100%' }}>
          <span>{deliveryContactPersonDTO.phone}</span>
          <Box mt={1} mb={1} className="df jcsb">
            <div style={{ width: '50%' }}>
              Вес: {weight}
              <Box mt={5}>Кол-во мест: 1</Box>
            </div>
            <div style={{ width: '50%' }}>
              Дата доставки(план):
              <Box mt={3} className={classes.largeSize}>
                {dateddmmyyFormat(
                  orderItemDTO.deliveryDatetimePeriod.slice(2, 24),
                )}
              </Box>
            </div>
          </Box>

          <Divider />
          <Box mt={2}>{formData.productName}</Box>
        </div>
        <div style={{ marginTop: -100 }}>
          <div className={classes.rightBarcode}>
            <Barcode
              format="CODE128"
              value={externalId}
              displayValue={false}
              height={60}
            />
          </div>
        </div>
      </Box>
    </div>
  );
};
