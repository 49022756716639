import React from 'react';
import { Edit as EditIcon } from 'react-feather';
import { Box, makeStyles, SvgIcon, IconButton } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  header: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    padding: 0,
  },
}));

export const DetailsHeader = ({ title, hasEdit, canEdit, onEdit }) => {
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      {title}{' '}
      {hasEdit && (
        <IconButton
          color="secondary"
          className={classes.button}
          disabled={!canEdit}
          onClick={onEdit}
        >
          <SvgIcon fontSize="small">
            <EditIcon />
          </SvgIcon>
        </IconButton>
      )}
    </Box>
  );
};
