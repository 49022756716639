import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: partnersInfoActions } = createSlice({
  name: 'partnersDetails',
  initialState: {
    data: {},
    isLoading: false,
    isUpdating: false,
    isError: false,
  },
  reducers: {
    getPartnersInfo: (state) => {
      state.isLoading = true;
    },
    getPartnersInfoSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
    getPartnersInfoPutError: (state) => {
      state.isError = true;
    },
    updatePartnersInfo: (state) => {
      state.isUpdating = true;
    },
    updatePartnersSolverConfig: (state) => {
      state.isUpdating = true;
    },
    updatePartnersInfoSuccess: (state, { payload }) => {
      state.isUpdating = false;
      state.data = payload;
    },
    updatePartnersInfoFailed: (state) => {
      state.isUpdating = false;
    },
    updateOrdersImportConfig: (state) => {
      state.isUpdating = true;
    },
    updateOrdersImportConfigSuccess: (state, { payload }) => {
      state.isUpdating = false;
      state.data = payload;
    },
    updateOrdersImportConfigFailed: (state) => {
      state.isUpdating = false;
    },
    updateImportMappingConfig: (state) => {
      state.isUpdating = true;
    },
    updateImportMappingConfigSuccess: (state, { payload }) => {
      state.isUpdating = false;
      state.data = payload;
    },
    updateImportMappingConfigFailed: (state) => {
      state.isUpdating = false;
    },
    reverseIntegrationConfig: (state) => {
      state.isUpdating = true;
    },
    reverseIntegrationConfigSuccess: (state, { payload }) => {
      state.isUpdating = false;
      state.data = payload;
    },
    reverseIntegrationConfigFailed: (state) => {
      state.isUpdating = false;
    },
    removePartner: (state) => {
      state.isLoading = true;
    },
  },
});

export const partnersInfoSelector = (state) => state.partners.partnersInfo;
