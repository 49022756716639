import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import { menuItems } from 'packages/webAdmin/src/navList';
import { MenuItem } from './MenuItem';

const useStyles = makeStyles(() => ({
  menuContainer: {
    width: 100,
    top: 72,
    height: 'calc(100% - 72px)',
    position: 'fixed',
    backgroundColor: '#ffffff',
    zIndex: 1000,
    overflow: 'visible',
  },
  '@media (max-height: 840px)': {
    menuContainer: {
      width: 90,
    },
  },
}));

const NavBar = () => {
  const classes = useStyles();

  return (
    <Box className={classes.menuContainer}>
      <Box pt={2} pb={2}>
        {menuItems.map(MenuItem)}
      </Box>
    </Box>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
