import { all } from 'redux-saga/effects';
import { authorizationSaga } from './saga/authorization/authorization.saga';
import { driversListSaga } from './saga/drivers/driversListSaga';
import { driversInfoSaga } from './saga/drivers/driversInfoSaga';
import { driversScheduleSaga } from './saga/drivers/driversScheduleSaga';
import { driversVehiclesSaga } from './saga/drivers/driversVehiclesSaga';
import { driversCreateSaga } from './saga/drivers/driversCreateSaga';
import {driversUploadFilesSaga} from "./saga/drivers/driversFilesSaga";

import { partnersCreateSaga } from './saga/partners/partnersCreateSaga';
import { partnersInfoSaga } from './saga/partners/partnersInfoSaga';
import { partnersListSaga } from './saga/partners/partnersListSaga';
import { usersPartnersSaga } from './saga/partners/usersPartnersListSaga';
import { b2bServicesSaga } from './saga/b2bServices/listSaga';

import { customersCreateSaga } from './saga/customers/customersCreateSaga';
import { customersInfoSaga } from './saga/customers/customersInfoSaga';
import { customersListSaga } from './saga/customers/customersListSaga';
import { areasFilterSaga } from './saga/global/areasFilterSaga';
import { areasListSaga } from './saga/areas/areasListSaga';
import { areasPricingPolygonsSaga } from './saga/areas/areasPricingPolygonsSaga';
import { areasCreateSaga } from './saga/areas/areasCreateSaga';
import { areasInfoSaga } from './saga/areas/areasInfoSaga';
import { areasPaymentMethodsSaga } from './saga/areas/areasPaymentMethods';
import { areasLegalDocumentsSaga } from './saga/areas/areasLegalDocuments';
import { areasAttributesSaga } from './saga/areas/areasAttributesSaga';

import { vehiclesListSaga } from './saga/vehicles/vehiclesListSaga';
import { vehiclesCreateSaga } from './saga/vehicles/vehiclesCreateSaga';
import { vehiclesInfoSaga } from './saga/vehicles/vehiclesInfoSaga';

import { solverInfoSaga } from './saga/solver/solverInfoSaga';
import { solverListSaga } from './saga/solver/solverListSaga';
import { solverCreateSaga } from './saga/solver/solverCreateSaga';
import { solverLogSaga } from './saga/solver/solverLogSaga';

import { waybillsListSaga } from './saga/waybills/waybillsListSaga';
import { waybillsCreateSaga } from './saga/waybills/waybillsCreateSaga';
import { waybillsInfoSaga } from './saga/waybills/waybillsInfoSaga';

import { ordersListSaga } from './saga/orders/ordersListSaga';
import { ordersCreateSaga } from './saga/orders/ordersCreateSaga';
import { ordersInfoSaga } from './saga/orders/ordersInfoSaga';
import { orderStatusHistorySaga } from './saga/orders/orderStatusHistorySaga';
import { orderSmsHistorySaga } from './saga/orders/orderSmsHistorySaga';

import { cargoListSaga } from './saga/cargo/cargoListSaga';
import { cargoInfoSaga } from './saga/cargo/cargoInfoSaga';
import { cargoStatusHistorySaga } from './saga/cargo/cargoStatusHistorySaga';
import { optionsSaga } from './options';
import { b2cServicesOptionSaga } from './saga/b2cServices/b2cServicesOptionSaga';
import { b2cServiceDetailsSaga } from './saga/b2cServices/b2cServiceDetailsSaga';
import { b2cServicesListSaga } from './saga/b2cServices/b2cServicesListSaga';
import { earningsListSaga } from './saga/services/earningsListSaga';

import { servicesListSaga } from './saga/services/servicesListSaga';

import { pricingModelListSaga } from './saga/services/pricingModelSaga';

import { vouchersSaga } from './saga/vouchers/vouchersSaga';

import { attributesSaga } from './saga/groups/attributesSaga';
import { groupsListSaga } from './saga/groups/groupsSaga';
import { attributesValuesSaga } from './saga/groups/attributesValuesSaga';

import { driversDebtorSaga } from './saga/driversDebtor/driversDebtorSaga';
import { smsConfigurationListSaga } from './saga/smsConfiguration/smsConfigurationSaga';
import { deliveryDynamicsSaga } from './saga/statistics/deliveryDynamicsSaga';

import { getMapStopSaga } from './saga/map/mapStopListSaga';

import { gettersSaga } from './getters';

export default function* rootSaga() {
  yield all([
    authorizationSaga(),
    driversListSaga(),
    driversInfoSaga(),
    driversScheduleSaga(),
    driversVehiclesSaga(),
    driversCreateSaga(),
    driversUploadFilesSaga(),
    partnersCreateSaga(),
    partnersInfoSaga(),
    partnersListSaga(),
    usersPartnersSaga(),
    customersCreateSaga(),
    customersInfoSaga(),
    customersListSaga(),
    areasFilterSaga(),
    areasInfoSaga(),
    areasPaymentMethodsSaga(),
    areasListSaga(),
    areasPricingPolygonsSaga(),
    areasCreateSaga(),
    areasLegalDocumentsSaga(),
    areasAttributesSaga(),
    vehiclesListSaga(),
    vehiclesCreateSaga(),
    vehiclesInfoSaga(),
    solverInfoSaga(),
    solverListSaga(),
    solverCreateSaga(),
    solverLogSaga(),
    waybillsListSaga(),
    waybillsCreateSaga(),
    waybillsInfoSaga(),
    ordersListSaga(),
    ordersCreateSaga(),
    ordersInfoSaga(),
    orderStatusHistorySaga(),
    orderSmsHistorySaga(),
    cargoListSaga(),
    cargoInfoSaga(),
    cargoStatusHistorySaga(),
    optionsSaga(),
    b2cServicesListSaga(),
    b2cServicesOptionSaga(),
    b2cServiceDetailsSaga(),
    servicesListSaga(),
    pricingModelListSaga(),
    vouchersSaga(),
    driversDebtorSaga(),
    deliveryDynamicsSaga(),
    gettersSaga(),
    attributesSaga(),
    groupsListSaga(),
    attributesValuesSaga(),
    b2bServicesSaga(),
    earningsListSaga(),
    smsConfigurationListSaga(),
    getMapStopSaga(),
  ]);
}
