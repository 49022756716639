import React from 'react';
import { Form } from '@vanongo/components/Form';
import { useTransformMetadata } from '../../hooks/useTransformMetadata';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../constants/namespaces';
import formMetadata from './formMetadata';

export const NoteForm = ({ formRef, formData }) => {
  const { t } = useTranslation(NAMESPACES.ORDERS);
  const metadata = useTransformMetadata({ metadata: formMetadata, t });

  return (
    <Form
      ref={formRef}
      metadata={metadata}
      columnSize={1}
      defaultValues={formData}
      buttonConfig={[]}
    />
  );
};
