import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: popupActions } = createSlice({
  name: 'openDataBot',
  initialState: {
    openedPopups: [],
  },
  reducers: {
    closePopup: (state) => {
      state.openedPopups = state.openedPopups.slice(
        0,
        state.openedPopups.length - 1,
      );
    },
    showPopup: (state, { payload: { popupProps, componentKey } }) => {
      state.openedPopups = state.openedPopups.concat([
        {
          ...popupProps,
          componentKey,
        },
      ]);
    },
  },
});

export const showPopup = (componentKey, props = {}) => ({
  payload: {
    componentKey,
    popupProps: props,
  },
  type: popupActions.showPopup.type,
});

export const openedPopupsSelect = (state) => state.popup.global.openedPopups;
