import { put, cancel, delay, fork } from 'redux-saga/effects';

import { decodeJWTToken } from '@vanongo/utils';
import {authorizationActions} from '../../slices/authorization';
import { signOutSaga } from './authorization.saga';
import store from '../../store';

// 50min
const REFRESH_TOKEN_INTERVAL = 50 * 60 * 1000;
let tokenTask;

const getExpirationTimeout = (generated, expiration) => {
  let expirationTimeout = expiration - generated;

  if (expirationTimeout > REFRESH_TOKEN_INTERVAL) {
    expirationTimeout = REFRESH_TOKEN_INTERVAL;
  }

  return expirationTimeout
}

export function* clearGetTokenTimeout() {
  if (tokenTask) {
    yield cancel(tokenTask);
    tokenTask = null;
  }
}

const getToken = function* (user) {
  if (!user || Object.keys(user).length === 0){   //user is empty
    yield signOutSaga();
    return;
  }

  let token;
  try {
    token = yield user.getIdToken(true);
  } catch (e) {
    console.error(e);
    yield signOutSaga();
    return;
  }

  yield put(authorizationActions.setToken(token));

  const { expiration } = decodeJWTToken(token);
  let expirationTimeout = getExpirationTimeout(new Date().getTime(), expiration);

  yield delay(expirationTimeout);
  tokenTask = yield fork(getToken, user);
};

export const checkTokenAndRefresh = function* () {
  if (tokenTask && tokenTask.isRunning()) {
      let authToken = store.getState().authorization.token;

      if (authToken) {
        const {issued, expiration} = decodeJWTToken(authToken);
        let expirationTimeout = getExpirationTimeout(issued, expiration);

        if (expirationTimeout + issued <= new Date().getTime()) {
          yield clearGetTokenTimeout();
        }
      }
  }else{
    yield clearGetTokenTimeout();
  }

  if (!tokenTask) {
    const userInfo = store.getState().authorization.userInfo;
    if (userInfo) {
      yield executeGetToken(userInfo);
    }
  }
};

export const executeGetToken = function* (user, onOpenSite) {
    tokenTask = yield fork(getToken, user, onOpenSite);
};
