import format from 'date-fns/format';

export function formatDate(value, key) {
  if (!value && value !== 0) {
    return undefined;
  }

  if (value === 0) {
    return undefined;
  }

  try {
    if (value.slice && value.slice(-3, -2) === '+') {
      return format(new Date(value + '00'), key);
    }

    return format(new Date(value), key);
  } catch (e) {
    return '';
  }
}
