import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: earningsListActions } = createSlice({
  name: 'earningsList',
  initialState: {
    list: [],
    page: 0,
    totalCount: 0,
    pageSize: 10,

    isLoading: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
  reducers: {
    getEarningsList: (state) => {
      state.isLoading = true;
    },
    getEarningsListSuccess: (
      state,
      { payload: { data, page, totalCount, pageSize } },
    ) => {
      state.isLoading = false;
      state.list = data;
      state.page = page - 1;
      state.totalCount = totalCount;
      state.pageSize = pageSize;
    },
    create: (state) => {
      state.isCreating = true;
    },
    createSuccess: (state, { payload }) => {
      state.list.unshift(payload);
      state.isCreating = false;
    },
    createFailed: (state) => {
      state.isCreating = false;
    },
    update: (state) => {
      state.isUpdating = true;
    },
    updateSuccess: (state, { payload: data }) => {
      const index = state.list.findIndex((el) => el.id === data.id);
      if (index > -1) {
        state.list.splice(index, 1, data);
      }

      state.isUpdating = false;
    },
    updateFailed: (state) => {
      state.isUpdating = false;
    },
    delete: (state) => {
      state.isDeleting = true;
    },
    deleteSuccess: (state) => {
      state.isDeleting = false;
    },
    deleteFailed: (state) => {
      state.isDeleting = false;
    },
  },
});

export const earningsListSelector = (state) => state.services.earningsList;
