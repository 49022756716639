export const get = (obj, path, def) => {
  if (!obj) {
    return def;
  }

  let res = path
    .replace(/\[/g, '.')
    .replace(/\]/g, '')
    .replace(/^\./, '')
    .split('.')
    .reduce((prev, curr) => {
      return prev && prev[curr];
    }, obj);

  return res === undefined ? def : res;
};
