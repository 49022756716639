import React from 'react';
import { Tooltip } from '@material-ui/core';

export const AggregationPanel = ({
  columnsWithWidth,
  aggregation = {},
  enableRowSelection,
  classes,
}) => {
  return (
    <>
      {enableRowSelection && (
        <div
          className={classes.aggregationItem}
          style={{
            minWidth: 30,
            maxWidth: 30,
          }}
        />
      )}
      {columnsWithWidth.map(({ field, width, isHidden }) => {
        const value = aggregation[field];

        if (isHidden) {
          return null;
        }

        return (
          <Tooltip
            key={field}
            title={value || ''}
            disableHoverListener={!value}
            disableFocusListener={!value}
            disableTouchListener={!value}
          >
            <div
              key={field}
              className={classes.aggregationItem}
              style={{
                minWidth: width,
                maxWidth: width,
              }}
            >
              {value}
              <div className={classes.customEllipses} />
            </div>
          </Tooltip>
        );
      })}
    </>
  );
};
