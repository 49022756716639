import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: ordersListActions } = createSlice({
  name: 'ordersList',
  initialState: {
    orders: [],
    aggregation: {},
    page: 0,
    totalCount: 0,
    isLoading: false,
    pageSize: 10,

    isFetchingExport: false,

    isImportOrdersType: false,
    importOrdersType: 0,
    error: {},
    importOrdersInfo: {},

    isFetchingSelectAll: false,
    selectAllOrders: [],
  },
  reducers: {
    getOrdersList: (state, { payload: { pageSize, page } }) => {
      state.isLoading = true;
      if (pageSize) {
        state.pageSize = pageSize;
      }
      if (page) {
        state.page = page - 1;
      }
    },
    getOrdersListSuccess: (
      state,
      { payload: { data, page, totalCount, pageSize, aggregation } },
    ) => {
      state.isLoading = false;
      state.orders = data;
      state.page = page - 1;
      state.totalCount = totalCount;
      state.pageSize = pageSize;
      state.aggregation = aggregation;
    },
    changeStatus: () => { },
    changeStatusSuccess: (state, { payload: { status, ids } }) => {
      ids.forEach((id) => {
        const item = state.orders.find((el) => el.id === id);
        if (item) {
          item.status = status;
        }
      });
    },
    reportExport: (state) => {
      state.isFetchingExport = true;
    },
    reportExportExportSuccess: (state) => {
      state.isFetchingExport = false;
    },
    selectAllOrders: (state) => {
      state.isFetchingSelectAll = true;
    },
    deselectAllOrders: (state) => {
      state.selectAllOrders = [];
    },
    selectAllOrdersSuccess: (state, { payload: { data } }) => {
      state.isFetchingSelectAll = false;
      state.selectAllOrders = data;
    },
    importFinishedOrders: (state) => {
      state.isImportOrdersType = true;
      state.isImportOrders = 0;
      state.error = {};
    },
    importFinishedOrdersSuccess: (state, { payload: { data } }) => {
      state.isImportOrdersType = false;
      state.importOrdersType = 1;
      state.error = {};
      state.importOrdersInfo = data;
    },
    importFinishedOrdersFailed: (state, { payload: { error } }) => {
      state.isImportOrdersType = false;
      state.importOrdersType = 2;
      state.error = error;
      state.importOrdersInfo = {};
    },
    clearImportOrders: (state) => {
      state.isImportOrdersType = false;
      state.importOrdersType = 0;
      state.error = {};
      state.importOrdersInfo = {};
    },
    rateUsOrders: () => { },
    createOrder: (state) => {
      state.isLoading = true;
    },
    createOrderSuccess: (state) => {
      state.isLoading = false;
    },
  },
});

export const ordersListSelector = (state) => state.orders.ordersList;
