import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: areasLegalDocumentsActions } = createSlice({
  name: 'areasLegalDocuments',
  initialState: {
    documents: {},
    isLoading: false,
  },
  reducers: {
    getAreasLegalDocuments: (state) => {
      state.isLoading = true;
    },
    getAreasLegalDocumentsSuccess: (state, { payload: { data } }) => {
      state.isLoading = false;
      state.documents = data;
    },
    uploadAreaDocument: (state) => {
      state.isLoading = true;
    },
    uploadAreaDocumentSuccess: (state, { payload: { data, type } }) => {
      state.isLoading = false;
      state.documents = { ...state.documents, [type]: data };
    },
  },
});

export const areasLegalDocumentsSelector = (state) =>
  state.areas.areasLegalDocuments;
