import React, { useMemo } from 'react';
import { TableHeader } from './TableHeader';
import { sizeConfig } from '../../sizeConfig';
import { makeStyles } from '@material-ui/core';
import { TableComponent } from './TableComponent';
import { Spinner } from '../../../Spinner';
import { Footer } from '../footer/Footer';

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: '#eeeeee',
      '& .greyHover': {
        backgroundColor: '#eeeeeeb3',
      },
    },
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 0 5px',
    overflow: 'hidden',
    position: 'relative',
  },
  customEllipses: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: 5,
    height: '100%',
    background: '#ffffffb3',
  },
  headerCell: {
    fontWeight: 'bold',
    cursor: 'initial',
    flex: 1,
    padding: 0,
  },
  headerContainer: {
    position: 'sticky',
    top: 0,
    background: '#fff',
    display: 'flex',
    zIndex: 2,
  },
  tableContainer: {
    height: 'calc(100% - 49px)',
    position: 'relative',
    overflow: 'auto',
  },
  ellipsis: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
  },
  headerElementWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '0 5px',
  },
  headerEllipsis: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: 1,
  },
  resizeHandle: {
    content: ' ',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 5,
    cursor: 'ew-resize',
    borderRight: '1px dashed #e0e0e0',
  },
  dragHandle: {
    cursor: 'move',
    marginRight: 2,
  },
  settingsIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  twoRowsEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
}));

export const Table = ({
  enableRowSelection,
  rowKey,
  rowSize,
  data,
  onRowClick,
  isLoading,
  sorting,
  onChangeSorting,
  enableResizing,
  enableReordering,
  columnsWithWidth,
  onResize,
  onReorder,
  rowCount,
  aggregation,
}) => {
  const classes = useStyles();

  const width = useMemo(
    () =>
      columnsWithWidth.reduce(
        (total, el) => (el.isHidden ? total : total + el.width),
        0,
      ) + (enableRowSelection ? 30 : 0),
    [columnsWithWidth, enableRowSelection],
  );

  if (isLoading) {
    return (
      <div className={classes.tableContainer}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={classes.tableContainer}>
      <TableHeader
        width={width}
        columns={columnsWithWidth}
        sorting={sorting}
        onChangeSorting={onChangeSorting}
        classes={classes}
        headerHeight={sizeConfig[rowSize].headerHeight}
        enableRowSelection={enableRowSelection}
        enableReordering={enableReordering}
        enableResizing={enableResizing}
        onResize={onResize}
        onReorder={onReorder}
      />
      <TableComponent
        data={data}
        width={width}
        columns={columnsWithWidth}
        rowKey={rowKey}
        enableRowSelection={enableRowSelection}
        onRowClick={onRowClick}
        rowHeight={sizeConfig[rowSize].rowHeight}
        ellipsisHeight={sizeConfig[rowSize].ellipsisHeight}
        classes={classes}
      />
      {!!rowCount && (
        <Footer
          rowCount={rowCount}
          columnsWithWidth={columnsWithWidth}
          aggregation={aggregation}
          footerHeight={sizeConfig[rowSize].footerHeight}
          enableRowSelection={enableRowSelection}
        />
      )}
    </div>
  );
};
