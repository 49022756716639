import { all } from 'redux-saga/effects';
import { solversOptions, solversOptionsSaga } from './solvers';

import { combineReducers } from '@reduxjs/toolkit';

export const optionsSaga = function* optionsSaga() {
  yield all([
    solversOptionsSaga(),
  ]);
};

export const optionsReducer = combineReducers({
  solversOptions,
});
