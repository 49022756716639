import React, { useState } from 'react';
import Page from '../Page';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { Breadcrumbs } from '../Breadcrumbs';
import { Form } from '../Form';
import LoadingScreen from '../LoadingScreen';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

export const FormPage = ({
  isLoading,
  breadcrumbsOptions,
  pageTitle,
  title,
  formProps = {},
  canSubmit,
  errors,
}) => {
  const [formState, changeFormState] = useState();
  const classes = useStyles();

  if (isLoading) {
    return <LoadingScreen />;
  }
  const onSubmit = (data) => {
    formProps.onSubmit(data);
    changeFormState(data);
  };

  return (
    <Page className={classes.root} title={pageTitle}>
      <Container maxWidth={false}>
        <Box>
          <Breadcrumbs data={breadcrumbsOptions} />
          {title && (
            <Typography variant="h3" color="textPrimary">
              {title}
            </Typography>
          )}
        </Box>
      </Container>
      <Box mt={3}>
        <Container maxWidth="lg">
          <Form
            {...formProps}
            canSubmit={canSubmit}
            defaultValues={formState || formProps.defaultValues}
            onSubmit={onSubmit}
            errors={errors}
          />
        </Container>
      </Box>
    </Page>
  );
};
