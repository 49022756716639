import { reduxHelper } from '@vanongo/utils';
import { api } from '../../services/api';

export const {
  actions: b2bServicesListBriefActions,
  reducer: b2bServicesListBrief,
  selector: b2bServicesListBriefSelector,
  saga: b2bServicesListBriefSaga,
} = reduxHelper({
  actionName: 'get',
  name: 'b2bServicesListBrief',
  promise: () =>
    api.get(`${process.env.REACT_APP_ADMIN_URL}b2b-services/list-brief`),
  mapFunc: (arr) => {
    const arrFiltered = arr.filter((el) => el.name);
    return arrFiltered.map((el) => ({
      label: el.name,
      value: el.id,
    }));
  },
  fetchOnce: false,
});
