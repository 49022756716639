import React, { Suspense, useState, useCallback } from 'react';
import PopupLayout from '../PopupLayout/PopupLayout';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import { SelectDrivers } from './selectDrivers/SelectDrivers';
import LoadingScreen from '@vanongo/components/LoadingScreen';
import { ConfigureDrivers } from './configureDrivers/ConfigureDrivers';
import { FinalCheck } from './finalCheck/FinalCheck';
import { ConfigureJob } from './configureJob/ConfigureJob';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../constants/namespaces';

const steps = [
  'Configure job',
  'Select drivers',
  'Configure drivers',
  'Final check',
];
export const CreateSolverJobPopup = ({
  closePopup,
  selection: selectedOrders,
  startStep = 0,
}) => {
  const { t } = useTranslation(NAMESPACES.ORDERS);
  const [activeStep, setActiveStep] = useState({
    step: startStep,
    selectedOrders,
    selectedDrivers: [],
  });
  const [selectedDrivers, changeSelectedDrivers] = useState([]);

  const handleChangeSelectedDrivers = useCallback(
    (item) =>
      changeSelectedDrivers((prevSelection) => {
        if (!item) {
          return [];
        }

        const completeMatch = prevSelection.findIndex(
          (el) =>
            el.driverId === item.driverId && el.vehicleId === item.vehicleId,
        );
        if (completeMatch > -1) {
          return prevSelection.filter((el, i) => i !== completeMatch);
        }

        const index = prevSelection.findIndex(
          (el) => el.driverId === item.driverId,
        );

        if (index > -1) {
          return [...prevSelection.filter((el, i) => i !== index), item];
        }

        return [...prevSelection, item];
      }),
    [changeSelectedDrivers],
  );

  const handleNext = (newData = {}) => {
    setActiveStep((prevActiveStep) => ({
      ...prevActiveStep,
      step: prevActiveStep.step + 1,
      ...newData,
    }));
  };

  const handleBack = (newData = {}) => {
    setActiveStep((prevActiveStep) => ({
      ...prevActiveStep,
      step: prevActiveStep.step - 1,
      ...newData,
    }));
  };

  const contentMap = {
    0: (
      <ConfigureJob
        activeStep={activeStep}
        closePopup={closePopup}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    ),
    1: (
      <Suspense fallback={<LoadingScreen />}>
        <SelectDrivers
          activeStep={activeStep}
          closePopup={closePopup}
          handleBack={handleBack}
          handleNext={handleNext}
          selectedDrivers={selectedDrivers}
          changeSelectedDrivers={handleChangeSelectedDrivers}
        />
      </Suspense>
    ),
    2: (
      <Suspense fallback={<LoadingScreen />}>
        <ConfigureDrivers
          activeStep={activeStep}
          handleBack={handleBack}
          closePopup={closePopup}
          handleNext={handleNext}
        />
      </Suspense>
    ),
    3: (
      <FinalCheck
        activeStep={activeStep}
        closePopup={closePopup}
        handleBack={handleBack}
      />
    ),
  };

  return (
    <PopupLayout width="100%" title={t('Create solver job')}>
      <Stepper
        activeStep={activeStep.step}
        alternativeLabel
        style={{ padding: 12 }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{t(label)}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {contentMap[activeStep.step]}
    </PopupLayout>
  );
};
