import { all, put, select, takeEvery } from 'redux-saga/effects';
import {
  orderSmsHistoryActions,
  orderSmsHistorySelector,
} from '../../slices/orders/orderSmsHistory';
import {apiRequest} from "../../../services/apiSdk";

const getOrderStatusSMSSaga = function* ({
  payload: { params: orderId, isReload },
}) {
  const { orderItemId, data } = yield select(orderSmsHistorySelector);
  if (orderId === orderItemId && !isReload) {
    yield put(
      orderSmsHistoryActions.getOrderSmsHistorySuccess({
        data,
        orderItemId,
      }),
    );
    return;
  }

  try {
    const response = yield apiRequest(
        `${process.env.REACT_APP_ADMIN_URL}orders/${orderId}/sms-status-history`
    );
    yield put(
      orderSmsHistoryActions.getOrderSmsHistorySuccess({
        data: response,
        orderItemId: orderId,
      }),
    );
  } catch (e) {}
};

export const orderSmsHistorySaga = function* () {
  yield all([
    takeEvery(
      orderSmsHistoryActions.getOrderSmsHistory.type,
      getOrderStatusSMSSaga,
    ),
  ]);
};
