import React, { useState } from 'react';
import {
  Box,
  IconButton,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../../webAdmin/src/constants/namespaces';
import { ChevronsUp, Map as MapIcon, MapPin } from 'react-feather';
import { Map } from './Map';
import { getCoordinatesOfPolygon } from '@vanongo/utils';
import L from 'leaflet';
import { useGetAreaById } from '../../../webAdmin/src/hooks/areas/useGetAreaById';
import { toast } from '../../toast/SnackbarSingleton';
import { commonErrorToast } from '../../toast/commonErrorToast';

const handleComplexValue = ({ onChange, first, second, value, prevValue }) => {
  // WORKAROUND FOR COPY&PASTE coordinates from google/yandex 43.2173601,76.6639822
  const complex = value.toString().split(',');

  const nextValue = { ...prevValue, [first]: complex[0] };
  if (complex.length === 2 && complex[1]) {
    nextValue[second] = complex[1].trim();
  }
  onChange(nextValue);
};

export const MapPoint = ({
  title,
  error,
  value,
  onChange,
  disabled,
  areaId,
}) => {
  const { t } = useTranslation(NAMESPACES.UI_KIT);
  const {lat, lng} = value || {lat: '', lng: ''};
  const [showMap, changeShowMap] = useState(false);
  const handleShowMap = () => {
    changeShowMap((prevValue) => !prevValue);
  };

  const { data: areaData, isLoading } = useGetAreaById(areaId);

  const handleChangeLat = (e) => {
    handleComplexValue({
      onChange,
      value: e.target.value,
      prevValue: value,
      first: 'lat',
      second: 'lng',
    });
  };
  const handleChangeLong = (e) => {
    handleComplexValue({
      onChange,
      value: e.target.value,
      prevValue: value,
      first: 'lng',
      second: 'lat',
    });
  };
  const errorMessage =
    error &&
    (error.message ||
      (error.lat && error.lat.message) ||
      (error.lng && error.lng.message));

  const handleCenterArea = () => {
    if (isLoading) {
      toast('Area is loading, try again', commonErrorToast());
      return;
    }

    if (!areaData.operationArea) {
      return;
    }

    const regionCoordinates = getCoordinatesOfPolygon(areaData.operationArea);
    const polygon = regionCoordinates.length && L.polygon(regionCoordinates);
    if (!polygon) {
      return;
    }
    onChange(polygon.getBounds().getCenter());
  };
  return (
    <Box>
      <Box className="df jcsb aic">
        {title}
        <Box className="df">
          {areaId && (
            <Tooltip title={t('Set center of area')}>
              <IconButton
                color="secondary"
                onClick={handleCenterArea}
                style={{ marginRight: 12 }}
              >
                <SvgIcon fontSize="medium">
                  <MapPin />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          )}
          <TextField
            style={
              areaId ? {
                marginRight: 5,
              } : {
                marginLeft: 15,
                marginRight: 5,
              }}
            value={lat}
            onChange={handleChangeLat}
            error={!!error}
            label={t('Latitude')}
            disabled={disabled}
            fullWidth
            variant="outlined"
          />
          <TextField
            style={{
              marginRight: 5,
            }}
            value={lng}
            onChange={handleChangeLong}
            error={!!error}
            label={t('Longitude')}
            disabled={disabled}
            fullWidth
            variant="outlined"
          />

          <IconButton color="secondary" onClick={handleShowMap}>
            <SvgIcon fontSize="medium">
              {showMap ? <ChevronsUp /> : <MapIcon />}
            </SvgIcon>
          </IconButton>
        </Box>
      </Box>
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      {showMap && <Map value={value ? value : {lat: '0', lng: '0'}} onChange={onChange} title={title}/>}
    </Box>
  );
};
