import React, { useMemo } from 'react';
import ReactDatePicker from 'react-datepicker';
import format from 'date-fns/format';
import { CustomInput } from './CustomInput';

import 'react-datepicker/dist/react-datepicker.css';

export const DatePicker = ({
  title,
  value,
  onChange,
  error,
  disabled,
  isClearable = true,
}) => {
  const handleChange = (value) => {
    if (!value) {
      return onChange(value);
    }
    onChange(format(value, 'yyyy-MM-dd'));
  };

  const formattedValue = useMemo(() => value && new Date(value), [value]);

  return (
    <ReactDatePicker
      selected={formattedValue}
      onChange={handleChange}
      isClearable={!disabled && isClearable}
      popperClassName="react-date-picker-popover"
      title={title}
      disabled={disabled}
      customInput={<CustomInput error={error} />}
    />
  );
};
