import { all, put, takeEvery } from 'redux-saga/effects';
import { customersCreateActions } from '../../slices/customers/customersCreate';
import { commonRequest } from '../apiSaga';
import history from '../../../services/history';
import { getValidationErrors } from '@vanongo/utils';

const createCustomersSaga = function* ({ payload: data }) {
  try {
    const response = yield commonRequest('postCustomerProfile', {
      body: data,
    });
    yield put(customersCreateActions.createCustomersSuccess());
    history.push(`/customers/${response.userId}`);
  } catch (e) {
    yield put(
      customersCreateActions.createCustomersFailed(getValidationErrors(e)),
    );
  }
};

export const customersCreateSaga = function* () {
  yield all([
    takeEvery(customersCreateActions.createCustomers.type, createCustomersSaga),
  ]);
};
