import React from 'react';
import { makeStyles, Link as MaterialLink } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.link.main,
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

export const Link = ({ type = 'default', className, ...props }) => {
  const classes = useStyles();

  return <MaterialLink className={clsx(className, classes[type])} {...props} />;
};
