import React, { useCallback, useEffect, useState } from 'react';
import {
  Select,
  MenuItem,
  Chip,
  Box,
  makeStyles,
  FormControl,
  CircularProgress,
} from '@material-ui/core';
import { transformOptions } from '@vanongo/components/formComponents/Select/transformOptions';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0,
  },
  chip: {
    marginRight: 2,
    marginBottom: 2,
    backgroundColor: '#FBA506',
    color: '#1791FF',
    fontWeight: 500,
  },
  container: {
    padding: 0,
    maxHeight: 72,
    overflow: 'auto',

    '& fieldset': {
      border: 'none',
    },

    '& svg': {
      color: '#FBA506',
    },
  },
  progress: {
    color: '#FBA506',
  },
}));

export const CitySelector = ({
  onChange,
  options: receivedOptions = [],
  value,
  variant = 'outlined',
  t,
  title,
  isLoading,
}) => {
  const classes = useStyles();

  const [openState, handleOpenState] = useState(false);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const options = transformOptions({ options: receivedOptions, t });
    setOptions(options);
  }, [receivedOptions, t, setOptions]);

  const handleChange = useCallback(
    (e) => {
      onChange(e.target.value, e);
    },
    [onChange],
  );

  const handleClick = (event) => {
    if (openState) return;
    if (['svg', 'path'].includes(event.target.tagName)) {
      return;
    }
    handleOpenState(true);
    event.stopPropagation();
  };

  const hideOptions = () => {
    handleOpenState(false);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
  };

  const onOpen = () => {
    window.addEventListener('click', hideOptions);
  };
  const onClose = () => {
    window.removeEventListener('click', hideOptions);
  };

  return (
    <FormControl
      fullWidth
      variant={variant}
      color="secondary"
      className={classes.container}
      focused={false}
    >
      <Select
        multiple
        value={value}
        onClick={handleClick}
        open={openState}
        label={title}
        onOpen={onOpen}
        onClose={onClose}
        onChange={handleChange}
        inputProps={{
          placeholder: 'Areas',
        }}
        renderValue={(selected) => (
          <Box className={classes.chips}>
            {isLoading ? (
              <CircularProgress className={classes.progress} size={20} />
            ) : (
              selected.map((value) => (
                <Chip
                  key={value}
                  label={(options.find((el) => el.value === value) || {}).label}
                  className={classes.chip}
                />
              ))
            )}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value} onClick={handleMenuClick}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
