import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: vehiclesInfoActions } = createSlice({
  name: 'vehiclesDetails',
  initialState: {
    data: {},
    isLoading: false,
    isUpdating: false,
    isError: false,
  },
  reducers: {
    getVehiclesInfo: (state) => {
      state.isLoading = true;
    },
    getVehiclesInfoSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
    getVehiclesPutError: (state) => {
      state.isError = true;
    },
    updateVehiclesInfo: (state) => {
      state.isUpdating = true;
    },
    updateVehiclesInfoSuccess: (state, { payload }) => {
      state.isUpdating = false;
      state.data = payload;
    },
    updateVehiclesInfoFailed: (state) => {
      state.isUpdating = false;
    },
    removeVehicle: (state) => {
      state.isLoading = true;
    },
  },
});

export const vehiclesInfoSelector = (state) => state.vehicles.vehiclesInfo;
