export const secondsFromTime = (value) => {
  if (!value && value !== 0) {
    return;
  }

  const date = new Date(value);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return hours * 3600 + minutes * 60;
};
