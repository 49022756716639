import React, { useMemo } from 'react';
import { FilterPanel } from './FilterPanel';
import { useLocation } from 'react-router-dom';
import { clearObjectOfEmptyParams } from '@vanongo/utils';
import history from '../../webAdmin/src/services/history';

export const reduceMetadata =
  (query, queryBackMapper = {}) =>
  (total, { name, type }) => {
    const value = query.get(name);
    if (queryBackMapper[name]) {
      const val = queryBackMapper[name](query);
      if (val) {
        total[name] = val;
      }
      return total;
    }
    if (!value) {
      return total;
    }
    total[name] = value;

    if (typeof total[name] === 'string' && total[name].includes(',')) {
      total[name] = total[name].split(',');
    }
    if (type === 'multiselect') {
      total[name] = Array.isArray(total[name]) ? total[name] : [total[name]];
    }

    return total;
  };

export const FilterQueryPanel = ({
  queryMapper,
  queryBackMapper,
  hasTabs,
  ...props
}) => {
  const { search: queryString } = useLocation();

  const values = useMemo(() => {
    const query = new URLSearchParams(queryString);

    const valuesForFilters = (props.metadata || []).reduce(
      reduceMetadata(query, queryBackMapper),
      {},
    );
    if (hasTabs) {
      valuesForFilters.tab = query.get('tab');
    }

    return (props.metadataOnTheFly || []).reduce(
      reduceMetadata(query, queryBackMapper),
      valuesForFilters,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString]);

  const handleChange = (data) => {
    let filters = clearObjectOfEmptyParams(data);
    if (queryMapper) {
      filters = queryMapper(filters);
    }

    const filtersString = new URLSearchParams(filters).toString();

    const filterSearch = filtersString ? `?${filtersString}` : '';

    if (filterSearch === queryString) {
      return;
    }

    history.push({
      search: filterSearch,
    });
  };

  return <FilterPanel {...props} values={values} onChange={handleChange} />;
};
