import React from 'react';
import ReactDOM from 'react-dom';

import './services/firebase';
import App from './App';
import { initLocalization } from './services/localization';
import './services/apiSdk';

initLocalization();
ReactDOM.render(<App />, document.getElementById('root'));
