import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card } from '@material-ui/core';
import {
  solverCreatePopupActions,
  solverCreatePopupSelector,
} from '../../../redux/slices/solver/solverCreate';
import { useTransformMetadata } from '../../../hooks/useTransformMetadata';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../../constants/namespaces';
import { driversVehiclesTableMetadata } from './driversVehiclesMetadata';
import { ButtonConfigRender } from '@vanongo/components/Buttons/ButtonConfigRender';
import { Box } from '@material-ui/core';
import { filterDriversVehiclesMetadata } from './filterDriversVehiclesMetadata';
import { Table } from '@vanongo/components/Table';
import { useListActions } from '../../../hooks/useListActions';
import { FilterPanel } from '@vanongo/components/Filter/FilterPanel';

export const SelectDrivers = ({
  activeStep: { selectedOrders },
  selectedDrivers,
  changeSelectedDrivers,
  closePopup,
  handleBack,
  handleNext,
}) => {
  const { t } = useTranslation(NAMESPACES.ORDERS);
  const { t: tGlobal } = useTranslation(NAMESPACES.GLOBAL);
  const area = useMemo(
    () => selectedOrders[0].pickupStop.areaId,
    [selectedOrders],
  );

  const [filters, changeFilters] = useState({ area });

  const handleFilters = (filters) => {
    changeFilters({ ...filters, area });
  };
  const {
    driverVehiclesPairs,
    driverVehiclesPairsTotalCount,
    driverVehiclesPairsPage,
    driverVehiclesPairsPageSize,
    isDriverVehiclesPairsFetching,
  } = useSelector(solverCreatePopupSelector);
  const { onChangePage, onChangeRowsPerPage, onRefresh } = useListActions({
    action: solverCreatePopupActions.getDriverVehiclesPairs,
    page: driverVehiclesPairsPage,
    pageSize: driverVehiclesPairsPageSize,
    params: filters,
  });

  const metadata = useTransformMetadata({
    metadata: driversVehiclesTableMetadata,
    t,
  });
  const filterMetadata = useTransformMetadata({
    metadata: filterDriversVehiclesMetadata,
    t,
  });

  const leftButton = [{ text: tGlobal('Close'), onClick: closePopup }];
  const buttonConfig = [
    { text: tGlobal('Back'), onClick: handleBack },
    {
      text: tGlobal('Next'),
      color: 'secondary',
      variant: 'contained',
      onClick: handleNext.bind(null, { selectedDrivers }),
      disabled: !selectedDrivers.length,
    },
  ];

  const onDriverSelectionChange = useCallback(
    (selection, item) => {
      changeSelectedDrivers(item);
    },
    [changeSelectedDrivers],
  );

  return (
    <>
      <Card>
        <Box mb={2} height="55vh" width="900px">
          <Card style={{ height: '100%' }}>
            <FilterPanel
              metadataOnTheFly={filterMetadata}
              values={filters}
              onChange={handleFilters}
            />
            <div style={{ height: 'calc(100% - 48px)' }}>
              <Table
                rowSize="oneRowSmall"
                data={driverVehiclesPairs}
                enableRowSelection
                page={driverVehiclesPairsPage}
                pageSize={driverVehiclesPairsPageSize}
                onRefresh={onRefresh}
                rowCount={driverVehiclesPairsTotalCount}
                columns={metadata}
                isLoading={isDriverVehiclesPairsFetching}
                selection={selectedDrivers}
                onSelectionChange={onDriverSelectionChange}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                enableResizing
                tableName="driverPairs"
              />
            </div>
          </Card>
        </Box>
      </Card>

      <ButtonConfigRender buttonConfig={buttonConfig} leftSide={leftButton} />
    </>
  );
};
