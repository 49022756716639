import React, { useCallback, useMemo } from 'react';
import {
  Select,
  MenuItem,
  makeStyles,
  FormControl,
  InputLabel,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { transformOptions } from './transformOptions';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const useStyles = makeStyles(() => ({
  wrapper: {
    minWidth: 100,
  },
  error: {
    fontSize: 12,
    margin: '3px 14px 0',
  },
}));

export const Multiselect = ({
  onChange,
  options: receivedOptions = [],
  value,
  width,
  variant = 'outlined',
  effect = () => ({}),
  effectParams,
  disabled,
  error,
  t,
  title,
  optionsEffect = {isLoading: false, options: undefined},
}) => {
  const classes = useStyles();
  const receivedFromEffect = effect(effectParams);
  const options = optionsEffect?.options?.length || receivedFromEffect.options
    ? transformOptions({ options: optionsEffect?.options || receivedFromEffect.options, t })
    : transformOptions({ options: receivedOptions, t });

  const handleChange = useCallback(
    (e) => {
      onChange(e.target.value, e);
    },
    [onChange],
  );

  const handleMenuClick = (e) => {
    e.stopPropagation();
  };

  const optionsWithNotListOptions = useMemo(() => {
    const notInList = value?.filter(
      (el) => !options.find((option) => option.value === el),
    );

    return options.concat(notInList.map((el) => ({ label: el, value: el })));
  }, [options]);

  return (
    <FormControl
      fullWidth
      variant={variant}
      className={classes.wrapper}
      style={{ maxWidth: width }}
      error={!!error}
    >
      <InputLabel>
        {receivedFromEffect.isLoading ? <CircularProgress size={18} /> : title}
      </InputLabel>
      <Select
        multiple
        value={value}
        label={title}
        onChange={handleChange}
        disabled={disabled}
        MenuProps={MenuProps}
      >
        {optionsWithNotListOptions.map(({ label, value }) => (
          <MenuItem key={value} value={value} onClick={handleMenuClick}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {error && error.message && (
        <Typography className={classes.error} color="error">
          {error.message}
        </Typography>
      )}
    </FormControl>
  );
};
