import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../../../webAdmin/src/constants/namespaces';
import { AggregationPanel } from './AggregationPanel';

const useStyles = makeStyles(() => ({
  footer: {
    display: 'flex',
    alignItems: 'center',

    position: 'sticky',
    bottom: -1,
    zIndex: 1,
  },
  aggregationItem: {
    height: '100%',
    backgroundColor: '#fff',
    borderTop: '1px solid #e0e0e0',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '0 0 0 5px',
  },
  customEllipses: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: 5,
    height: '100%',
    background: '#ffffffb3',
  },
  info: {
    position: 'absolute',
    borderTop: '1px solid #e0e0e0',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
  },
}));

export const Footer = ({
  rowCount,
  columnsWithWidth,
  aggregation,
  enableRowSelection,
  footerHeight,
}) => {
  const { t } = useTranslation(NAMESPACES.GLOBAL);
  const classes = useStyles();
  return (
    <div className={classes.footer} style={{ height: footerHeight }}>
      <AggregationPanel
        columnsWithWidth={columnsWithWidth}
        aggregation={aggregation}
        enableRowSelection={enableRowSelection}
        classes={classes}
        footerHeight={footerHeight}
      />

      <div className={classes.info} style={{ height: footerHeight }}>
        {t('Total items')}: {rowCount}
      </div>
    </div>
  );
};
