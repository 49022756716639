import React, { useCallback } from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

export const FormCheckbox = ({
  title,
  onChange,
  value,
  style,
  disabled,
  className,
}) => {
  const handleChange = useCallback(
    (e) => {
      onChange(e.target.checked);
    },
    [onChange],
  );

  return (
    <FormControlLabel
      className={className}
      control={
        <Checkbox
          color="primary"
          checked={value}
          onChange={handleChange}
          disabled={disabled}
          style={style}
        />
      }
      label={title}
      labelPlacement="end"
    />
  );
};
