import { all, put, takeEvery } from 'redux-saga/effects';
import { driversCreateActions } from '../../slices/drivers/driversCreate';
import { commonRequest } from '../apiSaga';
import history from '../../../services/history';
import { toast } from '@vanongo/components/toast/SnackbarSingleton';

const successToast = (driver) => () =>
  toast(
    `Driver ${driver.firstName} ${driver.lastName} is registered and verified`,
    { variant: 'success' },
  );

const prepareCreateData = ({
  firstName,
  lastName,
  email,
  phone,
  speaksEnglish,
  areaId,
  attributes,
  driverAttributes,
  organization,
}) => {
  const preparedData = {
    firstName,
    lastName,
    email,
    phone,
    speaksEnglish,
    areaId,
    attributes: attributes || [],
    driverAttributes: (driverAttributes || []).map((el) => el.valueId),
    organization: organization
  };

  return preparedData;
};

const createDriversSaga = function* ({ payload: { data } }) {
  try {
    const response = yield commonRequest('createDriver', {
      body: prepareCreateData(data),
    });

    yield put(driversCreateActions.createDriversSuccess());
    history.push(`/drivers`);
    if (response.registrationStatus === 'FINISHED') {
      successToast(data)();
      return;
    }
    toast(
      'Registration is in progress, it can take several minutes to finish.\n You will receive push notification when it finished',
      { variant: 'info', style: { whiteSpace: 'pre-line' } },
    );

  } catch (e) {
    yield put(driversCreateActions.createDriversSuccess());
  }
};

export const driversCreateSaga = function* () {
  yield all([
    takeEvery(driversCreateActions.createDrivers.type, createDriversSaga),
  ]);
};
