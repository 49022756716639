import { all, put, select, takeEvery } from 'redux-saga/effects';
import {
  vehiclesInfoActions,
  vehiclesInfoSelector,
} from '../../slices/vehicles/vehiclesInfo';
import { commonRequest } from '../apiSaga';
import history from '../../../services/history';

const getVehiclesInfoSaga = function* ({ payload }) {
  const { data } = yield select(vehiclesInfoSelector);
  if (payload === data.id) {
    yield put(vehiclesInfoActions.getVehiclesInfoSuccess(data));
    return;
  }

  try {
    const response = yield commonRequest('getVehicleDetails', {
      parameters: { vehicleId: payload },
    });
    yield put(vehiclesInfoActions.getVehiclesInfoSuccess(response));
  } catch (e) {
    yield put(vehiclesInfoActions.getVehiclesInfoSuccess());
  }
};

const updateVehiclesInfoSaga = function* ({ payload: { data, vehicleId } }) {
  try {
    const response = yield commonRequest('updateVehicleDetails', {
      body: data,
      parameters: { vehicleId },
    });

    yield put(vehiclesInfoActions.updateVehiclesInfoSuccess(response));
    history.push(`/vehicles/${vehicleId}`);
  } catch (e) {
    yield put(vehiclesInfoActions.updateVehiclesInfoFailed());
  }
};

const removeVehicleSaga = function* ({ payload }) {
  try {
    yield commonRequest('deleteVehicle', {
      parameters: { vehicleId: payload },
    });

    history.push('/vehicles');
  } catch (e) {
    console.error(e);
  }
};

export const vehiclesInfoSaga = function* () {
  yield all([
    takeEvery(vehiclesInfoActions.getVehiclesInfo.type, getVehiclesInfoSaga),
    takeEvery(
      vehiclesInfoActions.updateVehiclesInfo.type,
      updateVehiclesInfoSaga,
    ),
    takeEvery(vehiclesInfoActions.removeVehicle.type, removeVehicleSaga),
  ]);
};
