import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: areasAttributesActions } = createSlice({
  name: 'areasAttributes',
  initialState: {
    attributes: [],
    isLoading: false,
  },
  reducers: {
    getAreasAttributes: (state) => {
      state.isLoading = true;
    },
    getAreasAttributesSuccess: (state, { payload: { data } }) => {
      state.isLoading = false;
      state.attributes = data;
    },
  },
});

export const areasAttributesSelector = (state) => state.areas.areasAttributes;
