import { reduxHelper } from '@vanongo/utils';
import { api } from '../../services/api';

export const {
  actions: serviceInfoActions,
  reducer: serviceInfo,
  selector: serviceInfoSelector,
  saga: serviceInfoSaga,
} = reduxHelper({
  actionName: 'get',
  name: 'serviceInfo',
  defaultValue: {},
  fetchOnce: false,
  promise: (id) => api.get(`${process.env.REACT_APP_ADMIN_URL}services/${id}`),
});
