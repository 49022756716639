import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: areasPricingPolygonsActions } = createSlice({
  name: 'areasPricingPolygons',
  initialState: {
    areasPricingPolygons: [],
    isLoading: false,
    isUpdating: false,
    isCreating: false,
    selectedValue: {},
  },
  reducers: {
    getAreasPricingPolygons: (state) => {
      state.isLoading = true;
    },
    getAreasPricingPolygonsSuccess: (state, { payload: { data } }) => {
      state.areasPricingPolygons = data;
      state.isLoading = false;
      state.selectedValue = {};
    },
    selectValue: (state, { payload: { data } }) => {
      state.isLoading = false;
      state.selectedValue = data;
    },
    createPricingPolygon: (state) => {
      state.isCreating = true;
    },
    createPricingPolygonSuccess: (state, { payload: { data } }) => {
      state.isCreating = false;
      state.areasPricingPolygons.push(data);
      state.selectedValue = data;
    },
    createPricingPolygonFailed: (state) => {
      state.isCreating = false;
    },
    editPricingPolygon: (state) => {
      state.isUpdating = true;
    },
    editPricingPolygonSuccess: (state, { payload: { data } }) => {
      state.isUpdating = false;
      const index = state.areasPricingPolygons.findIndex(
        (item) => item.id === data.id,
      );
      state.areasPricingPolygons[index] = data;
      state.selectedValue = data;
    },
    editPricingPolygonFailed: (state) => {
      state.isUpdating = false;
    },
    deletePricingPolygon: () => {},
    deletePricingPolygonSuccess: () => {},
    deletePricingPolygonFailed: () => {},
    reorderPricingPolygon: (state) => {
      state.isUpdating = true;
    },
    reorderPricingPolygonSuccess: (state) => {
      state.isUpdating = false;
    },
    reorderPricingPolygonFailed: (state) => {
      state.isUpdating = false;
    },
  },
});

export const areasPricingPolygonsSelector = (state) =>
  state.areas.areasPricingPolygons;
