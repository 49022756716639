import { object, string } from 'yup';

export const validationSchema = object().shape({
  startLocation: object().nullable().required().shape({
    lat: string().required(),
    lng: string().required(),
  }),
  finishLocation: object()
    .nullable()
    .when('useFinishLocation', {
      is: true,
      then: object().nullable().required().shape({
        lat: string().required(),
        lng: string().required(),
      }),
      otherwise: object().nullable(),
    }),
});

export const configurationDriverMetadata = [
  {
    name: 'helpers',
    title: 'Helpers',
    type: 'string',
    md: 12,
  },
  {
    name: 'start',
    title: 'Operation time start',
    type: 'time',
  },
  {
    name: 'end',
    title: 'Operation time end',
    type: 'time',
  },
  {
    name: 'startLocation',
    type: 'mapPoint',
    title: 'Start position',
    md: 12,
  },
  {
    name: 'useFinishLocation',
    type: 'checkbox',
    title: 'Use finish location',
    md: 12,
  },
  {
    name: 'finishLocation',
    type: 'mapPoint',
    title: 'Finish position',
    md: 12,
  },
];
