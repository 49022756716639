import { createTheme, colors } from '@material-ui/core';
import _ from 'lodash';
import typography from './typography';
import { softShadows } from './shadows';

export const THEMES = {
  LIGHT: 'LIGHT',
};

const baseOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
  },
};

const lightTheme = {
  name: 'LIGHT',
  overrides: {
    MuiInputBase: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: colors.blueGrey[600],
        },
      },
    },
  },
  palette: {
    type: 'light',
    action: {
      active: colors.blueGrey[600],
    },
    background: {
      default: colors.common.white,
      dark: '#f4f6f8',
      paper: colors.common.white,
    },
    primary: {
      main: colors.indigo[600],
    },
    secondary: {
      main: '#0c8bf7',
    },
    error: {
      main: colors.red[600],
      contrastText: colors.common.white,
      dark: colors.red[700],
      light: colors.red[200],
    },
    link: {
      main: '#1791FF',
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: '#899194',
      error: colors.red[600],
    },
  },
  shadows: softShadows,
};

export const theme = createTheme(
  _.merge({}, baseOptions, lightTheme, { direction: 'ltr' }),
);
