import React from 'react';
import { Typography } from '@material-ui/core';

export const NameCellRender = ({ name, firstName, lastName, email }) => {
  return (
    <div>
      <Typography variant="h6" color="inherit" style={{ fontWeight: 'bold' }}>
        {name ? name : `${firstName} ${lastName}`}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {email}
      </Typography>
    </div>
  );
};
