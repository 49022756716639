import { all, takeEvery, put, select, takeLatest } from 'redux-saga/effects';
import { ordersListActions } from '../../slices/orders/ordersList';
import { commonRequest } from '../apiSaga';
import { areasFilterValueSelector } from '../../slices/global/areasFilter';
import {
  filterMetadata,
  filterMetadataOnTheFly,
} from '../../../views/orders/OrdersList/filterListMetadata';
import { toast } from '@vanongo/components/toast/SnackbarSingleton';
import { localization } from '../../../services/localization';
import {
  sortingParam,
  backendTime,
  timeStringFromSeconds,
  getObjFromQuery,
  createQueryParams,
  downloadBlob,
} from '@vanongo/utils';
import { popupActions } from '../../slices/popup';
import { apiRequest } from '../../../services/apiSdk';
import { api } from '../../../services/api';
import { commonErrorToast } from '@vanongo/components';
import axios from 'axios';
import {
  B2B_API_ORDER_CREATE_URL,
  B2B_AUTH_API_URL,
} from '../../../constants/app';
import { partnersInfoSelector } from '../../slices/partners/partnersInfo';

// export const apiPartner = new HttpClient(() => store.getState().authorization.token, commonError );

export const createFilters = (query) => {
  if (!query) {
    return {};
  }

  if (!Object.keys(query).length) {
    return {};
  }

  const metadata = [...filterMetadata, ...filterMetadataOnTheFly];
  const filters = Object.entries(query).reduce((total, [key, value]) => {
    if (key === 'minWeight' || key === 'maxWeight') {
      if (!total.weight) {
        total.weight = `${query.minWeight || 0},${query.maxWeight || 10000}`;
      }

      return total;
    }

    if (key === 'flags') {
      if (value.includes(1) && !value.includes(2)) {
        total.validatedAddress = 'true';
      }

      if (value.includes(2) && !value.includes(1)) {
        total.validatedAddress = 'false';
      }

      if (value.includes(3) && !value.includes(4)) {
        total.outsideOfArea = 'true';
      }

      if (value.includes(4) && !value.includes(3)) {
        total.outsideOfArea = 'false';
      }

      if (value.includes(5) && !value.includes(6)) {
        total.isReturn = 'true';
      }

      if (value.includes(6) && !value.includes(5)) {
        total.isReturn = 'false';
      }

      return total;
    }

    if (key === 'externalCargoIds') {
      return total;
    }

    const findItem = metadata.find((el) => el.name === key);
    if (findItem) {
      total[key] = value;
    }

    return total;
  }, {});

  if (query.dateType && (query.dateFrom || query.dateTo)) {
    if (
      ['requestedDeliveryPeriod', 'expectedDeliveryPeriod'].includes(
        query.dateType,
      )
    ) {
      filters[
        `${query.dateExact ? '&&' : '<@'}${query.dateType}`
      ] = `[${backendTime(+query.dateFrom || 0)},${backendTime(
        +query.dateTo || 9999999999999,
      )}]`;
    } else {
      if (query.dateFrom) {
        filters[`>=${query.dateType}`] = backendTime(+query.dateFrom);
      }
      if (query.dateTo) {
        filters[`<=${query.dateType}`] = backendTime(+query.dateTo);
      }
    }
  }

  if (query.tab && query.tab !== 'all') {
    filters.userType = query.tab.toUpperCase();
  }

  if (query.waybillId) {
    filters.waybillId = query.waybillId;
  }

  return filters;
};

export const createOrderRequestParams = ({
  page,
  pageSize,
  area,
  queryString,
  sorting,
}) => {
  const query = getObjFromQuery(queryString);
  let body;
  let operationId = 'getOrders';

  if (query.externalCargoIds) {
    body = query.externalCargoIds.split(/[/\s/\n,;]/).filter((el) => el);

    operationId = 'postOrders';
  }

  return [
    operationId,
    {
      body,
      parameters: {
        page,
        limit: pageSize,
        area: area,
        ...createFilters(query),
        sorting: sortingParam(sorting),
      },
    },
  ];
};

const prepareAggregation = (aggregation) => {
  if (!aggregation) {
    return {};
  }

  return {
    totalCargoWeight: (+aggregation.weight).toFixed(0),
    volume: (+aggregation.volume).toFixed(0),
    delay: timeStringFromSeconds(aggregation.delay),
  };
};

const getOrdersListSaga = function* ({
  payload: { pageSize = 10, page = 1, queryString, sorting } = {
    pageSize: 10,
    page: 1,
  },
}) {
  const area = yield select(areasFilterValueSelector);

  try {
    const response = yield commonRequest(
      ...createOrderRequestParams({
        page,
        pageSize,
        area,
        queryString,
        sorting,
      }),
    );

    yield put(
      ordersListActions.getOrdersListSuccess({
        data: response.results,
        page: response.currentPage,
        totalCount: response.totalCount,
        pageSize,
        aggregation: prepareAggregation(response.aggregations),
      }),
    );
  } catch (e) {
    console.error(e);
  }
};

const changeStatusSaga = function* ({ payload: { id, comment, status } }) {
  const ids = Array.isArray(id) ? id : [id];
  try {
    yield put(popupActions.closePopup());
    yield commonRequest('updateStatusOrder', {
      body: {
        status,
        reason: comment,
        order_items: ids,
      },
    });

    toast(localization.t('Order status changed successfully'), {
      variant: 'success',
    });
    yield put(ordersListActions.changeStatusSuccess({ status, ids }));
  } catch (e) {
    console.log(e);
  }
};

const exportOrdersSaga = function* ({ payload }) {
  try {
    const response = yield commonRequest('exportWaybills', {
      body: { orders: payload.map(({ id }) => id) },
    });

    downloadBlob('', '', response.url);
    yield put(ordersListActions.reportExportExportSuccess());
  } catch (e) {
    yield put(ordersListActions.reportExportExportSuccess());
    console.error(e);
  }
};

const selectAllOrdersSaga = function* ({
  payload: { queryString, areaFilter, limit, page },
}) {
  const query = {
    ...createFilters(getObjFromQuery(queryString)),
    area: areaFilter,
    limit,
    page,
  };
  const queryValue = createQueryParams(query);

  try {
    const response = yield apiRequest(
      `${process.env.REACT_APP_ADMIN_URL}orders/select${queryValue}`,
    );
    yield put(
      ordersListActions.selectAllOrdersSuccess({
        data: response,
      }),
    );
  } catch (e) {
    console.log(e);
  }
};

const importFinishedOrdersSaga = function* ({ payload: data }) {
  const partnersInfo = yield select(partnersInfoSelector);
  const indexWarehouseAddresses =
    partnersInfo.data.warehouseAddresses.findIndex(
      (item) => item.originalAddressString === data.values.address,
    );

  try {
    const formData = new FormData();
    formData.append('organizationId', data.values.partner);
    formData.append('areaId', data.values.area);
    if (partnersInfo.data.warehouseAddresses[indexWarehouseAddresses]) {
      formData.append(
        'warehouseAddress',
        JSON.stringify(
          partnersInfo.data.warehouseAddresses[indexWarehouseAddresses],
        ),
      );
    }
    formData.append('file', data.files[0]);

    const response = yield api.post(
      `${process.env.REACT_APP_ADMIN_URL}orders/import`,
      {
        json: false,
        body: formData,
      },
    );

    toast(localization.t('File imported successfully'), {
      variant: 'success',
    });

    yield put(
      ordersListActions.importFinishedOrdersSuccess({ data: response }),
    );
  } catch (e) {
    console.log('ERROR', e);
    yield put(
      ordersListActions.importFinishedOrdersFailed({ error: e.response.body }),
    );
  }
};

const rateUsOrdersSaga = function* ({ payload: selection }) {
  try {
    yield api.post(`${process.env.REACT_APP_ADMIN_URL}orders/rate-us`, {
      body: { orders: selection.map(({ id }) => id) },
    });

    toast(localization.t('Rate us request successful'), {
      variant: 'success',
    });
  } catch (e) {
    console.error(e);
  }
};

const createOrderSaga = function* ({ payload: data }) {
  try {
    const authorization = yield api.get(B2B_AUTH_API_URL);

    yield axios({
      method: 'post',
      url: B2B_API_ORDER_CREATE_URL,
      data: {
        externalOrderId: data.externalOrderId,
        comments: data.comments,
        orderItem: {
          cargo: [
            {
              weight: data.weight,
              volume: data.volume,
              partnerCargoId: data.externalOrderId,
            },
          ],
          pickupAddress: {
            fullAddress: data.pickupAddress,
          },
          pickupContactPerson: {
            firstName: data.pickupContactPersonName,
            phone: data.pickupContactPersonPhone,
          },
          pickupDatetimePeriod: {
            startDatetime: data.pickupDatetimePeriod.from,
            endDatetime: data.pickupDatetimePeriod.to,
          },
          deliveryAddress: {
            fullAddress: data.deliveryAddress,
          },
          deliveryContactPerson: {
            firstName: data.deliveryContactName,
            phone: data.deliveryContactPhone,
          },
          deliveryDatetimePeriod: {
            startDatetime: data.deliveryDatetimePeriod.from,
            endDatetime: data.deliveryDatetimePeriod.to,
          },
        },
      },
      headers: {
        Authorization: authorization,
      },
    });

    yield put(popupActions.closePopup());

    yield toast(localization.t('Order created'), {
      variant: 'success',
    });
    yield put(ordersListActions.createOrderSuccess());
    yield put(
      ordersListActions.getOrdersList({
        pageSize: 10,
        page: 1,
      }),
    );
  } catch (e) {
    toast(localization.t('Something went wrong'), commonErrorToast());
    console.error(e);
  }
};

export const ordersListSaga = function* () {
  yield all([
    takeLatest(ordersListActions.getOrdersList.type, getOrdersListSaga),
    takeEvery(ordersListActions.changeStatus.type, changeStatusSaga),
    takeEvery(ordersListActions.reportExport.type, exportOrdersSaga),
    takeEvery(ordersListActions.selectAllOrders.type, selectAllOrdersSaga),
    takeEvery(
      ordersListActions.importFinishedOrders.type,
      importFinishedOrdersSaga,
    ),
    takeEvery(ordersListActions.rateUsOrders.type, rateUsOrdersSaga),
    takeEvery(ordersListActions.createOrder.type, createOrderSaga),
  ]);
};
