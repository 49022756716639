import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import {
  PARTNERS,
  PARTNERS_CREATE,
  AREAS,
  AREAS_CREATE,
  AREAS_EDIT_REGION,
  AREAS_EDIT_CENTER,
  AREAS_EDIT_INFO,
  AREAS_INFO,
  DRIVERS,
  DRIVERS_INFO,
  DRIVERS_CREATE,
  VEHICLES,
  VEHICLES_CREATE,
  VEHICLES_DETAILS,
  VEHICLES_EDIT_INFO,
  ORDERS,
  ORDERS_CREATE,
  ORDERS_DETAILS,
  ORDERS_EDIT_DELIVERY_INFO,
  CUSTOMERS,
  CUSTOMERS_CREATE,
  CUSTOMERS_EDIT_INFO,
  CUSTOMERS_INFO,
  SOLVER,
  SOLVER_INFO,
  CARGO,
  CARGO_DETAILS,
  CARGO_EDIT_DETAILS,
  WAYBILLS,
  ORDERS_EDIT_PICK_UP_INFO,
  WAYBILLS_DETAILS,
  SERVICES,
  SERVICES_CREATE,
  SERVICES_DETAILS,
  AREAS_ADD_POLYGON,
  AREAS_EDIT_POLYGON,
  VOUCHERS,
  VOUCHERS_CREATE,
  DRIVERS_DEBTOR,
  GROUPS,
  GROUPS_CREATE,
  GROUPS_EDIT,
  STATISTICS,
  SMS_CONFIGURATION,
  SMS_CONFIGURATION_CREATE,
  MAP,
} from './constants/routes';

export const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() =>
      import('packages/webAdmin/src/views/errors/NotFoundView'),
    ),
  },
  {
    path: '*',
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/drivers" />,
      },
      {
        exact: true,
        path: DRIVERS,
        component: lazy(() =>
          import('packages/webAdmin/src/views/drivers/DriverList'),
        ),
      },
      {
        exact: true,
        path: DRIVERS_CREATE,
        component: lazy(() =>
          import('packages/webAdmin/src/views/drivers/DriversCreate'),
        ),
      },
      {
        exact: true,
        path: '/drivers/:driverId/edit-info',
        component: lazy(() =>
          import('packages/webAdmin/src/views/drivers/DriverEditInfo'),
        ),
      },
      {
        exact: true,
        path: '/drivers/:driverId/edit-schedule',
        component: lazy(() =>
          import('packages/webAdmin/src/views/drivers/DriverEditSchedule'),
        ),
      },
      {
        exact: true,
        path: DRIVERS_INFO,
        component: lazy(() =>
          import('packages/webAdmin/src/views/drivers/DriverDetails'),
        ),
      },
      {
        exact: true,
        path: PARTNERS_CREATE,
        component: lazy(() =>
          import(
            'packages/webAdmin/src/views/partners/partners/PartnersCreate'
          ),
        ),
      },
      {
        exact: true,
        path: '/partners',
        component: () => <Redirect to="/partners/partners" />,
      },
      {
        exact: true,
        path: PARTNERS,
        component: lazy(() =>
          import('packages/webAdmin/src/views/partners/List'),
        ),
      },
      {
        exact: true,
        path: '/partners/details/:partnerId',
        component: lazy(() =>
          import(
            'packages/webAdmin/src/views/partners/partners/PartnersDetails'
          ),
        ),
      },
      {
        exact: true,
        path: '/partners/:partnerId/edit-info',
        component: lazy(() =>
          import(
            'packages/webAdmin/src/views/partners/partners/PartnersEditInfo'
          ),
        ),
      },
      {
        exact: true,
        path: CUSTOMERS,
        component: lazy(() =>
          import('packages/webAdmin/src/views/customers/CustomersList'),
        ),
      },
      {
        exact: true,
        path: CUSTOMERS_CREATE,
        component: lazy(() =>
          import('packages/webAdmin/src/views/customers/CustomersCreate'),
        ),
      },
      {
        exact: true,
        path: CUSTOMERS_INFO,
        component: lazy(() =>
          import('packages/webAdmin/src/views/customers/CustomersDetails'),
        ),
      },
      {
        exact: true,
        path: CUSTOMERS_EDIT_INFO,
        component: lazy(() =>
          import('packages/webAdmin/src/views/customers/CustomersEditInfo'),
        ),
      },
      {
        exact: true,
        path: AREAS,
        component: lazy(() =>
          import('packages/webAdmin/src/views/areas/AreasList'),
        ),
      },
      {
        exact: true,
        path: AREAS_CREATE,
        component: lazy(() =>
          import('packages/webAdmin/src/views/areas/AreasCreate'),
        ),
      },
      {
        exact: true,
        path: '/areas/:areaId',
        component: lazy(() =>
          import('packages/webAdmin/src/views/areas/AreasDetails'),
        ),
      },
      {
        exact: true,
        path: AREAS_INFO,
        component: lazy(() =>
          import('packages/webAdmin/src/views/areas/AreasDetails'),
        ),
      },
      {
        exact: true,
        path: AREAS_EDIT_INFO,
        component: lazy(() =>
          import('packages/webAdmin/src/views/areas/AreasEditInfo'),
        ),
      },
      {
        exact: true,
        path: AREAS_EDIT_REGION,
        component: lazy(() =>
          import('packages/webAdmin/src/views/areas/AreasEditRegion'),
        ),
      },
      {
        exact: true,
        path: AREAS_EDIT_CENTER,
        component: lazy(() =>
          import('packages/webAdmin/src/views/areas/AreasEditCenter'),
        ),
      },
      {
        exact: true,
        path: AREAS_ADD_POLYGON,
        component: lazy(() =>
          import(
            'packages/webAdmin/src/views/areas/AreasDetails/PricingPolygons/CreateAreaPricingPolygon'
          ),
        ),
      },
      {
        exact: true,
        path: AREAS_EDIT_POLYGON,
        component: lazy(() =>
          import(
            'packages/webAdmin/src/views/areas/AreasDetails/PricingPolygons/EditAreaPricingPolygon'
          ),
        ),
      },
      {
        exact: true,
        path: VEHICLES,
        component: lazy(() =>
          import('packages/webAdmin/src/views/vehicles/VehiclesList'),
        ),
      },
      {
        exact: true,
        path: VEHICLES_CREATE,
        component: lazy(() =>
          import('packages/webAdmin/src/views/vehicles/VehiclesCreate'),
        ),
      },
      {
        exact: true,
        path: VEHICLES_DETAILS,
        component: lazy(() =>
          import('packages/webAdmin/src/views/vehicles/VehiclesDetails'),
        ),
      },
      {
        exact: true,
        path: VEHICLES_EDIT_INFO,
        component: lazy(() =>
          import('packages/webAdmin/src/views/vehicles/VehiclesEditInfo'),
        ),
      },
      {
        exact: true,
        path: ORDERS,
        component: lazy(() =>
          import('packages/webAdmin/src/views/orders/OrdersList'),
        ),
      },
      {
        exact: true,
        path: ORDERS_CREATE,
        component: lazy(() =>
          import('packages/webAdmin/src/views/orders/OrdersCreate'),
        ),
      },
      {
        exact: true,
        path: ORDERS_DETAILS,
        component: lazy(() =>
          import('packages/webAdmin/src/views/orders/OrdersDetails'),
        ),
      },
      {
        exact: true,
        path: ORDERS_EDIT_DELIVERY_INFO,
        component: lazy(() =>
          import('packages/webAdmin/src/views/orders/OrdersEditDeliveryInfo'),
        ),
      },
      {
        exact: true,
        path: ORDERS_EDIT_PICK_UP_INFO,
        component: lazy(() =>
          import('packages/webAdmin/src/views/orders/OrdersEditPickUpInfo'),
        ),
      },
      {
        exact: true,
        path: WAYBILLS,
        component: lazy(() =>
          import('packages/webAdmin/src/views/waybills/WaybillsList'),
        ),
      },
      {
        exact: true,
        path: WAYBILLS_DETAILS,
        component: lazy(() =>
          import('packages/webAdmin/src/views/waybills/WaybillsDetails'),
        ),
      },
      {
        exact: true,
        path: SOLVER,
        component: lazy(() =>
          import('packages/webAdmin/src/views/solver/SolverList'),
        ),
      },
      {
        exact: true,
        path: CARGO,
        component: lazy(() =>
          import('packages/webAdmin/src/views/cargo/CargoList'),
        ),
      },
      {
        exact: true,
        path: CARGO_DETAILS,
        component: lazy(() =>
          import('packages/webAdmin/src/views/cargo/CargoDetails'),
        ),
      },
      {
        exact: true,
        path: CARGO_EDIT_DETAILS,
        component: lazy(() =>
          import('packages/webAdmin/src/views/cargo/CargoEditDetails'),
        ),
      },
      {
        exact: true,
        path: SOLVER_INFO,
        component: lazy(() =>
          import('packages/webAdmin/src/views/solver/SolverDetails'),
        ),
      },
      {
        exact: true,
        path: SERVICES,
        component: lazy(() =>
          import('packages/webAdmin/src/views/services/List'),
        ),
      },
      {
        exact: true,
        path: SERVICES_CREATE,
        component: lazy(() =>
          import(
            'packages/webAdmin/src/views/services/b2c/ServicesB2cCreate/CreateB2cService'
          ),
        ),
      },
      {
        exact: true,
        path: SERVICES_DETAILS,
        component: lazy(() =>
          import('packages/webAdmin/src/views/services/b2c/ServiceB2cDetails'),
        ),
      },
      {
        exact: true,
        path: VOUCHERS,
        component: lazy(() =>
          import('packages/webAdmin/src/views/vouchers/VouchersList'),
        ),
      },
      {
        exact: true,
        path: VOUCHERS_CREATE,
        component: lazy(() =>
          import('packages/webAdmin/src/views/vouchers/VouchersCreate'),
        ),
      },
      {
        exact: true,
        path: DRIVERS_DEBTOR,
        component: lazy(() =>
          import('packages/webAdmin/src/views/driversDebtor/DriversDebtor'),
        ),
      },
      {
        exact: true,
        path: '/statistics',
        component: () => <Redirect to="/statistics/delivery-dynamics" />,
      },
      {
        exact: true,
        path: STATISTICS,
        component: lazy(() =>
          import('packages/webAdmin/src/views/statistics/Statistics'),
        ),
      },
      {
        exact: true,
        path: MAP,
        component: lazy(() => import('packages/webAdmin/src/views/Map')),
      },
      {
        exact: true,
        path: '/gr',
        component: () => <Redirect to="/gr/groups" />,
      },
      {
        exact: true,
        path: GROUPS,
        component: lazy(() => import('packages/webAdmin/src/views/groups')),
      },
      {
        exact: true,
        path: GROUPS_CREATE,
        component: lazy(() =>
          import('packages/webAdmin/src/views/groups/Create'),
        ),
      },
      {
        exact: true,
        path: GROUPS_EDIT,
        component: lazy(() =>
          import('packages/webAdmin/src/views/groups/Edit'),
        ),
      },
      {
        exact: true,
        path: SMS_CONFIGURATION,
        component: lazy(() =>
          import('packages/webAdmin/src/views/smsConfiguration'),
        ),
      },
      {
        exact: true,
        path: SMS_CONFIGURATION_CREATE,
        component: lazy(() =>
          import(
            'packages/webAdmin/src/views/smsConfiguration/createSmsConfig'
          ),
        ),
      },
      {
        path: '*',
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];
