import { createSlice } from '@reduxjs/toolkit';

export const { reducer, actions: customersListActions } = createSlice({
  name: 'customersList',
  initialState: {
    customers: [],
    page: 0,
    totalCount: 0,
    isLoading: false,
    pageSize: 10,
  },
  reducers: {
    getCustomersList: (state) => {
      state.isLoading = true;
    },
    getCustomersListSuccess: (
      state,
      { payload: { data, page, totalCount, pageSize } },
    ) => {
      state.isLoading = false;
      state.customers = data;
      state.page = page - 1;
      state.totalCount = totalCount;
      state.pageSize = pageSize;
    },
  },
});

export const customersListSelector = (state) => state.customers.customersList;
