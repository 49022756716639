import React, { useRef, useState } from 'react';
import PopupLayout from '../PopupLayout/PopupLayout';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../constants/namespaces';
import { useReactToPrint } from 'react-to-print';
import { makeStyles } from '@material-ui/core';
import { PrintContainer } from './PrintContainer';
import { NoteForm } from './NoteForm';

const useStyles = makeStyles((theme) => ({
  popupWrapper: {
    margin: -20,
    height: '50vh',
    overflow: 'hidden',
    'overflow-y': 'auto',
  },
  container: {
    margin: 40,
  },
  bigSize: {
    fontSize: 24,
  },
  largeSize: {
    fontSize: 36,
  },
  rightBarcode: {
    transform: 'rotate(90deg)',
    width: 60,
  },
}));

export const ConsignmentNote = ({
  closePopup,
  cargo: { externalId, weight },
  deliveryStop,
  deliveryContactPersonDTO,
  orderItemDTO,
}) => {
  const { t } = useTranslation(NAMESPACES.ORDERS);
  const { t: tGlobal } = useTranslation(NAMESPACES.GLOBAL);
  const printRef = useRef();
  const formRef = useRef();
  const classes = useStyles();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const [step, changeStep] = useState(1);
  const [formData, setFormData] = useState({});

  const formButtons = [
    { text: tGlobal('Cancel'), onClick: closePopup },
    {
      color: 'secondary',
      type: 'submit',
      variant: 'contained',
      text: tGlobal('Next'),
      onClick: () => {
        const values = formRef.current.getValues();
        setFormData(values);
        changeStep(2);
      },
    },
  ];

  const printButtons = [
    {
      text: tGlobal('Back'),
      onClick: () => {
        changeStep(1);
      },
    },
    {
      color: 'secondary',
      type: 'submit',
      variant: 'contained',
      text: t('Print'),
      onClick: handlePrint,
    },
  ];

  return (
    <PopupLayout
      width={548}
      title={t('Consignment note')}
      buttonConfig={step === 1 ? formButtons : printButtons}
    >
      <div className={classes.popupWrapper}>
        {step === 1 && <NoteForm formRef={formRef} formData={formData} />}
        {step === 2 && (
          <PrintContainer
            componentRef={printRef}
            externalId={externalId}
            weight={weight}
            deliveryStop={deliveryStop}
            deliveryContactPersonDTO={deliveryContactPersonDTO}
            orderItemDTO={orderItemDTO}
            classes={classes}
            formData={formData}
          />
        )}
      </div>
    </PopupLayout>
  );
};
