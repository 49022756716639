import { useDispatch, useSelector } from 'react-redux';
import {
  areasAttributesSelector,
  areasAttributesActions,
} from '../../redux/slices/areas/areasAttributes';
import { useEffect } from 'react';

let prevAreaId = null;

export const useAreaAttributes = (areaId) => {
  const dispatch = useDispatch();
  const { attributes, isLoading } = useSelector(areasAttributesSelector);

  useEffect(() => {
    // TODO move if expression to redux
    if (!isLoading && areaId !== prevAreaId) {
      prevAreaId = areaId;
      dispatch(areasAttributesActions.getAreasAttributes({ areaId }));
    }
  }, [dispatch, isLoading, areaId]);

  return {
    attributes,
    isLoading,
  };
};
