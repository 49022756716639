import { all, takeLatest, put } from 'redux-saga/effects';
import { servicesListActions } from '../../slices/services/servicesList';
import { api } from '../../../services/api';
import { getValidationErrors } from '@vanongo/utils';
import { popupActions } from '../../slices/popup';

const prepareFormData = ({
  condition,
  description,
  isActive,
  isMandatory,
  name,
  pricingModel,
  unitOfMeasure,
}) => {
  return {
    condition,
    description,
    isActive,
    isMandatory,
    name,
    pricingModel,
    unitOfMeasure,
  };
};

const getListSaga = function* ({
  payload: { pageSize = 10, page = 1 } = {
    pageSize: 10,
    page: 1,
  },
}) {
  try {
    const response = yield api.get(
      `${process.env.REACT_APP_ADMIN_URL}services`,
      {
        query: {
          page,
          limit: pageSize,
        },
      },
    );

    yield put(
      servicesListActions.getListSuccess({
        data: response.results,
        page: response.currentPage,
        pageSize,
        totalCount: response.totalCount,
      }),
    );
  } catch (e) {}
};

const createSaga = function* ({ payload: data }) {
  try {
    yield api.post(`${process.env.REACT_APP_ADMIN_URL}services`, {
      body: data,
    });

    yield put(popupActions.closePopup());
    yield put(servicesListActions.createSuccess());
  } catch (e) {
    yield put(servicesListActions.createFailed(getValidationErrors(e)));
  }
};

const updateSaga = function* ({ payload: { formData, serviceId } }) {
  try {
    const response = yield api.patch(
      `${process.env.REACT_APP_ADMIN_URL}services/${serviceId}`,
      {
        body: prepareFormData(formData),
      },
    );

    yield put(popupActions.closePopup());
    yield put(servicesListActions.updateSuccess(response));
  } catch (e) {
    yield put(servicesListActions.updateFailed(getValidationErrors(e)));
  }
};

const deleteSaga = function* ({ payload: selection }) {
  try {
    yield Promise.all(
      selection.map(({ id }) =>
        api.delete(`${process.env.REACT_APP_ADMIN_URL}services/${id}`),
      ),
    );

    yield put(servicesListActions.deleteSuccess());
  } catch (e) {}
};
export const servicesListSaga = function* () {
  yield all([
    takeLatest(servicesListActions.getList.type, getListSaga),
    takeLatest(servicesListActions.create.type, createSaga),
    takeLatest(servicesListActions.createSuccess.type, getListSaga),
    takeLatest(servicesListActions.deleteSuccess.type, getListSaga),
    takeLatest(servicesListActions.update.type, updateSaga),
    takeLatest(servicesListActions.delete.type, deleteSaga),
  ]);
};
