export const parsePointCoordinates = (location) => {
  if (!location) {
    return null;
  }

  const string = location.slice(1, location.length - 1);
  const [lng, lat] = string.split(',');

  return { lat, lng };
};

export const joinPointCoordinates = (location) => {
  if (!location) {
    return '';
  }

  const { lng, lat } = location;

  if (!lng || !lat) {
    return '';
  }
  return `(${lng},${lat})`;
};
