import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  Container,
  Button,
  makeStyles,
} from '@material-ui/core';
import Page from '@vanongo/components/Page';
import Logo from '@vanongo/components/Logo';
import { isTokenPendingSelector } from '../../redux/slices/authorization';
import { authorizationActions } from 'packages/webAdmin/src/redux/slices/authorization';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../constants/namespaces';
import LoadingScreen from '@vanongo/components/LoadingScreen';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'center',
  },
  cardContent: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    minHeight: 280,
  },
}));

const LoginView = () => {
  const { t } = useTranslation(NAMESPACES.GLOBAL);
  const classes = useStyles();

  const isPending = useSelector(isTokenPendingSelector);
  const dispatch = useDispatch();

  const signIn = useCallback(() => {
    dispatch(authorizationActions.signIn());
  }, [dispatch]);

  if (isPending) {
    return <LoadingScreen />;
  }

  return (
    <Page className={classes.root} title="Login">
      <Container maxWidth="sm">
        <Card>
          <CardContent className={classes.cardContent}>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="center"
              mt={6}
            >
              <Logo width="300px" />
            </Box>
            <Box mt={8}>
              <Button
                color="secondary"
                disabled={isPending}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={signIn}
              >
                {t('Log In')}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default LoginView;
