import React from 'react';
import { CustomIcon } from '@vanongo/components/CustomIcon/CustomIcon';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../../constants/namespaces';
import { makeStyles } from '@material-ui/core';
import history from '../../../services/history';

const useStyles = makeStyles(() => ({
  item: {
    marginRight: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',

    '&:last-child': {
      marginRight: 0,
    },
  },
}));

export const SubMenuItem = ({
  title,
  customIcon,
  href,
  iconHeight = 60,
  iconWidth = 60,
}) => {
  const { t } = useTranslation(NAMESPACES.MENU);
  const classes = useStyles();

  const handleClick = () => {
    history.push(href);
  };

  return (
    <div className={classes.item} key={href} onClick={handleClick}>
      {customIcon && (
        <CustomIcon glyph={customIcon} height={iconHeight} width={iconWidth} />
      )}
      <span style={{ whiteSpace: 'nowrap' }}>{t(title)}</span>
    </div>
  );
};
