import { reduxHelper } from '@vanongo/utils';
import { api } from '../../services/api';

export const {
  actions: driverGroupsListBriefOptionsActions,
  reducer: driverGroupsListBriefOptions,
  selector: driverGroupsListBriefOptionsSelector,
  saga: driverGroupsListBriefOptionsSaga,
} = reduxHelper({
  actionName: 'getDriverGroupsListBriefOptions',
  name: 'driverGroupsListBriefOptions',
  promise: () =>
    api.get(`${process.env.REACT_APP_ADMIN_URL}driver-groups/list-brief`),
  fetchOnce: false,
});
