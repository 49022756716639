import React from 'react';
import { IconButton, SvgIcon } from '@material-ui/core';
import { XCircle } from 'react-feather';
import { closeToast } from './SnackbarSingleton';

export const commonErrorToast = () => {
  const onClickDismiss = (key) => () => {
    closeToast(key);
  };

  return {
    variant: 'error',
    persist: true,
    action: (key) => (
      <IconButton color="inherit" onClick={onClickDismiss(key)}>
        <SvgIcon fontSize="small">
          <XCircle />
        </SvgIcon>
      </IconButton>
    ),
  };
};
