import React, { useEffect } from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import PopupLayout from '../PopupLayout/PopupLayout';
import { useDispatch, useSelector } from 'react-redux';
import { solverLogSelector } from '../../redux/slices/solver/solverLog';
import { solverLogActions } from '../../redux/slices/solver/solverLog';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@vanongo/components/Link/Link';
import { ORDERS } from '../../constants/routes';

const useStyles = makeStyles(() => ({
  wrapper: {
    maxHeight: '70vh',
    overflow: 'auto',
    whiteSpace: 'pre',
  },
}));

const LinkOrderItems = ({el}) => (
  <Link
    variant="body1"
    type="link"
    to={`${ORDERS}/${el}`}
    component={RouterLink}
    target={'_blank'}
    >
    {el}
  </Link>
);

const renderTextWithIdLinks = (str, orderIds) => {
  if(!str) return;
  
  let result = [];

  const regexUUID = /[0-9a-f]{8}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{12}/gi;
  const matches = [...str.matchAll(regexUUID)];

  const lastSubStrIndex = matches.reduce((acc, match) => {
    const subStrBeforeMatch = str.slice(acc, match.index);
    const id = match[0];

    result = [ 
      ...result,
      subStrBeforeMatch,
      orderIds.includes(id) ? <LinkOrderItems el={id} /> : id
    ];

    return match.index + match[0].length;
  }, 0)

  result = [...result, str.slice(lastSubStrIndex)];

  return result;
}

export const SolverJobLogPopup = ({ closePopup, filePath, orderItems }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { content, isLoading } = useSelector(solverLogSelector);
  
  useEffect(() => {
    dispatch(solverLogActions.getSolverLogForJob(filePath));
  }, [dispatch, filePath]);
  
  return (
    <PopupLayout width={1000} title={'Job log'} buttonConfig={[]}>
      {isLoading || !content || !orderItems ? (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <div className={classes.wrapper}>
          <div>{renderTextWithIdLinks(content, orderItems)}</div> 
        </div>
      )}
    </PopupLayout>
  );
};